import React, { useState, useEffect } from 'react';
import {
  Button,
  Card,
  Col,
  Form,
  Row,
  OverlayTrigger,
  Tooltip,
  Spinner
} from 'react-bootstrap';
import FalconCardHeader from 'components/common/FalconCardHeader';
import { toast } from 'react-toastify';
import { createSendmoney, getSendmoney } from 'lib/apiService';
import IconButton from 'components/common/IconButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import * as XLSX from 'xlsx';

const styles = {
  cursor: 'pointer'
};

const SendmoneyPage = () => {
  // ok yamar sonin bainaa ene
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [pageSize] = useState(40);
  const [inputs, setInputs] = useState([]);
  const [observer, setObserver] = useState(false);

  const handleInputChange = (index, field, value) => {
    setObserver(true);
    const newInputs = [...inputs];
    newInputs[index][field] = value;
    setInputs(newInputs);
  };

  const handleInputChangelimit = (index, main, field, value) => {
    setObserver(true);
    const newInputs = [...inputs];
    // Ensure main is a valid index

    // Update the money_limit property
    newInputs[index][main][field] = parseFloat(value);
    setInputs(newInputs);
  };

  const fetchData = async query => {
    try {
      const response = await getSendmoney(query);
      if (response) {
        const { data } = response.data; // No need for optional chaining here
        setInputs(data);
        const totalCount = response.data.mytotal; // No need for optional chaining here
        setTotalPages(Math.ceil(totalCount / pageSize));
      }
    } catch (error) {
      console.error('Error fetching user data:', error);
    }
  };

  useEffect(() => {
    fetchData(`?page=${currentPage}&limit=${pageSize}`);
  }, [currentPage]);

  const handleSubmit = async e => {
    e.preventDefault();

    setObserver(false);

    const { data } = await createSendmoney(inputs);
    if (data.success) {
      fetchData(`?page=${currentPage}&limit=${pageSize}`);
      toast.success(`Амжилттай шинэчилэгдлээ`, {
        theme: 'colored'
      });
    } else {
      toast.error(`can't set avatar`, {
        theme: 'colored'
      });
    }
  };

  const addInput = () => {
    if (inputs.length < 200) {
      const newInput = {
        code: '',
        convertCurrencyCode: '',
        name: '',
        buying_rate: 0,
        selling_rate: 0,
        first_fee: { money_limit: 0, value: 0 },
        second_fee: { money_limit: 0, value: 0 },
        third_fee: { money_limit: 0, value: 0 },
        fourth_fee: { money_limit: 0, value: 0 },
        fifth_fee: { money_limit: 0, value: 0 }
      };
      setInputs([newInput, ...inputs]);
    }
  };
  const removeInput = index => {
    setObserver(true);
    const newInputs = [...inputs];
    newInputs.splice(index, 1);
    setInputs(newInputs);
  };

  const handlePageChange = newPage => {
    setCurrentPage(newPage);
  };

  function convertArrayToExcel(array) {
    const worksheet = XLSX.utils.json_to_sheet(array);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet 1');
    const excelBuffer = XLSX.write(workbook, {
      bookType: 'xlsx',
      type: 'array'
    });

    // Create a Blob object with the Excel data
    const blob = new Blob([excelBuffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    });

    // Create a temporary anchor element
    const anchor = document.createElement('a');
    anchor.href = URL.createObjectURL(blob);
    anchor.download = 'data.xlsx'; // Change the filename as desired
    anchor.style.display = 'none';

    // Append the anchor to the document body and click it programmatically
    document.body.appendChild(anchor);
    anchor.click();

    // Clean up
    document.body.removeChild(anchor);
    URL.revokeObjectURL(anchor.href);
  }

  const handleChangeFileFormat = async () => {
    const response = await getSendmoney('?limit=200');
    if (response) {
      const { data } = response.data; // No need for optional chaining here

      const newRawData = data.map(item => {
        return {
          code: item.code,
          convertCurrencyCode: item.convertCurrencyCode,
          name: item.name,
          buying_rate: item.buying_rate,
          selling_rate: item.selling_rate,
          // transfer_limit: item.transfer_limit,
          first_fee_money_limit: item.first_fee.money_limit,
          first_fee_value: item.first_fee.value,
          second_fee_money_limit: item.second_fee.money_limit,
          second_fee_value: item.second_fee.value,
          third_fee_money_limit: item.third_fee.money_limit,
          third_fee_value: item.third_fee.value,
          fourth_fee_money_limit: item.fourth_fee.money_limit,
          fourth_fee_value: item.fourth_fee.value,
          fifth_fee_money_limit: item.fifth_fee.money_limit,
          fifth_fee_value: item.fifth_fee.value
        };
      });

      convertArrayToExcel(newRawData);
    }
  };

  const handleChangeFileFormatImport = async event => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = async e => {
      setLoading(true);
      const importData = new Uint8Array(e.target.result);
      const workbook = XLSX.read(importData, { type: 'array' });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
      jsonData.shift();
      const filteredData = jsonData.filter(item => item.length > 0);
      const newRawData = filteredData
        .map(item => {
          return {
            code: item[0],
            convertCurrencyCode: item[1],
            name: item[2],
            buying_rate: item[3],
            selling_rate: item[4],
            // transfer_limit: item[5],
            first_fee: {
              money_limit: item[5],
              value: item[6]
            },
            second_fee: {
              money_limit: item[7],
              value: item[8]
            },
            third_fee: {
              money_limit: item[9],
              value: item[10]
            },
            fourth_fee: {
              money_limit: item[11],
              value: item[12]
            },
            fifth_fee: {
              money_limit: item[13],
              value: item[14]
            }
          };
        })
        .filter(Boolean);
      const { data } = await createSendmoney(newRawData);
      if (data.success) {
        fetchData(`?page=${currentPage}&limit=${pageSize}`);
        setLoading(false);
        toast.success(`Амжилттай шинэчилэгдлээ`, {
          theme: 'colored'
        });
      } else {
        toast.error(`can't set avatar`, {
          theme: 'colored'
        });
      }
    };

    reader.readAsArrayBuffer(file);
  };

  return (
    <>
      <Card>
        <FalconCardHeader title="Мөнгөн гуйвуулга" />

        <Card.Body className="bg-light">
          <div className="d-flex justify-content-end gap-3">
            <div className="mr-2">
              <IconButton
                className="mr-3"
                variant="falcon-default"
                size="sm"
                icon="external-link-alt"
                transform="shrink-3"
                onClick={handleChangeFileFormat}
              >
                <span className="d-none d-sm-inline-block ms-1">Экспорт</span>
              </IconButton>
            </div>
            <div>
              <Form.Group as={Col} lg={6} controlId="firstName">
                <label
                  className="btn btn-falcon-default btn-sm mb-1 py-1"
                  htmlFor="uploadfile-1"
                >
                  Импорт
                </label>
                <input
                  id="uploadfile-1"
                  style={styles}
                  type="file"
                  accept=".xlsx"
                  className="form-control d-none"
                  onChange={handleChangeFileFormatImport}
                />
              </Form.Group>
            </div>
          </div>
          <Form onSubmit={handleSubmit}>
            {inputs.length < 200 && (
              <IconButton
                variant="primary"
                className="me-2 mb-1"
                icon="plus"
                transform="shrink-3"
                onClick={addInput}
              >
                Нөхцөл нэмэх
              </IconButton>
            )}
            <div className="text-end">
              <Button variant="primary" type="submit" disabled={!observer}>
                Шинэчлэх
              </Button>
            </div>
            {loading && (
              <div className="my-5 text-center">
                <Spinner animation="border" role="status">
                  <span className="visually-hidden">Түр хүлээнэ үү.</span>
                </Spinner>
              </div>
            )}

            {inputs &&
              inputs.map((input, index) => (
                <div key={index}>
                  {' '}
                  <Row
                    className={`mb-5 mt-4 g-3 ${
                      index !== 0 && 'border-top border-gray-300'
                    } `}
                  >
                    <Form.Group as={Col} lg={2} controlId="firstName">
                      <Form.Label className="d-flex justify-content-between align-items-center">
                        <span>Код</span>
                        <OverlayTrigger
                          overlay={
                            <Tooltip
                              style={{ position: 'fixed' }}
                              id="overlay-trigger-example"
                            >
                              Мөнгөн шилжүүлэг хийгдэх улсын currency code жш:
                              USD, CNY
                            </Tooltip>
                          }
                        >
                          <span className="bg-primary px-2 rounded-circle text-white">
                            i
                          </span>
                        </OverlayTrigger>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="код"
                        value={inputs[index]?.code || ''}
                        name="code"
                        onChange={e =>
                          handleInputChange(
                            index,
                            'code',
                            e.target.value.toUpperCase()
                          )
                        }
                      />
                    </Form.Group>

                    <Form.Group as={Col} md={3} lg={3} controlId="firstName">
                      <Form.Label className="d-flex justify-content-between align-items-center">
                        <span>Хөрвөх валют код</span>
                        <OverlayTrigger
                          overlay={
                            <Tooltip
                              style={{ position: 'fixed' }}
                              id="overlay-trigger-example"
                            >
                              Мөнгөн шилжүүлэг хийхэд шаардагдах валют нь хөрвөж
                              бодогдох currency code
                            </Tooltip>
                          }
                        >
                          <span className="bg-primary px-2 rounded-circle text-white">
                            i
                          </span>
                        </OverlayTrigger>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="код"
                        value={inputs[index]?.convertCurrencyCode || ''}
                        name="convertCurrencyCode"
                        onChange={e =>
                          handleInputChange(
                            index,
                            'convertCurrencyCode',
                            e.target.value.toUpperCase()
                          )
                        }
                      />
                    </Form.Group>

                    <Form.Group as={Col} lg={3} controlId="lastName">
                      <Form.Label className="d-flex justify-content-between align-items-center">
                        <span>Нэр</span>
                        <OverlayTrigger
                          overlay={
                            <Tooltip
                              style={{ position: 'fixed' }}
                              id="overlay-trigger-example"
                            >
                              Өөр ойлгомжтойгоор бичнэ
                            </Tooltip>
                          }
                        >
                          <span className="bg-primary px-2 rounded-circle text-white">
                            i
                          </span>
                        </OverlayTrigger>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="нэр"
                        value={inputs[index]?.name || ''}
                        name="name"
                        onChange={e =>
                          handleInputChange(index, 'name', e.target.value)
                        }
                      />
                    </Form.Group>
                    <Form.Group as={Col} lg={2} controlId="email">
                      <Form.Label className="d-flex justify-content-between align-items-center">
                        <span>
                          1{' '}
                          {inputs[index].convertCurrencyCode ===
                          inputs[index].code
                            ? 'MNT'
                            : inputs[index].convertCurrencyCode}{' '}
                          =
                        </span>

                        <OverlayTrigger
                          overlay={
                            <Tooltip
                              style={{ position: 'fixed' }}
                              id="overlay-trigger-example"
                            >
                              CNY, USD, RUB, KRW, JPY зэрэг 5 орны мөнгөн
                              тэмдэгтээс бусад нь 1 гэсэн харьцаагаар оруулахад
                              болно. Хэрэв төгрөгөөс доллараар дамжихгүйгээр
                              шууд хөрвөх мөнгөн тэмдэгтийг ЖШ: 1төгрөг =
                              0.390954 вон бол 0.390954 гэж оруулахад болно.
                            </Tooltip>
                          }
                        >
                          <span className="bg-primary px-2 rounded-circle text-white">
                            i
                          </span>
                        </OverlayTrigger>
                      </Form.Label>
                      <Form.Control
                        type="number"
                        placeholder="авах"
                        value={inputs[index]?.buying_rate || ''}
                        name="buying_rate"
                        onChange={e =>
                          handleInputChange(
                            index,
                            'buying_rate',
                            e.target.value
                          )
                        }
                      />
                    </Form.Group>

                    <Form.Group as={Col} lg={2} controlId="phone">
                      <Form.Label className="d-flex justify-content-between align-items-center">
                        <span>1 {inputs[index]?.code}=</span>
                        <OverlayTrigger
                          overlay={
                            <Tooltip
                              style={{ position: 'fixed' }}
                              id="overlay-trigger-example"
                            >
                              CNY, USD, RUB, KRW, JPY зэрэг 5 орны мөнгөн
                              тэмдэгтээс бусад нь 1 гэсэн харьцаагаар оруулахад
                              болно. Хэрэв төгрөгөөс доллараар дамжихгүйгээр
                              шууд хөрвөх мөнгөн тэмдэгтийг ЖШ: 1вон = 2.5
                              төгрөг бол 2.5 гэж оруулахад болно.
                            </Tooltip>
                          }
                        >
                          <span className="bg-primary px-2 rounded-circle text-white">
                            i
                          </span>
                        </OverlayTrigger>
                      </Form.Label>

                      <Form.Control
                        type="number"
                        placeholder="зарах"
                        value={inputs[index]?.selling_rate || ''}
                        name="selling_rate"
                        onChange={e =>
                          handleInputChange(
                            index,
                            'selling_rate',
                            e.target.value
                          )
                        }
                      />
                    </Form.Group>

                    {/* <Form.Group as={Col} lg={2} controlId="email">
                    <Form.Label>Шилжүүлгийн хэмжээ</Form.Label>
                    <Form.Control
                      type="number"
                      placeholder="авах"
                      value={inputs[index]?.transfer_limit || ''}
                      name="transfer_limit"
                      onChange={e =>
                        handleInputChange(
                          index,
                          'transfer_limit',
                          e.target.value
                        )
                      }
                    />
                  </Form.Group> */}
                    <br />

                    <Form.Group as={Col} lg={3} controlId="email">
                      <Form.Label className="d-flex justify-content-between align-items-center">
                        <span>Шимтгэл хэмжээ (1)</span>
                        <OverlayTrigger
                          overlay={
                            <Tooltip
                              style={{ position: 'fixed' }}
                              id="overlay-trigger-example"
                            >
                              Шимтгэлийн хэмжээ нь 5 интервальтай байх бөгөөд
                              жишээлбэл Монгол төгрөгийг Америк луу илгээвэл
                              1000$ хүртэл 2$ шимтгэл авах бол 1000 гээд
                              оруулахад болно.
                            </Tooltip>
                          }
                        >
                          <span className="bg-primary px-2 rounded-circle text-white">
                            i
                          </span>
                        </OverlayTrigger>
                      </Form.Label>
                      <Form.Control
                        type="number"
                        placeholder="0"
                        value={inputs[index]?.first_fee.money_limit || ''}
                        name="money_limit"
                        onChange={e =>
                          handleInputChangelimit(
                            index,
                            'first_fee',
                            'money_limit',
                            e.target.value
                          )
                        }
                      />
                    </Form.Group>

                    <Form.Group as={Col} lg={3} controlId="email">
                      <Form.Label className="d-flex justify-content-between align-items-center">
                        <span>Шимтгэл (1)</span>
                        <OverlayTrigger
                          overlay={
                            <Tooltip
                              style={{ position: 'fixed' }}
                              id="overlay-trigger-example"
                            >
                              Шимтгэлийн хэмжээ нь 5 интервальтай байх бөгөөд
                              жишээлбэл Монгол төгрөгийг Америк луу илгээвэл
                              1000$ хүртэл 2$ шимтгэл авах бол 2 гээд оруулахад
                              болно.
                            </Tooltip>
                          }
                        >
                          <span className="bg-primary px-2 rounded-circle text-white">
                            i
                          </span>
                        </OverlayTrigger>
                      </Form.Label>
                      <Form.Control
                        type="number"
                        placeholder="0"
                        value={inputs[index]?.first_fee.value || ''}
                        name="value"
                        onChange={e =>
                          handleInputChangelimit(
                            index,
                            'first_fee',
                            'value',
                            e.target.value
                          )
                        }
                      />
                    </Form.Group>

                    <Form.Group as={Col} lg={3} controlId="email">
                      <Form.Label>Шимтгэл хэмжээ (2)</Form.Label>
                      <Form.Control
                        type="number"
                        placeholder="0"
                        value={inputs[index]?.second_fee.money_limit || ''}
                        name="money_limit"
                        onChange={e =>
                          handleInputChangelimit(
                            index,
                            'second_fee',
                            'money_limit',
                            e.target.value
                          )
                        }
                      />
                    </Form.Group>

                    <Form.Group as={Col} lg={3} controlId="email">
                      <Form.Label>Шимтгэл (2)</Form.Label>
                      <Form.Control
                        type="number"
                        placeholder="0"
                        value={inputs[index]?.second_fee.value || ''}
                        name="value"
                        onChange={e =>
                          handleInputChangelimit(
                            index,
                            'second_fee',
                            'value',
                            e.target.value
                          )
                        }
                      />
                    </Form.Group>

                    <Form.Group as={Col} lg={3} controlId="email">
                      <Form.Label>Шимтгэл хэмжээ (3)</Form.Label>
                      <Form.Control
                        type="number"
                        placeholder="0"
                        value={inputs[index]?.third_fee.money_limit || ''}
                        name="money_limit"
                        onChange={e =>
                          handleInputChangelimit(
                            index,
                            'third_fee',
                            'money_limit',
                            e.target.value
                          )
                        }
                      />
                    </Form.Group>

                    <Form.Group as={Col} lg={3} controlId="email">
                      <Form.Label>Шимтгэл (3)</Form.Label>
                      <Form.Control
                        type="number"
                        placeholder="0"
                        value={inputs[index]?.third_fee.value || ''}
                        name="value"
                        onChange={e =>
                          handleInputChangelimit(
                            index,
                            'third_fee',
                            'value',
                            e.target.value
                          )
                        }
                      />
                    </Form.Group>

                    <Form.Group as={Col} lg={3} controlId="email">
                      <Form.Label>Шимтгэл хэмжээ (4)</Form.Label>
                      <Form.Control
                        type="number"
                        placeholder="0"
                        value={inputs[index]?.fourth_fee.money_limit || ''}
                        name="money_limit"
                        onChange={e =>
                          handleInputChangelimit(
                            index,
                            'fourth_fee',
                            'money_limit',
                            e.target.value
                          )
                        }
                      />
                    </Form.Group>

                    <Form.Group as={Col} lg={3} controlId="email">
                      <Form.Label>Шимтгэл (4)</Form.Label>
                      <Form.Control
                        type="number"
                        placeholder="0"
                        value={inputs[index]?.fourth_fee.value || ''}
                        name="value"
                        onChange={e =>
                          handleInputChangelimit(
                            index,
                            'fourth_fee',
                            'value',
                            e.target.value
                          )
                        }
                      />
                    </Form.Group>
                    <Form.Group as={Col} lg={3} controlId="email">
                      <Form.Label className="d-flex justify-content-between align-items-center">
                        <span>Шимтгэл хэмжээ (5)</span>
                        <OverlayTrigger
                          overlay={
                            <Tooltip
                              style={{ position: 'fixed' }}
                              id="overlay-trigger-example"
                            >
                              Шимтгэлийн хэмжээ нь 5 интервальтай байх бөгөөд
                              жишээлбэл Монгол төгрөгийг Америк луу илгээвэл 50k
                              $ дээш 30$ шимтгэл авах бол 50000 гээд оруулахад
                              болно.
                            </Tooltip>
                          }
                        >
                          <span className="bg-primary px-2 rounded-circle text-white">
                            i
                          </span>
                        </OverlayTrigger>
                      </Form.Label>
                      <Form.Control
                        type="number"
                        placeholder="0"
                        value={inputs[index]?.fifth_fee.money_limit || ''}
                        name="money_limit"
                        onChange={e =>
                          handleInputChangelimit(
                            index,
                            'fifth_fee',
                            'money_limit',
                            e.target.value
                          )
                        }
                      />
                    </Form.Group>

                    <Form.Group as={Col} lg={3} controlId="email">
                      <Form.Label className="d-flex justify-content-between align-items-center">
                        <span>Шимтгэл (5)</span>
                        <OverlayTrigger
                          overlay={
                            <Tooltip
                              style={{ position: 'fixed' }}
                              id="overlay-trigger-example"
                            >
                              Шимтгэлийн хэмжээ нь 5 интервальтай байх бөгөөд
                              жишээлбэл Монгол төгрөгийг Америк луу илгээвэл 50k
                              $ дээш 30$ шимтгэл авах бол 30 гээд оруулахад
                              болно.
                            </Tooltip>
                          }
                        >
                          <span className="bg-primary px-2 rounded-circle text-white">
                            i
                          </span>
                        </OverlayTrigger>
                      </Form.Label>
                      <Form.Control
                        type="number"
                        placeholder="0"
                        value={inputs[index]?.fifth_fee.value || ''}
                        name="value"
                        onChange={e =>
                          handleInputChangelimit(
                            index,
                            'fifth_fee',
                            'value',
                            e.target.value
                          )
                        }
                      />
                    </Form.Group>

                    {index > 0 && (
                      <Form.Group
                        as={Col}
                        lg={1}
                        controlId="phone"
                        style={{ marginTop: '47px' }}
                      >
                        <Button
                          variant="warning"
                          onClick={() => removeInput(index)}
                        >
                          <FontAwesomeIcon icon={faTrashAlt} />
                        </Button>
                      </Form.Group>
                    )}
                  </Row>
                </div>
              ))}
          </Form>
          <div className="mt-3 d-flex justify-content-center">
            {Array.from({ length: totalPages }, (_, i) => (
              <Button
                key={i}
                variant={i + 1 === currentPage ? 'primary' : 'outline-primary'}
                className="me-1"
                onClick={() => handlePageChange(i + 1)}
              >
                {i + 1}
              </Button>
            ))}
          </div>
        </Card.Body>
      </Card>
    </>
  );
};

export default SendmoneyPage;
