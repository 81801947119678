import React, { useState, useContext, useEffect } from 'react';
import { Card, Col, Row, Button, Form } from 'react-bootstrap';
import Flex from 'components/common/Flex';
import SettingsHeader from './SettingsHeader';
import SettingsSideBar from './SettingsSideBar';
import Avatar from 'components/common/Avatar';
import axios from 'axios';
import Resizer from 'react-image-file-resizer';
import { FcHome, FcCallback, FcInvite, FcGlobe } from 'react-icons/fc';
import { toast } from 'react-toastify';
import team1 from 'assets/img/avatar.png';
import { updateCompany } from 'lib/apiService';
import { AuthWizardContext } from 'context/Context';

const SettingsGemeral = () => {
  const { company, setCompany } = useContext(AuthWizardContext);
  const [imageUrl, setImageUrl] = useState();
  const [formData, setFormData] = useState({});
  const [formAddress, setFormAddress] = useState([]);
  const [observer, setObserver] = useState(false);
  const [observerAddress, setObserverAddress] = useState(false);
  console.log('🚀 ~ SettingsGemeral ~ setObserverAddress:', setObserverAddress);

  const [edit, setEdit] = useState({
    profile: false,
    address: false
  });

  const imageUploader = image => {
    Resizer.imageFileResizer(
      image,
      800,
      600,
      'WEBP',
      100,
      0,
      uri => {
        axios
          .post(`${process.env.REACT_APP_FILE_URL}/uploadimage`, {
            image: uri
          })
          .then(response => {
            if (response.data?.url && response.data?.public_id) {
              const newImageUrl = {
                url: response?.data?.url.toString(),
                public_id: response?.data?.public_id.toString(),
                name: image.name
              };
              formData.logo = newImageUrl;
              setFormData(formData);
              setImageUrl(newImageUrl);
            }
          })
          .catch(error => {
            console.log('CLOUDINARY UPLOAD FAILED', error);
          });
      },
      'base64',
      400,
      300
    );
  };

  useEffect(() => {
    if (company) {
      setFormData(company);
      setImageUrl(company.image);
      setFormAddress(company.address);
    }
  }, [company]);

  const handleChangeImage = e => {
    setObserver(true);
    const file = e.target.files[0];
    imageUploader(file);
  };

  const handleChange = e => {
    setObserver(true);
    if (e.target.name === 'phones') {
      const { index } = e.target.dataset;
      const updatedPhones = [...formData.phones];
      updatedPhones[index] = e.target.value;
      setFormData({
        ...formData,
        phones: updatedPhones
      });
    } else {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value
      });
    }
  };

  const handleSubmit = e => {
    e.preventDefault();
    const preparedImage = {
      url: imageUrl.url,
      name: imageUrl.name,
      public_id: imageUrl.public_id
    };

    formData.image = preparedImage;
    updateCompany(formData._id, formData)
      .then(result => {
        if (result.data.success) {
          toast.success(`Амжиллтай илгээгдлээ`, {
            theme: 'colored'
          });
          setFormData(result.data.data);
          setCompany(result.data.data);
          localStorage.setItem('company', JSON.stringify(result.data.data));
        }
      })
      .catch(error => {
        toast.warning(`${error.response.data.error.message}`, {
          theme: 'colored'
        });
      });
  };

  const handleSubmitAddress = e => {
    e.preventDefault();
    updateCompany(formData._id, { address: formAddress })
      .then(result => {
        if (result.data.success) {
          toast.success(`Амжиллтай илгээгдлээ`, {
            theme: 'colored'
          });
          setFormData(result.data.data);
          setCompany(result.data.data);
          localStorage.setItem('company', JSON.stringify(result.data.data));
        }
      })
      .catch(error => {
        toast.warning(`${error.response.data.error.message}`, {
          theme: 'colored'
        });
      });
  };

  const addressHandleChange = e => {
    setFormAddress(e.target.value);
  };

  return (
    <Row className="g-3">
      <Col xl={4} xxl={3}>
        <SettingsSideBar />
      </Col>
      <Col xl={8} xxl={9}>
        <SettingsHeader data={'Байгууллагын мэдээлэл'} />
        <Card>
          <Card.Header className="bg-light d-flex justify-content-between align-items-md-center">
            <div>
              <h6 className="mb-0">Профайл</h6>
            </div>
            <div>
              <button
                className="btn btn-link"
                onClick={() => setEdit({ ...edit, profile: !edit.profile })}
              >
                <h6 className="mb-0 text-primary"> {'Засах'}</h6>
              </button>{' '}
            </div>
          </Card.Header>
          <Card.Body>
            <Form onSubmit={handleSubmit}>
              <Row className="g-0">
                {imageUrl && imageUrl.url ? (
                  <Col lg={6} xxl={6}>
                    <Flex
                      alignItems="center"
                      className={`px-2 ms-2 ${
                        edit.profile ? 'py-x1' : 'py'
                      } position-relative`}
                    >
                      <Avatar src={imageUrl.url} rounded="soft" size="4xl" />
                    </Flex>
                  </Col>
                ) : (
                  <Col lg={12} xxl={12}>
                    <Flex
                      alignItems="center"
                      className={`px-2 ms-5 ${
                        edit.profile ? 'py-x1' : 'py'
                      } position-relative`}
                    >
                      <Avatar src={team1} rounded="soft" size="4xl" />
                    </Flex>
                  </Col>
                )}

                {edit.profile && (
                  <Col lg={6} xxl={6}>
                    <Flex
                      alignItems="center"
                      className={`px-2 ${
                        edit.profile ? 'py-x1' : 'py'
                      } position-relative`}
                    >
                      <label
                        className="btn btn-primary mb-0"
                        htmlFor="uploadfile-1"
                      >
                        Өөрчлөх
                      </label>
                      <input
                        id="uploadfile-1"
                        accept="image/*"
                        type="file"
                        name="image"
                        className="form-control d-none"
                        onChange={handleChangeImage}
                      />
                    </Flex>
                  </Col>
                )}

                <Col lg={6} xxl={6}>
                  <Flex
                    alignItems="center"
                    className={`px-2 ${
                      edit.profile ? 'py-x1' : 'py'
                    } position-relative`}
                  >
                    {edit.profile ? (
                      <Form.Group as={Col} lg={12} controlId="name">
                        <Form.Label>Нэр</Form.Label>
                        <input
                          placeholder="Байгууллагын нэр"
                          className="form-control"
                          name="name"
                          onChange={handleChange}
                          value={formData?.name || ''}
                        />
                      </Form.Group>
                    ) : (
                      <>
                        <div
                          className={`bg-orange p-2 text-dark bg-opacity-10 rounded`}
                        >
                          <div className="bg-soft-info d-inline-block rounded py-1 text-warning px-2">
                            <FcHome size={20} />
                          </div>
                        </div>

                        <div className="ms-3 my-x1">
                          <h5 className="fs-0 fw-semi-bold mb-2">
                            <div className="text-900">{'Байгууллагын нэр'}</div>
                          </h5>
                          <h6 className="mb-0 text-600">{formData?.name}</h6>
                        </div>
                      </>
                    )}
                  </Flex>
                </Col>
                <Col lg={6} xxl={6}>
                  <Flex
                    alignItems="center"
                    className={`px-2 ${
                      edit.profile ? 'py-x1' : 'py'
                    } position-relative`}
                  >
                    {edit.profile ? (
                      <Form.Group as={Col} lg={12} controlId="email">
                        <Form.Label>Имэйл</Form.Label>
                        <input
                          placeholder="Бизнес имэйл"
                          className="form-control"
                          name="email"
                          onChange={handleChange}
                          value={formData?.email}
                        />
                      </Form.Group>
                    ) : (
                      <>
                        <div
                          className={`bg-orange p-2 text-dark bg-opacity-10 rounded`}
                        >
                          <div className="bg-200 d-inline-block rounded py-1 text-warning px-2">
                            <FcInvite size={20} />
                          </div>
                        </div>

                        <div className="ms-3 my-x1">
                          <h5 className="fs-0 fw-semi-bold mb-2">
                            <div className="text-900">{'Бизнес имэйл'}</div>
                          </h5>
                          <h6 className="mb-0 text-600">{formData?.email}</h6>
                        </div>
                      </>
                    )}
                  </Flex>
                </Col>
                {formData &&
                  formData.phones &&
                  formData.phones.map((phone, index) => (
                    <Col lg={6} xxl={6} key={index}>
                      <Flex
                        alignItems="center"
                        className={`px-2 ${
                          edit.profile ? 'py-x1' : 'py'
                        } position-relative`}
                      >
                        {edit.profile ? (
                          <Form.Group as={Col} lg={12} controlId="phone">
                            <Form.Label>Утас</Form.Label>
                            <input
                              placeholder="Утас"
                              className="form-control"
                              name="phones"
                              data-index={index}
                              onChange={handleChange}
                              value={phone}
                            />
                          </Form.Group>
                        ) : (
                          <>
                            <div
                              className={`bg-orange p-2 text-dark bg-opacity-10 rounded`}
                            >
                              <div className="bg-soft-primary d-inline-block rounded py-1 text-warning px-2">
                                <FcCallback size={20} />
                              </div>
                            </div>
                            <div className="ms-3 my-x1">
                              <h5 className="fs-0 fw-semi-bold mb-2">
                                <div className="text-900">{'Холбоо барих'}</div>
                              </h5>
                              <h6 className="mb-0 text-600">{phone}</h6>
                            </div>
                          </>
                        )}
                      </Flex>
                    </Col>
                  ))}

                {edit.profile && (
                  <Col lg={12} xxl={12}>
                    <Flex
                      alignItems="center"
                      justifyContent="end"
                      className={`px-2 ${
                        edit.profile ? 'py-x1' : 'py'
                      } position-relative`}
                    >
                      <Button
                        variant="falcon-default"
                        className="me-2 mb-1"
                        onClick={() =>
                          setEdit({ ...edit, profile: !edit.profile })
                        }
                      >
                        Буцах
                      </Button>
                      <Button
                        variant="primary"
                        type="submit"
                        className="me-2 mb-1"
                        disabled={!observer}
                      >
                        Хадгалах
                      </Button>
                    </Flex>
                  </Col>
                )}
              </Row>
            </Form>
          </Card.Body>
        </Card>
        {/* address form */}
        <Card className="mt-3">
          <Card.Header className="bg-light d-flex justify-content-between align-items-md-center">
            <div>
              <h6 className="mb-0">Хаягийн мэдээлэл</h6>
            </div>
            <div>
              <button
                className="btn btn-link"
                onClick={() => setEdit({ ...edit, address: !edit.address })}
              >
                <h6 className="mb-0 text-primary"> {'Засах'}</h6>
              </button>{' '}
            </div>
          </Card.Header>
          <Card.Body>
            <Form onSubmit={handleSubmitAddress}>
              <Row className="g-0">
                <Col lg={12} xxl={12}>
                  <Flex
                    alignItems="center"
                    className={`px-2 ${
                      edit.address ? 'py-x1' : 'py'
                    } position-relative`}
                  >
                    {edit.address ? (
                      <Form.Group as={Col} lg={12} controlId="country">
                        <Form.Label>Хаяг</Form.Label>
                        <input
                          placeholder="Хаяг"
                          className="form-control"
                          name="address"
                          onChange={addressHandleChange}
                          value={formAddress}
                        />
                      </Form.Group>
                    ) : (
                      <>
                        <div
                          className={`bg-orange p-2 text-dark bg-opacity-10 rounded`}
                        >
                          <div className="bg-soft-info d-inline-block rounded py-1 text-warning px-2">
                            <FcGlobe size={20} />
                          </div>
                        </div>

                        <div className="ms-3 my-x1">
                          <h5 className="fs-0 fw-semi-bold mb-2">
                            <div className="text-900">{'Хаяг'}</div>
                          </h5>
                          <h6 className="mb-0 text-600">{formAddress}</h6>
                        </div>
                      </>
                    )}
                  </Flex>
                </Col>
                {edit.address && (
                  <Col lg={12} xxl={12}>
                    <Flex
                      alignItems="center"
                      justifyContent="end"
                      className={`px-2 ${
                        edit.address ? 'py-x1' : 'py'
                      } position-relative`}
                    >
                      <Button
                        variant="falcon-default"
                        className="me-2 mb-1"
                        onClick={() =>
                          setEdit({ ...edit, address: !edit.address })
                        }
                      >
                        Буцах
                      </Button>
                      <Button
                        variant="primary"
                        type="submit"
                        className="me-2 mb-1"
                        disabled={!observerAddress}
                      >
                        Хадгалах
                      </Button>
                    </Flex>
                  </Col>
                )}
              </Row>
            </Form>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};

export default SettingsGemeral;
