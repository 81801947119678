import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import PrivateRoute from './privateRoutes';
import MainLayout from '../layouts/MainLayout';
import ErrorLayout from '../layouts/ErrorLayout';

import Error404 from 'components/errors/Error404';
import Error500 from 'components/errors/Error500';

import CardLogin from 'components/authentication/card/Login';
import Logout from 'components/authentication/card/Logout';
import CardForgetPassword from 'components/authentication/card/ForgetPassword';
import CardPasswordReset from 'components/authentication/card/PasswordReset';

import Dashboard from 'components/dashboards/default';
// Company Create
import LoanList from 'components/dashboards/loan/LoanList';
import LoanUpdate from 'components/dashboards/loan/LoanUpdate';

//report
import ReportList from 'components/dashboards/report/ReportList';
import ReportAdd from 'components/dashboards/report/ReportAdd';
//company blog list
import ServiceList from 'components/dashboards/service/ServiceList';
import ServiceAdd from 'components/dashboards/service/ServiceAdd';
import ServiceUpdate from 'components/dashboards/service/ServiceUpdate';
// message
import MessageList from 'components/dashboards/message/MessageList';
import MessageDetail from 'components/dashboards/message/MessageDetail';
// customer
import CustomerList from 'components/dashboards/customer/Customers';
import CustomerSettings from 'components/dashboards/customer/settings/Settings';
import CustomerInvite from 'components/dashboards/customer/CustomerInvite';
import InvitedUser from 'components/authentication/card/InvitedUser';
//settings
import SettingsGemeral from 'components/dashboards/settings/SettingsGemeral';
import SettingsAccount from 'components/dashboards/settings/SettingsAccount';

import SettingsLanguages from 'components/dashboards/settings/SettingsLanguages';
import SettingsLegal from 'components/dashboards/settings/SettingsLegal';
import SettingsStructure from 'components/dashboards/settings/SettingsStructure';
import SettingsAbout from 'components/dashboards/settings/SettingsAbout';
import SettingsHistory from 'components/dashboards/settings/SettingsHistory';
import SettingsSocial from 'components/dashboards/settings/SettingsSocial';
// log
import LogList from 'components/dashboards/log/LogList';
import Currency from 'components/dashboards/customer/settings/Currency';
import Sendmoney from 'components/dashboards/customer/settings/Sendmoney';

const FalconRoutes = () => {
  return (
    <Routes>
      <Route element={<ErrorLayout />}>
        <Route path="errors/404" element={<Error404 />} />
        <Route path="errors/500" element={<Error500 />} />
      </Route>
      {/*- ------------- Authentication ---------------------------  */}

      {/*- ------------- Card ---------------------------  */}
      <Route path="/authentication/card/login" element={<CardLogin />} />
      <Route path="/authentication/card/logout" element={<Logout />} />
      <Route
        path="/authentication/card/forgot-password"
        element={<CardForgetPassword />}
      />
      <Route
        path="/authentication/card/reset-password/:token"
        element={<CardPasswordReset />}
      />

      <Route
        path="/authentication/card/invite-user/:token"
        element={<InvitedUser />}
      />
      {/* //--- MainLayout Starts  */}

      <Route element={<MainLayout />}>
        {/*Dashboard*/}
        <Route
          path="/"
          element={
            <PrivateRoute requiredRoles={['admin', 'worker']}>
              <Dashboard />
            </PrivateRoute>
          }
        />
        {/* company */}
        <Route
          path="/loan/loan-list"
          element={
            <PrivateRoute requiredRoles={['admin', 'worker']}>
              <LoanList />
            </PrivateRoute>
          }
        />
        <Route
          path="/loan/loan-update/:loanId"
          element={
            <PrivateRoute requiredRoles={['admin', 'worker']}>
              <LoanUpdate />
            </PrivateRoute>
          }
        />
        {/* report */}
        <Route
          path="/report/report-service"
          element={
            <PrivateRoute requiredRoles={['admin', 'worker']}>
              <ReportAdd />
            </PrivateRoute>
          }
        />
        <Route
          path="/report/report-list"
          element={
            <PrivateRoute requiredRoles={['admin', 'worker']}>
              <ReportList />
            </PrivateRoute>
          }
        />
        {/* service */}
        <Route
          path="/service/service-list"
          element={
            <PrivateRoute requiredRoles={['admin', 'worker']}>
              <ServiceList />
            </PrivateRoute>
          }
        />
        <Route
          path="/service/new-service"
          element={
            <PrivateRoute requiredRoles={['admin', 'worker']}>
              <ServiceAdd />
            </PrivateRoute>
          }
        />
        <Route
          path="/service/service-update/:serviceId"
          element={
            <PrivateRoute requiredRoles={['admin', 'worker']}>
              <ServiceUpdate />
            </PrivateRoute>
          }
        />
        {/* Message list */}
        <Route
          path="/message/message-list"
          element={
            <PrivateRoute requiredRoles={['admin', 'worker']}>
              <MessageList />
            </PrivateRoute>
          }
        />
        <Route
          path="/message/message-detail/:messageId"
          element={
            <PrivateRoute requiredRoles={['admin', 'worker']}>
              <MessageDetail />
            </PrivateRoute>
          }
        />
        {/* Customer */}
        <Route
          path="/customers/customer-list"
          element={
            <PrivateRoute requiredRoles={['admin']}>
              <CustomerList />
            </PrivateRoute>
          }
        />

        <Route
          path="/customers/customer-settings"
          element={
            <PrivateRoute requiredRoles={['admin']}>
              <CustomerSettings />
            </PrivateRoute>
          }
        />
        <Route
          path="/currency/currency-settings"
          element={
            <PrivateRoute requiredRoles={['admin', 'worker']}>
              <Currency />
            </PrivateRoute>
          }
        />
        <Route
          path="/sendmoney/sendmoney-settings"
          element={
            <PrivateRoute requiredRoles={['admin', 'worker']}>
              <Sendmoney />
            </PrivateRoute>
          }
        />
        <Route
          path="/customers/customer-invite"
          element={
            <PrivateRoute requiredRoles={['admin']}>
              <CustomerInvite />
            </PrivateRoute>
          }
        />

        {/* Settings  page */}
        <Route
          path="/settings/general"
          element={
            <PrivateRoute requiredRoles={['admin']}>
              <SettingsGemeral />
            </PrivateRoute>
          }
        />
        <Route
          path="/settings/account"
          element={
            <PrivateRoute requiredRoles={['admin']}>
              <SettingsAccount />
            </PrivateRoute>
          }
        />
        <Route
          path="/settings/about"
          element={
            <PrivateRoute requiredRoles={['admin']}>
              <SettingsAbout />
            </PrivateRoute>
          }
        />
        <Route
          path="/settings/structure"
          element={
            <PrivateRoute requiredRoles={['admin']}>
              <SettingsStructure />
            </PrivateRoute>
          }
        />
        <Route
          path="/settings/languages"
          element={
            <PrivateRoute requiredRoles={['admin']}>
              <SettingsLanguages />
            </PrivateRoute>
          }
        />
        <Route
          path="/settings/legal"
          element={
            <PrivateRoute requiredRoles={['admin']}>
              <SettingsLegal />
            </PrivateRoute>
          }
        />
        <Route
          path="/settings/history"
          element={
            <PrivateRoute requiredRoles={['admin']}>
              <SettingsHistory />
            </PrivateRoute>
          }
        />
        <Route
          path="/settings/social"
          element={
            <PrivateRoute requiredRoles={['admin']}>
              <SettingsSocial />
            </PrivateRoute>
          }
        />
        <Route
          path="/log/log-list"
          element={
            <PrivateRoute requiredRoles={['admin']}>
              <LogList />
            </PrivateRoute>
          }
        />
      </Route>

      {/* <Navigate to="/errors/404" /> */}
      <Route path="*" element={<Navigate to="/errors/404" replace />} />
    </Routes>
  );
};

export default FalconRoutes;
