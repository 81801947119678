import React, { useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import SettingsSideBar from './SettingsSideBar';
import SettingsHeader from './SettingsHeader';
import { LiaLanguageSolid } from 'react-icons/lia';

const SettingsLanguages = () => {
  const [language, setLanguage] = useState([]);
  console.log('🚀 ~ SettingsLanguages ~ setLanguage:', setLanguage);

  const [edit, setEdit] = useState({
    profile: false,
    language: false
  });

  return (
    <Row className="g-3">
      <Col xl={4} xxl={3}>
        <SettingsSideBar />
      </Col>
      <Col xl={8} xxl={9}>
        <SettingsHeader data={'Хэл'} />
        <Card className="mt-4">
          <Card.Header className="bg-light d-flex justify-content-between align-items-md-center">
            <h6 className="mb-0">Хэрэглэгддэг хэл</h6>
            <button
              className="btn btn-link"
              onClick={() => setEdit({ ...edit, language: !edit.language })}
            ></button>
          </Card.Header>
          <Card.Body>
            <Row>
              {edit.language ? (
                <></>
              ) : (
                <>
                  <h6 className="mb-0">Бизнесийн хаяг</h6>
                  <h6 className="fw-light my-3 ">
                    Веб сайт дээр идэвхтэй бөгөөд хэрэглэгчдэд харагдана.{' '}
                  </h6>
                  <div className="border-bottom" />
                  <div className="mt-2">
                    {language?.length > 0 ? (
                      language.map((item, index) => (
                        <div
                          className="d-flex p-2 justify-content-start gap-2 align-items-center"
                          key={index}
                        >
                          <div className="bg-soft-primary d-inline-block rounded py-1 text-primary px-2">
                            <LiaLanguageSolid size={20} />
                          </div>
                          <h6 className="font-bold my-2 w-25">
                            {item === 'mn'
                              ? 'Монгол'
                              : item === 'cn'
                              ? 'Хятад'
                              : item === 'en'
                              ? ' Aнгил'
                              : item}
                          </h6>
                          <div>
                            <div className="badge rounded-pill bg-success mt-1">
                              Идэвхтэй
                            </div>
                          </div>
                        </div>
                      ))
                    ) : (
                      <div className="alert alert-warning" role="alert">
                        Mэдээлэл байхгүй байна
                      </div>
                    )}
                  </div>
                </>
              )}
            </Row>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};

export default SettingsLanguages;
