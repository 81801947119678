import React from 'react';
import { Card, Col, Form, Row } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';

const LoanBasicInformation = () => {
  const {
    register,
    formState: { errors }
  } = useFormContext();

  return (
    <Card className="mb-3">
      <Card.Header as="h6" className="bg-light">
        Ерөнхий
      </Card.Header>
      <Card.Body>
        <Row className="gx-2 gy-3">
          <Col md="12">
            <Form.Group>
              <Form.Label>Регистерийн дугаар:</Form.Label>
              <Form.Control
                type="text"
                isInvalid={!!errors.registerNumber}
                {...register('registerNumber')}
              />
              <Form.Control.Feedback type="invalid">
                {errors.registerNumber?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col md="12">
            <Form.Group>
              <Form.Label>Зээлдэгчийн нэр:</Form.Label>
              <Form.Control
                type="text"
                isInvalid={!!errors.firstName}
                {...register('firstName')}
              />
              <Form.Control.Feedback type="invalid">
                {errors.firstName?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>

          <Col md="12">
            <Form.Group>
              <Form.Label>Зээлдэгчийн овог:</Form.Label>
              <Form.Control
                type="text"
                isInvalid={!!errors.lastName}
                {...register('lastName')}
              />
              <Form.Control.Feedback type="invalid">
                {errors.lastName?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>

          <Col md="12">
            <Form.Group>
              <Form.Label>Имэйл</Form.Label>
              <Form.Control
                type="text"
                isInvalid={!!errors.email}
                {...register('email')}
              />
              <Form.Control.Feedback type="invalid">
                {errors.email?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>

          <Col md="12">
            <Form.Group>
              <Form.Label>Утас</Form.Label>
              <Form.Control
                type="text"
                isInvalid={!!errors.phone}
                {...register('phone')}
              />
              <Form.Control.Feedback type="invalid">
                {errors.phone?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default LoanBasicInformation;
