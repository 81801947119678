import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';
import PageHeader from 'components/common/PageHeader';
import SoftBadge from 'components/common/SoftBadge';

const MessageHeader = ({ getMessageDatas = [] }) => {
  let trueCount = 0;

  for (const item of getMessageDatas) {
    if (item.seen === true) {
      trueCount++;
    }
  }
  return (
    <Row className="g-3 mb-3">
      <Col md={6} xxl={6}>
        <PageHeader title="Шинэ зурвас" titleTag="h5">
          <p className="fs--1 mt-1">
            <SoftBadge pill bg="success" className="fs--2">
              {trueCount} шинэ зурвас ирсэн байна
            </SoftBadge>
          </p>
        </PageHeader>
      </Col>
      <Col md={6} xxl={6}>
        <PageHeader title="Нийт зурвас" titleTag="h5">
          <p className="fs--1 mt-1">
            <SoftBadge pill bg="primary" className="fs--2">
              Нийт {getMessageDatas.length} зурвас байна
            </SoftBadge>
          </p>
        </PageHeader>
      </Col>
    </Row>
  );
};

MessageHeader.propTypes = {
  getMessageDatas: PropTypes.array
};

export default MessageHeader;
