export const courseFilters = [
  {
    label: 'Category',
    options: [
      {
        label: 'Байгууллагын мэдээлэл',
        icon: 'file-alt',
        type: 'checkbox',
        value: 'free course',
        name: 'free course',
        to: '/settings/general'
      },
      {
        label: 'Хэрэглэгчид ба зөвшөөрөл',
        icon: 'paperclip',
        type: 'checkbox',
        value: 'paid course',
        name: 'paid course',
        to: '/settings/account'
      },
      {
        label: 'Сошиал холбоос',
        icon: ['fab', 'facebook'],
        iconShrink: true,
        type: 'checkbox',
        value: 'on sale',
        name: 'on sale',
        to: '/settings/social'
      },
      {
        label: 'Нууцлалын бодлого',
        icon: 'brush',
        iconShrink: true,
        type: 'checkbox',
        value: 'on sale',
        name: 'on sale',
        to: '/settings/legal'
      },
      {
        label: 'Компанийн бүтэц',
        icon: 'list',
        iconShrink: true,
        type: 'checkbox',
        value: 'on sale',
        name: 'on sale',
        to: '/settings/structure'
      },
      {
        label: 'Бидний тухай',
        icon: 'sun',
        iconShrink: true,
        type: 'checkbox',
        value: 'on sale',
        name: 'on sale',
        to: '/settings/about'
      },
      {
        label: 'Компанийн түүх',
        icon: 'users',
        iconShrink: true,
        type: 'checkbox',
        value: 'on sale',
        name: 'on sale',
        to: '/settings/history'
      }
    ]
  }
];
