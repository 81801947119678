import React, { useState, useEffect } from 'react';
import { Card, Dropdown } from 'react-bootstrap';
import SoftBadge from 'components/common/SoftBadge';
import CardDropdown from 'components/common/CardDropdown';
import Flex from 'components/common/Flex';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import CustomersTableHeader from './CustomersTableHeader';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import { getUsers } from 'lib/apiService';
import team1 from 'assets/img/avatar.png';
import { deleteUser, toggleActivationUser } from 'lib/apiService';
import { toast } from 'react-toastify';

const imgStyle = {
  height: '3rem',
  transition: '0.5s ease-in-out'
};

const Customers = () => {
  const [customers, setCustomers] = useState([]);
  const [pageIndex, setPageIndex] = useState({
    total: 0,
    currentIndex: 0,
    pageCount: 1,
    start: 0,
    end: 0,
    limit: 20,
    prevPage: 0
  });
  const fetchData = async (queryString = {}) => {
    try {
      const response = await getUsers(queryString);

      if (response?.data?.pagination) {
        const { total, prevPage, pageCount, start, end, limit } =
          response.data.pagination;

        setPageIndex(prev => ({
          ...prev,
          total,
          pageCount,
          start,
          end,
          limit,
          prevPage
        }));

        setCustomers(response?.data?.data);
      }
    } catch (error) {
      console.error('Error fetching user data:', error);
    }
  };

  useEffect(() => {
    fetchData(`?limit=${pageIndex.limit}&page=${pageIndex.currentIndex + 1}`);
  }, [pageIndex.currentIndex]); // Re-fetch data when pageIndex.pageCount changes

  const handleNextPage = () => {
    if (pageIndex.currentIndex < Math.ceil(pageIndex.total / pageIndex.limit)) {
      setPageIndex(prev => ({ ...prev, currentIndex: prev.currentIndex + 1 }));
    }
  };

  const handlePreviousPage = () => {
    if (pageIndex.currentIndex + 1 > 1) {
      setPageIndex(prev => ({ ...prev, currentIndex: prev.currentIndex - 1 }));
    }
  };

  const handleGoToPage = page => {
    setPageIndex(prev => ({ ...prev, currentIndex: page }));
  };

  const removeMessage = deleteId => {
    deleteUser(deleteId)
      .then(result => {
        if (result.data.success) {
          toast.success(`Амжиллтай устгагдлаа`, {
            theme: 'colored'
          });
          fetchData();
        }
      })
      .catch(error => {
        toast.warning(`${error.response.data.error.message}`, {
          theme: 'colored'
        });
      });
  };

  const archiveMessage = (contactId, value) => {
    toggleActivationUser(contactId, value)
      .then(result => {
        if (result.data.success) {
          toast.success(`Амжиллтай хадгаллаа`, {
            theme: 'colored'
          });
          fetchData();
        }
      })
      .catch(error => {
        toast.warning(`${error.response.data.error.message}`, {
          theme: 'colored'
        });
      });
  };

  const columns = [
    {
      accessor: 'image',
      Header: 'Аватар',
      headerProps: { className: 'pe-1' },
      cellProps: {
        className: 'py-2'
      },
      Cell: rowData => {
        const { avatarImage } = rowData.row.original;
        return (
          <Flex alignItems="center">
            {avatarImage ? (
              <img
                src={`data:image/svg+xml;base64,${avatarImage}`}
                alt="avatar image"
                style={imgStyle}
              />
            ) : (
              <img src={team1} alt="avatar image" style={imgStyle} />
            )}
          </Flex>
        );
      }
    },
    {
      accessor: 'name',
      Header: 'Нэр',
      headerProps: { className: 'pe-2' },
      Cell: rowData => {
        const { name } = rowData.row.original;
        return <>{name}</>;
      }
    },
    {
      accessor: 'email',
      Header: 'Имэйл',
      headerProps: { className: 'pe-2' },
      Cell: rowData => {
        const { email } = rowData.row.original;
        return <>{email}</>;
        // return <a href={`mailto:${email}`}>{email}</a>;
      }
    },
    {
      accessor: 'phone',
      Header: 'Утас',
      headerProps: { className: 'pe-2' },
      Cell: rowData => {
        const { phone } = rowData.row.original;
        return <span className="text-primary">{phone}</span>;
        // return <a href={`tel:${phone}`}>{phone}</a>;
      }
    },
    {
      accessor: 'status',
      Header: 'Төлөв',
      headerProps: { className: 'pe-2' },
      Cell: rowData => {
        const { status } = rowData.row.original;
        return status ? (
          <SoftBadge pill bg="success" className="me-2">
            Нээлттэй
          </SoftBadge>
        ) : (
          <SoftBadge pill bg="secondary" className="me-2">
            Хаалттай
          </SoftBadge>
        );
      }
    },
    {
      accessor: 'role',
      Header: 'Үүрэг',
      headerProps: { className: 'pe-2' },
      Cell: rowData => {
        const { role } = rowData.row.original;
        return role === 'admin' ? (
          <SoftBadge pill bg="primary" className="me-2">
            Админ
          </SoftBadge>
        ) : (
          <SoftBadge pill bg="primary" className="me-2">
            Нийтлэгч
          </SoftBadge>
        );
      }
    },
    {
      accessor: 'date',
      Header: 'Огноо',
      headerProps: { className: 'pe-2' },
      Cell: rowData => {
        const { createdAt } = rowData.row.original;
        return <>{createdAt.split('T')[0]}</>;
      }
    },
    {
      accessor: 'none',
      Header: '',
      disableSortBy: true,
      cellProps: {
        className: 'text-end'
      },
      Cell: rowData => {
        const { _id: userId } = rowData.row.original;
        return (
          <CardDropdown>
            <div className="py-2">
              <Dropdown.Item onClick={() => archiveMessage(userId, true)}>
                Нээх
              </Dropdown.Item>
              <Dropdown.Item onClick={() => archiveMessage(userId, false)}>
                Хаах
              </Dropdown.Item>
              <Dropdown.Divider as="div" />
              <Dropdown.Item
                onClick={() => removeMessage(userId)}
                className="text-danger"
              >
                Устгах
              </Dropdown.Item>
            </div>
          </CardDropdown>
        );
      }
    }
  ];

  return (
    <AdvanceTableWrapper
      columns={columns}
      data={customers}
      selection
      sortable
      pagination
      perPage={pageIndex.limit}
    >
      <Card className="mb-3">
        <Card.Header>
          <CustomersTableHeader
            table
            customers={customers}
            refetch={fetchData}
          />
        </Card.Header>
        <Card.Body className="p-0">
          <AdvanceTable
            table
            headerClassName="bg-200 text-900 text-nowrap align-middle"
            rowClassName="align-middle white-space-nowrap"
            tableProps={{
              size: 'sm',
              striped: true,
              className: 'fs--1 mb-0 overflow-hidden'
            }}
          />
        </Card.Body>
        <Card.Footer>
          {customers && customers.length > 0 && (
            <AdvanceTablePagination
              canPreviousPage={pageIndex.currentIndex !== 0}
              canNextPage={
                !(pageIndex.currentIndex + 1 === pageIndex.pageCount)
              }
              previousPage={handlePreviousPage}
              nextPage={handleNextPage}
              pageCount={Math.ceil(pageIndex.total / pageIndex.limit)}
              pageIndex={pageIndex.currentIndex}
              gotoPage={handleGoToPage}
            />
          )}
        </Card.Footer>
      </Card>
    </AdvanceTableWrapper>
  );
};

export default Customers;
