import React, { useEffect, useContext } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import LoanBasicInformation from './LoanForms/LoanBasicInformation';
import LoanHeader from './LoanForms/LoanHeader';
import LoanFiles from './LoanForms/LoanFiles';
import LoanStatus from './LoanForms/LoanStatus';
import { getLoan, updateLoan } from 'lib/apiService';
import { AuthWizardContext } from 'context/Context';
import LoanAddress from './LoanForms/LoanAddress';
import Collateral from './LoanForms/Collateral';
import LoanInfo from './LoanForms/LoanInfo';
import { formatPriceWithoutSymbol } from 'helpers/utils';
import LoanVerified from './LoanForms/LoanVerified';

const schema = yup
  .object({
    firstName: yup.string().required('Энэ талбарыг оруулна уу!'),
    lastName: yup.string().required('Энэ талбарыг оруулна уу!'),
    phone: yup.string().required('Энэ талбарыг оруулна уу!'),
    email: yup.string().required('Энэ талбарыг оруулна уу!'),
    status: yup.string().required('Энэ талбарыг оруулна уу!'),
    loanInfoForm: yup.string().required('Энэ талбарыг оруулна уу!'),
    bankStatementForm: yup.string().required('Энэ талбарыг оруулна уу!'),
    //addressForm: yup.string().required('Энэ талбарыг оруулна уу!'),
    idCardForm: yup.string().required('Энэ талбарыг оруулна уу!'),
    marriedForm: yup.string().required('Энэ талбарыг оруулна уу!'),
    socialInsuranceForm: yup.string().required('Энэ талбарыг оруулна уу!'),
    address: yup.object().shape({
      city: yup.string().required('Хот талбарыг оруулна уу!'),
      district: yup.string().required('Дүүрэг талбарыг оруулна уу!'),
      khoroo: yup.string().required('Хороо талбарыг оруулна уу!'),
      description: yup
        .string()
        .required('Хаягийн дэлгэрэнгүй талбарыг оруулна уу!')
    }),
    collateralType: yup.string().required('Энэ талбарыг оруулна уу!'),
    collateralInfo: yup.string().required('Энэ талбарыг оруулна уу!'),
    collateralPrice: yup.string().required('Энэ талбарыг оруулна уу!'),
    money: yup.string().required('Энэ талбарыг оруулна уу!'),
    period: yup.string().required('Энэ талбарыг оруулна уу!'),
    loanRate: yup.string().required('Энэ талбарыг оруулна уу!'),
    dedication: yup.string().required('Энэ талбарыг оруулна уу!'),
    registerNumber: yup.string().required('Энэ талбарыг оруулна уу!'),
    employment: yup.string().required('Энэ талбарыг оруулна уу!'),
    company: yup.string().required('Энэ талбарыг оруулна уу!'),
    experience: yup.string().required('Энэ талбарыг оруулна уу!'),
    income: yup.string().required('Энэ талбарыг оруулна уу!'),
    incomeSource: yup.string().required('Энэ талбарыг оруулна уу!'),
    familyIncome: yup.string().required('Энэ талбарыг оруулна уу!')
  })
  .required();

const LoanUpdate = () => {
  const { setRefetchLoan } = useContext(AuthWizardContext);
  const { loanId } = useParams();

  const setReset = newData => {
    reset({
      firstName: newData?.firstName,
      lastName: newData?.lastName,
      phone: newData?.phone,
      email: newData?.email,
      status: newData?.status,
      loanInfoForm: newData?.loanInfoForm,
      bankStatementForm: newData?.bankStatementForm,
      addressForm: newData?.addressForm,
      idCardForm: newData?.idCardForm,
      marriedForm: newData?.marriedForm,
      socialInsuranceForm: newData?.socialInsuranceForm,
      DefinitionAmount: newData?.DefinitionAmount,
      CustomsDocuments: newData?.CustomsDocuments,
      NDSHReference: newData?.NDSHReference,
      PurchaseSale: newData?.PurchaseSale,
      StudyBook: newData?.StudyBook,
      LearningDefinition: newData?.LearningDefinition,
      carCard: newData?.carCard,
      address: newData?.address,
      collateralType: newData?.collateralType,
      collateralInfo: newData?.collateralInfo,
      collateralPrice: formatPriceWithoutSymbol(
        Number(newData?.collateralPrice)
      ),
      money: formatPriceWithoutSymbol(newData?.money),
      period: newData?.period,
      loanRate: newData?.loanRate,
      dedication: newData?.dedication,
      registerNumber: newData?.registerNumber,
      employment: newData?.employment,
      company: newData?.company,
      experience: newData?.experience,
      income: formatPriceWithoutSymbol(Number(newData?.income)),
      incomeSource: newData?.incomeSource,
      familyIncome: formatPriceWithoutSymbol(Number(newData?.familyIncome)),
      creditScore: newData?.creditScore
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getLoan(loanId);
        const newData = response?.data?.data;
        setReset(newData);
        setRefetchLoan(true);
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };
    fetchData();
  }, [loanId]);
  // images
  const methods = useForm({
    resolver: yupResolver(schema)
  });
  const { handleSubmit, reset } = methods;

  const onSubmit = async data => {
    try {
      if (Object.keys(data).length === 0) {
        toast.warning('Та мэдээллээ гүйцэт оруулна уу', {
          theme: 'colored'
        });
      } else {
        const income = data.income.replace(/,/g, '');
        const collateralPrice = data.collateralPrice.replace(/,/g, '');
        const money = data.money.replace(/,/g, '');
        const familyIncome = data.familyIncome.replace(/,/g, '');
        data.income = income;
        data.collateralPrice = collateralPrice;
        data.money = money;
        data.familyIncome = familyIncome;
        const result = await updateLoan(loanId, data);
        if (result.data.success) {
          setReset(result.data.data);
          toast.success('Амжилттай шинэчилэгдлээ', {
            theme: 'colored'
          });
        } else {
          toast.warning('Мэдээлэл хадгалахад алдаа гарлаа', {
            theme: 'colored'
          });
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <FormProvider {...methods}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Row className="g-3">
          <Col xs={12}>
            <LoanHeader />
          </Col>
          <Col lg={8}>
            <LoanBasicInformation />
            <LoanInfo />
            <LoanVerified />
            <LoanFiles />
          </Col>
          <Col lg={4}>
            <div className="sticky-sidebar">
              <LoanStatus />
              <LoanAddress />
              <Collateral />
            </div>
          </Col>
        </Row>
      </Form>
    </FormProvider>
  );
};

export default LoanUpdate;
