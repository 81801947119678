import React, { useEffect, useState } from 'react';
import WeeklySales from './WeeklySales';
import { Row, Col } from 'react-bootstrap';
// import { AuthWizardContext } from '../../../context/Context';
import TotalOrder from './TotalOrder';
import MarketShare from './MarketShare';
import DeviceAnalytics from './DeviceAnalytics';
import RunningProjects from './RunningProjects';
import UseragentProjects from './UseragentProjects';
import { getLogs } from 'lib/apiService';

const Dashboard = () => {
  // /const { user } = useContext(AuthWizardContext);
  const [filterAction, setFilterAction] = useState('all');
  // const [visitorAgent, setVisitorAgent] = useState({});
  const [userLogs, setUserLogs] = useState([]);
  const [logAnalytics, setLogAnalytics] = useState({});

  const fetchData = async () => {
    try {
      const response = await getLogs();

      if (response.data && response.data.data) {
        // Check if the response has valid data
        const data = response.data.data;
        setUserLogs(data);
        setLogAnalytics(response.data.counter);
      } else {
        console.error('Invalid company data received:', response.data);
      }
    } catch (error) {
      console.error('Error fetching company data:', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    const filteredLogs = userLogs
      .map(log => {
        if (log.action == filterAction) {
          return log;
        }
      })
      .filter(Boolean);

    setUserLogs(filteredLogs);
  }, [filterAction]);

  return (
    <>
      <Row className="g-3 mb-3">
        <Col md={6} xxl={3}>
          <WeeklySales totalVisitors={[]} />
        </Col>
        <Col md={6} xxl={3}>
          <TotalOrder todayVisitors={[]} />
        </Col>
        <Col md={6} xxl={3}>
          <MarketShare browserAnalytics={[]} radius={['100%', '87%']} />
        </Col>
        <Col md={6} xxl={3}>
          <DeviceAnalytics deviceAnalytics={[]} radius={['100%', '87%']} />
        </Col>
      </Row>

      <Row className="g-3 mb-3">
        <Col lg={6}>
          <RunningProjects
            userLogs={userLogs}
            setFilterAction={setFilterAction}
            analytics={logAnalytics}
          />
        </Col>
        <Col lg={6}>
          <UseragentProjects visitors={[]} />
        </Col>
      </Row>
    </>
  );
};

export default Dashboard;
