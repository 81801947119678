import React from 'react';
import { Col, Row } from 'react-bootstrap';
import SendmoneyPage from './SendmoneyPage';

const Sendmoney = () => {
  return (
    <>
      <Row className="g-3">
        <Col lg={12}>
          <SendmoneyPage />
        </Col>
      </Row>
    </>
  );
};

export default Sendmoney;
