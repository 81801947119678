import IconButton from 'components/common/IconButton';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Col, Form, Row, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { toggleActivationUsers, deleteUsers } from 'lib/apiService';

const CustomersTableHeader = ({ selectedRowIds, customers, refetch }) => {
  const navigage = useNavigate();
  const [actionType, setActionType] = useState('editable');
  const keyArray = Object.keys(selectedRowIds).map(Number);
  const newUpdateIds = keyArray.map(item => {
    return customers[item]?._id;
  });

  const handleSubmit = e => {
    e.preventDefault();
    if (newUpdateIds.length > 0 && actionType !== 'editable') {
      if (actionType !== 'delete') {
        toggleActivationUsers(
          newUpdateIds,
          actionType === 'active' ? true : false
        )
          .then(result => {
            if (result.data.success) {
              toast.success(`Амжиллтай шинэчилэгдлээ`, {
                theme: 'colored'
              });
              refetch();
            }
          })
          .catch(error => {
            toast.warning(`${error.response.data.error.message}`, {
              theme: 'colored'
            });
          });
      } else {
        deleteUsers(newUpdateIds)
          .then(result => {
            if (result.data.success) {
              toast.success(`Амжиллтай устгагдлаа`, {
                theme: 'colored'
              });
              refetch();
            }
          })
          .catch(error => {
            toast.warning(`${error.response.data.error.message}`, {
              theme: 'colored'
            });
          });
      }
    }
  };

  const handleFieldChange = e => {
    setActionType(e.target.value);
  };

  const handleGoTo = () => {
    navigage('/customers/customer-invite');
  };
  return (
    <Row className="flex-between-center">
      <Col xs={4} sm="auto" className="d-flex align-items-center pe-0">
        <h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">Ажилтан</h5>
      </Col>
      <Col xs={8} sm="auto" className="ms-auto text-end ps-0">
        {Object.keys(selectedRowIds).length > 0 ? (
          <div className="d-flex">
            <Form.Select
              size="sm"
              aria-label="Bulk actions"
              onChange={handleFieldChange}
            >
              <option value="editable">Засварлах</option>
              <option value="active">Нээх</option>
              <option value="closed">Хаах</option>
              <option value="delete">Устгах</option>
            </Form.Select>
            <Button
              type="button"
              variant="falcon-default"
              size="sm"
              className="ms-2"
              onClick={handleSubmit}
            >
              Хадгалах
            </Button>
          </div>
        ) : (
          <div id="orders-actions">
            <IconButton
              variant="falcon-default"
              size="sm"
              icon="plus"
              transform="shrink-3"
              onClick={handleGoTo}
            >
              <span className="d-none d-sm-inline-block ms-1">
                Ажилтан нэмэх
              </span>
            </IconButton>
            <IconButton
              variant="falcon-default"
              size="sm"
              icon="filter"
              transform="shrink-3"
              className="mx-2"
            >
              <span className="d-none d-sm-inline-block ms-1">Шүүх</span>
            </IconButton>
          </div>
        )}
      </Col>
    </Row>
  );
};

CustomersTableHeader.propTypes = {
  selectedRowIds: PropTypes.object,
  customers: PropTypes.array,
  refetch: PropTypes.func
};

export default CustomersTableHeader;
