import React, { useState, useEffect } from 'react';
import { Card, Dropdown, Col } from 'react-bootstrap';
import SoftBadge from 'components/common/SoftBadge';
import CardDropdown from 'components/common/CardDropdown';
import { Link } from 'react-router-dom';
import LoanTableHeader from './LoanTableHeader';
import LoanHeader from './LoanHeader';
import { toast } from 'react-toastify';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
import {
  getLoans,
  fetchSearchByTextLoan,
  toggleActivationLoan,
  deleteLoan
} from 'lib/apiService';
import classNames from 'classnames';

const LoanList = () => {
  const [getLoanDatas, setGetLoanDatas] = useState([]);
  const [text, setText] = useState('');
  const [pageIndex, setPageIndex] = useState({
    total: 0,
    currentIndex: 0,
    pageCount: 1,
    start: 0,
    end: 0,
    limit: 20,
    prevPage: 0
  });

  const fetchData = async (queryString = {}) => {
    try {
      const response = await getLoans(queryString);

      if (response?.data?.pagination) {
        const { total, prevPage, pageCount, start, end, limit } =
          response.data.pagination;

        setPageIndex(prev => ({
          ...prev,
          total,
          pageCount,
          start,
          end,
          limit,
          prevPage
        }));

        setGetLoanDatas(response?.data?.data);
      }
    } catch (error) {
      console.error('Error fetching user data:', error);
    }
  };

  useEffect(() => {
    fetchData(`?limit=${pageIndex.limit}&page=${pageIndex.currentIndex + 1}`);
  }, [pageIndex.currentIndex]); // Re-fetch data when pageIndex.pageCount changes

  const handleNextPage = () => {
    if (pageIndex.currentIndex < Math.ceil(pageIndex.total / pageIndex.limit)) {
      setPageIndex(prev => ({ ...prev, currentIndex: prev.currentIndex + 1 }));
    }
  };

  const handlePreviousPage = () => {
    if (pageIndex.currentIndex + 1 > 1) {
      setPageIndex(prev => ({ ...prev, currentIndex: prev.currentIndex - 1 }));
    }
  };

  const handleGoToPage = page => {
    setPageIndex(prev => ({ ...prev, currentIndex: page }));
  };

  useEffect(() => {
    async function fetchSearchLoansByParams(queryString) {
      try {
        const result = await fetchSearchByTextLoan(queryString);
        setGetLoanDatas(result.data.data);
      } catch (error) {
        console.error('Error fetching products:', error);
        // Handle errors if necessary
      }
    }

    if (text !== '') {
      const queryParams = new URLSearchParams(window.location.search);
      queryParams.set('searchText', text);

      // Update the URL with the new search text
      window.history.replaceState(
        {},
        '',
        `${window.location.pathname}?${queryParams}`
      );

      fetchSearchLoansByParams({ searchText: text });
    } else {
      window.history.replaceState({}, '', `${window.location.pathname}`);
      fetchData();
    }
  }, [text]);

  const handleChange = e => {
    const value = e.target.value;
    setText(value);
  };

  const handleFieldChange = (value, loanId) => {
    if (value === 'delete') {
      deleteLoan(loanId, value)
        .then(result => {
          if (result.data.success) {
            toast.success(`Амжиллтай устгагдлаа`, {
              theme: 'colored'
            });
            fetchData();
          }
        })
        .catch(error => {
          toast.warning(`${error.response.data.error.message}`, {
            theme: 'colored'
          });
        });
    } else {
      toggleActivationLoan(loanId, value)
        .then(result => {
          if (result.data.success) {
            toast.success(`Амжиллтай шинэчилэгдлээ`, {
              theme: 'colored'
            });
            fetchData();
          }
        })
        .catch(error => {
          toast.warning(`${error.response.data.error.message}`, {
            theme: 'colored'
          });
        });
    }
  };

  const columns = [
    {
      accessor: 'id',
      Header: 'Зээлийн дугаар',
      headerProps: { className: 'pe-2' },
      cellProps: {
        className: 'py-2'
      },
      Cell: rowData => {
        const { _id } = rowData.row.original;
        return (
          <>
            <Link to={`/loan/loan-update/${_id}`}>
              <strong>#{_id.slice(-6)}</strong>
            </Link>{' '}
          </>
        );
      }
    },
    {
      accessor: 'fullname',
      Header: 'Овог нэр',
      Cell: rowData => {
        const { firstName, lastName } = rowData.row.original;
        return <>{lastName + ` / ` + firstName}</>;
      }
    },
    {
      accessor: 'status',
      Header: 'Төлөв',
      headerProps: { className: 'pe-3' },
      Cell: rowData => {
        const { status } = rowData.row.original;
        return status ? (
          <SoftBadge pill bg="secondary" className="me-2">
            Хаагдсан
          </SoftBadge>
        ) : (
          <SoftBadge pill bg="success" className="me-2">
            Нээлттэй
          </SoftBadge>
        );
      }
    },
    {
      accessor: 'email',
      Header: 'Имэйл',
      Cell: rowData => {
        const { email } = rowData.row.original;
        return <>{email}</>;
      }
    },
    {
      accessor: 'phone',
      Header: 'Утас',
      Cell: rowData => {
        const { phone } = rowData.row.original;
        return <>{phone}</>;
      }
    },
    {
      accessor: 'seen',
      Header: 'Төлөв',
      headerProps: {
        className: 'text-center pe-1'
      },
      cellProps: {
        className: 'fs-0'
      },
      Cell: rowData => {
        const { seen } = rowData.row.original;
        return (
          <SoftBadge
            size="sm"
            pill
            bg={classNames({
              success: seen === true,
              secondary: seen === false
            })}
            className="d-flex flex-center"
          >
            <p className="mb-0">
              {seen === true && 'шинэ'}
              {seen === false && 'хуучин'}
            </p>
          </SoftBadge>
        );
      }
    },
    {
      accessor: 'none',
      Header: '',
      disableSortBy: true,
      cellProps: {
        className: 'text-end'
      },
      Cell: rowData => {
        const { _id: loanId } = rowData.row.original;
        return (
          <CardDropdown>
            <div className="py-2">
              <Dropdown.Item
                onClick={() => handleFieldChange('active', loanId)}
              >
                Хаах
              </Dropdown.Item>
              <Dropdown.Item onClick={() => handleFieldChange('close', loanId)}>
                Нээх
              </Dropdown.Item>
              <Dropdown.Divider as="div" />
              <Dropdown.Item
                onClick={() => handleFieldChange('delete', loanId)}
                className="text-danger"
              >
                Устгах
              </Dropdown.Item>
            </div>
          </CardDropdown>
        );
      }
    }
  ];

  return (
    <>
      <Col xs={12} className="mb-3">
        <LoanHeader />
      </Col>

      <AdvanceTableWrapper
        columns={columns}
        data={getLoanDatas}
        selection
        sortable
        pagination
        perPage={pageIndex.limit}
      >
        <Card className="mb-3">
          <Card.Header>
            <LoanTableHeader
              table
              getLoanDatas={getLoanDatas}
              handleChange={handleChange}
              text={text}
              refetch={fetchData}
            />
          </Card.Header>
          <Card.Body className="p-0">
            <AdvanceTable
              table
              headerClassName="bg-200 text-900 text-nowrap align-middle"
              rowClassName="align-middle white-space-nowrap"
              tableProps={{
                size: 'sm',
                striped: true,
                className: 'fs--1 mb-0 overflow-hidden'
              }}
            />
          </Card.Body>
          <Card.Footer>
            {getLoanDatas && getLoanDatas.length > 0 && (
              <AdvanceTablePagination
                canPreviousPage={pageIndex.currentIndex !== 0}
                canNextPage={
                  !(pageIndex.currentIndex + 1 === pageIndex.pageCount)
                }
                previousPage={handlePreviousPage}
                nextPage={handleNextPage}
                pageCount={Math.ceil(pageIndex.total / pageIndex.limit)}
                pageIndex={pageIndex.currentIndex}
                gotoPage={handleGoToPage}
              />
            )}
          </Card.Footer>
        </Card>
      </AdvanceTableWrapper>
    </>
  );
};

export default LoanList;
