import IconButton from 'components/common/IconButton';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import React, { useState, useContext } from 'react';
import { Col, Form, Row, Button } from 'react-bootstrap';
import * as XLSX from 'xlsx';
import { toggleActivationContacts, deleteContacts } from 'lib/apiService';
import { AuthWizardContext } from 'context/Context';

function convertArrayToExcel(array) {
  const worksheet = XLSX.utils.json_to_sheet(array);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet 1');
  const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

  // Create a Blob object with the Excel data
  const blob = new Blob([excelBuffer], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
  });

  // Create a temporary anchor element
  const anchor = document.createElement('a');
  anchor.href = URL.createObjectURL(blob);
  anchor.download = 'data.xlsx'; // Change the filename as desired
  anchor.style.display = 'none';

  // Append the anchor to the document body and click it programmatically
  document.body.appendChild(anchor);
  anchor.click();

  // Clean up
  document.body.removeChild(anchor);
  URL.revokeObjectURL(anchor.href);
}

function removeHtmlTags(text) {
  const tempElement = document.createElement('div');
  tempElement.innerHTML = text;
  return tempElement.textContent || tempElement.innerText || '';
}

const ProductCatalogTableHeader = ({
  selectedRowIds,
  getBlogDatas,
  refetch
}) => {
  const { setRetchContact } = useContext(AuthWizardContext);
  const [actionType, setActionType] = useState('editable');
  const keyArray = Object.keys(selectedRowIds).map(Number);
  const newUpdateIds = keyArray.map(item => {
    return getBlogDatas[item]?._id;
  });

  const handleSubmit = e => {
    e.preventDefault();
    if (newUpdateIds.length > 0 && actionType !== 'editable') {
      if (actionType !== 'delete') {
        toggleActivationContacts(
          newUpdateIds,
          actionType === 'active' ? true : false
        )
          .then(result => {
            if (result.data.success) {
              toast.success(`Амжиллтай шинэчилэгдлээ`, {
                theme: 'colored'
              });
              refetch();
              setRetchContact(true);
            }
          })
          .catch(error => {
            toast.warning(`${error.response.data.error.message}`, {
              theme: 'colored'
            });
          });
      } else {
        deleteContacts(newUpdateIds)
          .then(result => {
            if (result.data.success) {
              toast.success(`Амжиллтай устгагдлаа`, {
                theme: 'colored'
              });
              refetch();
            }
          })
          .catch(error => {
            toast.warning(`${error.response.data.error.message}`, {
              theme: 'colored'
            });
          });
      }
    }
  };

  const handleFieldChange = e => {
    setActionType(e.target.value);
  };

  const handleFieldFilterChange = e => {
    const value = e.target.value;
    let queryString = '';
    switch (value) {
      case 'new':
        queryString = `?sort=-1`;
        break;
      case 'old':
        queryString = `?sort=1`;
        break;
      case 'true':
        queryString = `?seen=true`;
        break;
      case 'false':
        queryString = `?seen=false`;
        break;
      case 'mark':
        queryString = `?archived=true`;
        break;
      case 'notmark':
        queryString = `?archived=false`;
        break;
      default:
        queryString;
    }
    refetch(queryString);
  };

  const handleChangeFileFormat = () => {
    const newRawData = getBlogDatas.map(blog => {
      return {
        firsName: blog.firsName,
        lastName: blog.lastName,
        type: blog.contactType,
        email: blog.email,
        phone: blog.phone,
        description: removeHtmlTags(blog.description)
      };
    });
    convertArrayToExcel(newRawData);
    // convertArrayToCSVDownload(newRawData);
  };

  return (
    <Row className="flex-between-center">
      <Col xs={4} sm="auto" className="d-flex align-items-center pe-0">
        <h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">Мэдээлэл</h5>
      </Col>
      <Col xs={8} sm="auto" className="ms-auto text-end ps-0">
        {Object.keys(selectedRowIds).length > 0 ? (
          <div className="d-flex">
            <Form.Select
              size="sm"
              aria-label="Bulk actions"
              onChange={handleFieldChange}
            >
              <option value="editable">Засварлах</option>
              <option value="closed">Үзсэн гэж бүртгэх</option>
              <option value="delete">Устгах</option>
            </Form.Select>
            <Button
              type="button"
              variant="falcon-default"
              size="sm"
              className="ms-2"
              onClick={handleSubmit}
            >
              Хадгалах
            </Button>
          </div>
        ) : (
          <div className="d-flex">
            <div className="mx-1">
              <Form.Select
                size="sm"
                aria-label="Bulk actions"
                onChange={handleFieldFilterChange}
              >
                <option value="filter">Шүүлтүүр</option>
                <option value="new">Шинэ эхэндээ</option>
                <option value="old">Хуучин эхэндээ</option>
                <option value="true">Үзээгүй</option>
                <option value="false">Үзсэн</option>
                <option value="mark">Тэмдэглэсэн</option>
                <option value="notmark">Тэмдэглээгүй</option>
              </Form.Select>
            </div>
            <div id="orders-actions">
              <IconButton
                variant="falcon-default"
                size="sm"
                icon="external-link-alt"
                transform="shrink-3"
                onClick={handleChangeFileFormat}
              >
                <span className="d-none d-sm-inline-block ms-1">Экспорт</span>
              </IconButton>
            </div>
          </div>
        )}
      </Col>
    </Row>
  );
};

ProductCatalogTableHeader.propTypes = {
  selectedRowIds: PropTypes.object,
  getBlogDatas: PropTypes.array,
  refetch: PropTypes.func
};

export default ProductCatalogTableHeader;
