import React from 'react';
import { Col, Row } from 'react-bootstrap';
import CurrencyChange from './CurrencyChange';

const Currency = () => {
  return (
    <>
      <Row className="g-3">
        <Col lg={12}>
          <CurrencyChange />
        </Col>
      </Row>
    </>
  );
};

export default Currency;
