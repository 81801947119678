import React from 'react';
import { Navigate } from 'react-router-dom';
// import { AuthWizardContext } from '../context/Context';
import Cookies from 'js-cookie';
import PropTypes from 'prop-types';

const PrivateRoute = ({ children, requiredRoles }) => {
  const token = Cookies.get('uiAccessToken');
  const user =
    typeof window !== 'undefined' && JSON.parse(localStorage.getItem('uiUser'));
  // const { user } = useContext(AuthWizardContext);
  const userRole = user?.role ?? null;
  // Show a loading state if user or userRole is not available yet
  if (!token || user === undefined || userRole === null) {
    <Navigate to="/authentication/card/login" />;
  }

  if (userRole) {
    const hasRequiredRoles =
      requiredRoles.length > 0 &&
      requiredRoles?.some(role => {
        return [userRole].includes(role);
      });

    if (!hasRequiredRoles) {
      return <Navigate to="/authentication/card/login" />;
    }
  } else {
    return <Navigate to="/authentication/card/login" />;
  }

  return children;
};

PrivateRoute.propTypes = {
  children: PropTypes.element.isRequired,
  requiredRoles: PropTypes.array
};

export default PrivateRoute;
