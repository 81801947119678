import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import React, { useState } from 'react';
import { Col, Form, Row, Button } from 'react-bootstrap';
import {
  toggleActivationServices,
  deleteServices
} from '../../../lib/apiService';

const ServiceTableHeader = ({ selectedRowIds, getSerivceDatas, refetch }) => {
  const [actionType, setActionType] = useState('editable');
  const keyArray = Object.keys(selectedRowIds).map(Number);

  const newUpdateIds =
    keyArray.length > 0 &&
    keyArray.map(item => {
      return getSerivceDatas[item]?._id;
    });

  const handleSubmit = e => {
    e.preventDefault();
    if (newUpdateIds.length > 0 && actionType !== 'editable') {
      if (actionType !== 'delete') {
        toggleActivationServices(
          newUpdateIds,
          actionType === 'active' ? true : false
        )
          .then(result => {
            if (result.data.success) {
              toast.success(`Амжиллтай шинэчилэгдлээ`, {
                theme: 'colored'
              });
              refetch();
            }
          })
          .catch(error => {
            toast.warning(`${error.response.data.error.message}`, {
              theme: 'colored'
            });
          });
      } else {
        deleteServices(newUpdateIds)
          .then(result => {
            if (result.data.success) {
              toast.success(`Амжиллтай устгагдлаа`, {
                theme: 'colored'
              });
              refetch();
            }
          })
          .catch(error => {
            toast.warning(`${error.response.data.error.message}`, {
              theme: 'colored'
            });
          });
      }
    }
  };

  const handleFieldChange = e => {
    setActionType(e.target.value);
  };

  return (
    <Row className="flex-between-center">
      <Col xs={4} sm="auto" className="d-flex align-items-center pe-0">
        <h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">Үйлчилгээ</h5>
      </Col>
      <Col xs={8} sm="auto" className="ms-auto text-end ps-0">
        {Object.keys(selectedRowIds).length > 0 ? (
          <div className="d-flex">
            <Form.Select
              size="sm"
              aria-label="Bulk actions"
              onChange={handleFieldChange}
            >
              <option value="editable">Засварлах</option>
              <option value="active">Нээх</option>
              <option value="closed">Хаах</option>
              <option value="delete">Устгах</option>
              {/* <option value="archive">Archive</option> */}
            </Form.Select>
            <Button
              type="button"
              variant="falcon-default"
              size="sm"
              className="ms-2"
              onClick={handleSubmit}
            >
              Хадгалах
            </Button>
          </div>
        ) : (
          <div></div>
        )}
      </Col>
    </Row>
  );
};

ServiceTableHeader.propTypes = {
  selectedRowIds: PropTypes.object,
  getBlogDatas: PropTypes.array,
  refetch: PropTypes.func,
  getSerivceDatas: PropTypes.any
};

export default ServiceTableHeader;
