import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';
import PageHeader from 'components/common/PageHeader';
import SoftBadge from 'components/common/SoftBadge';

const ProductCatalogHeader = ({ getCounter }) => {
  return (
    <Row className="g-3 mb-3">
      <Col md={12} xxl={12}>
        <PageHeader title="Бичилтүүд" titleTag="h5">
          <p className="fs--1 mt-3">
            <SoftBadge pill bg="success" className="fs--2 me-2">
              {getCounter.POST || 0} үүсгэсэн
            </SoftBadge>
            <SoftBadge pill bg="warning" className="fs--2 me-2">
              {getCounter.PUT || 0} засварласан
            </SoftBadge>
            <SoftBadge pill bg="danger" className="fs--2">
              {getCounter.DELETE || 0} усгасан
            </SoftBadge>
          </p>
        </PageHeader>
      </Col>
    </Row>
  );
};

ProductCatalogHeader.propTypes = {
  getCounter: PropTypes.any
};

export default ProductCatalogHeader;
