import React, { useState } from 'react';
import { Button, Col, Card, Collapse, Form, Row } from 'react-bootstrap';
import InputField from '../../common/InputFields';
import { register } from 'lib/apiService';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

const ExperienceForm = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    email: '',
    name: '',
    role: ''
  });

  const handleBack = () => {
    navigate(-1);
  };

  const handleSubmit = e => {
    e.preventDefault();
    register(formData)
      .then(result => {
        if (result.data.success) {
          toast.success(`Амжиллтай илгээгдлээ`, {
            theme: 'colored'
          });
          setFormData({
            email: '',
            firstName: '',
            lastName: '',
            password: '123123'
          });
        }
      })
      .catch(error => {
        toast.warning(`${error.response.data.error.message}`, {
          theme: 'colored'
        });
      });
  };

  const handleChange = e =>
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });

  return (
    <Row className="g-3">
      <Col lg={12}>
        <Card className="h-100">
          <Card.Header>
            <h5 className="mb-0">Ажилтан нэмэх</h5>
          </Card.Header>
          <Card.Body className="bg-light pb-0">
            <div>
              <Collapse in={true}>
                <div>
                  {/* <ExperienceForm /> */}
                  <Form onSubmit={handleSubmit}>
                    <InputField
                      type="email"
                      value={formData.email}
                      label="Имэйл хаяг"
                      name="email"
                      handleChange={handleChange}
                    />

                    <div className="mb-3 row">
                      <div className="text-lg-end form-label col-form-label col-sm-3">
                        Үүрэг
                      </div>
                      <div className="col-md-7 col-sm-9">
                        <Form.Select name="role" onChange={handleChange}>
                          <option value="">сонголт</option>
                          {[
                            { name: 'Админ', value: 'admin' },
                            { name: 'Нийтлэгч', value: 'worker' }
                          ].map((category, i) => (
                            <option key={i} value={category.value}>
                              {category.name}
                            </option>
                          ))}
                        </Form.Select>
                      </div>
                    </div>

                    <InputField
                      type="text"
                      value={formData.name}
                      label="Өөрийн нэр"
                      name="name"
                      handleChange={handleChange}
                    />

                    <Form.Group as={Row} className="mb-5">
                      <Col sm={{ span: 7, offset: 3 }}>
                        <Button
                          type="button"
                          variant="falcon-default"
                          className="me-2"
                          onClick={handleBack}
                        >
                          Болих
                        </Button>
                        <Button type="submit">Ажилтанд илгээх</Button>
                      </Col>
                    </Form.Group>
                  </Form>
                </div>
              </Collapse>
            </div>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};

export default ExperienceForm;
