import React, { useState, useContext, useEffect } from 'react';
import { Card, Col, Row, Form, Button } from 'react-bootstrap';
import SettingsSideBar from './SettingsSideBar';
import SettingsHeader from './SettingsHeader';
import Flex from 'components/common/Flex';
import { toast } from 'react-toastify';
import { updateCompany } from 'lib/apiService';
import { AuthWizardContext } from 'context/Context';

const SettingsStructure = () => {
  const { company, setCompany } = useContext(AuthWizardContext);
  const [observer, setObserver] = useState(false);
  const [formData, setFormData] = useState([
    { name: 'facebook', link: '' },
    { name: 'instagram', link: '' },
    { name: 'twitter', link: '' },
    { name: 'youtube', link: '' }
  ]);

  const [edit, setEdit] = useState({});

  useEffect(() => {
    if (company) {
      if (company.socialLinks.length > 0) {
        setFormData(company.socialLinks);
      }
    }
  }, [company]);

  const handleSubmit = async e => {
    e.preventDefault();
    try {
      updateCompany(company._id, { socialLinks: formData })
        .then(result => {
          if (result.data.success) {
            toast.success(`Амжиллтай илгээгдлээ`, {
              theme: 'colored'
            });
            setFormData(result.data.data.socialLinks);
            setCompany(result.data.data);
            localStorage.setItem('company', JSON.stringify(result.data.data));
            setEdit({ ...edit, address: !edit.address });
          }
        })
        .catch(error => {
          console.log(
            '🚀 ~ file: SettingsLegal.js:44 ~ handleSubmit ~ error:',
            error
          );
          toast.warning(`${error.response.data.error.message}`, {
            theme: 'colored'
          });
        });
    } catch (error) {
      console.error(error);
    }
  };

  const handleInputChange = (index, field, value) => {
    setObserver(true);
    const newInputs = [...formData];
    newInputs[index][field] = value;
    setFormData(newInputs);
  };

  return (
    <Row className="g-3">
      <Col xl={4} xxl={3}>
        <SettingsSideBar />
      </Col>
      <Col xl={8} xxl={9}>
        <SettingsHeader data={' Сайт ашиглалтын нөхцөл'} />
        <Card className="mt-4">
          <Card.Header className="bg-light d-flex justify-content-between align-items-md-center">
            <h6 className="mb-0">Холбоосууд</h6>
            <button
              className="btn btn-link"
              onClick={() => setEdit({ ...edit, address: !edit.address })}
            >
              {' '}
              {edit.address ? (
                <h6
                  className="text-primary"
                  onClick={() => setEdit({ ...edit, address: !edit.address })}
                >
                  Буцах
                </h6>
              ) : (
                <h6 className="text-primary">Засах</h6>
              )}
            </button>
          </Card.Header>
          <Card.Body>
            <Row>
              {edit.address ? (
                <Form onSubmit={handleSubmit}>
                  <Row className="gx-2 gy-3 px-4">
                    <Col xs="12">
                      <Form.Group>
                        {formData &&
                          formData.map((input, index) => (
                            <div className="d-flex mb-3" key={index}>
                              <div className="me-2">
                                <Form.Control
                                  type="text"
                                  style={{ marginRight: '10px' }}
                                  placeholder="Түлхүүр"
                                  value={input.name}
                                  disabled
                                />
                              </div>
                              <div className="me-2 w-100">
                                <Form.Control
                                  type="text"
                                  style={{ marginRight: '10px' }}
                                  placeholder="Утга"
                                  value={input.link}
                                  onChange={e =>
                                    handleInputChange(
                                      index,
                                      'link',
                                      e.target.value
                                    )
                                  }
                                />
                              </div>
                            </div>
                          ))}
                      </Form.Group>
                    </Col>
                    <Col lg={12} xxl={12}>
                      <Flex
                        alignItems="center"
                        justifyContent="end"
                        className={`px-2 ${
                          edit.address ? 'py-x1' : 'py'
                        } position-relative`}
                      >
                        <Button
                          variant="falcon-default"
                          className="me-2 mb-1"
                          onClick={() =>
                            setEdit({ ...edit, address: !edit.address })
                          }
                        >
                          Буцах
                        </Button>
                        <Button
                          variant="primary"
                          type="submit"
                          className="me-2 mb-1"
                          disabled={!observer}
                        >
                          Хадгалах
                        </Button>
                      </Flex>
                    </Col>
                  </Row>
                </Form>
              ) : (
                <div>
                  {formData.length > 0 ? (
                    formData.map((item, index) => (
                      <div
                        key={index}
                        className="d-flex justify-content-between mb-2 mt-2 px-3"
                      >
                        <div className="me-4">{item.name}</div>
                        <div>{item.link}</div>
                      </div>
                    ))
                  ) : (
                    <div className="alert alert-warning" role="alert">
                      Mэдээлэл байхгүй байна
                    </div>
                  )}
                </div>
              )}
            </Row>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};

export default SettingsStructure;
