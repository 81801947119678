import IconButton from 'components/common/IconButton';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import React, { useState } from 'react';
import { Col, Form, Row, Button } from 'react-bootstrap';
import * as XLSX from 'xlsx';
import { toggleActivationLoans, deleteLoans } from '../../../lib/apiService';

function convertArrayToExcel(array) {
  const worksheet = XLSX.utils.json_to_sheet(array);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet 1');
  const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

  // Create a Blob object with the Excel data
  const blob = new Blob([excelBuffer], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
  });

  // Create a temporary anchor element
  const anchor = document.createElement('a');
  anchor.href = URL.createObjectURL(blob);
  anchor.download = 'data.xlsx'; // Change the filename as desired
  anchor.style.display = 'none';

  // Append the anchor to the document body and click it programmatically
  document.body.appendChild(anchor);
  anchor.click();

  // Clean up
  document.body.removeChild(anchor);
  URL.revokeObjectURL(anchor.href);
}

const LoanTableHeader = ({
  selectedRowIds,
  getLoanDatas,
  handleChange,
  text,
  refetch
}) => {
  const [actionType, setActionType] = useState('editable');
  const keyArray = Object.keys(selectedRowIds).map(Number);
  const newUpdateIds = keyArray.map(item => {
    return getLoanDatas[item]?._id;
  });

  const handleSubmit = e => {
    e.preventDefault();
    if (newUpdateIds.length > 0 && actionType !== 'editable') {
      if (actionType !== 'delete') {
        toggleActivationLoans(
          newUpdateIds,
          actionType === 'active' ? true : false
        )
          .then(result => {
            if (result.data.success) {
              toast.success(`Амжиллтай шинэчилэгдлээ`, {
                theme: 'colored'
              });
              refetch();
            }
          })
          .catch(error => {
            toast.warning(`${error.response.data.error.message}`, {
              theme: 'colored'
            });
          });
      } else {
        deleteLoans(newUpdateIds)
          .then(result => {
            if (result.data.success) {
              toast.success(`Амжиллтай устгагдлаа`, {
                theme: 'colored'
              });
              refetch();
            }
          })
          .catch(error => {
            toast.warning(`${error.response.data.error.message}`, {
              theme: 'colored'
            });
          });
      }
    }
  };

  const handleFieldChange = e => {
    setActionType(e.target.value);
  };

  const handleFieldFilterChange = e => {
    const value = e.target.value;
    let queryString = '';
    switch (value) {
      case 'new':
        queryString = `?sort=-1`;
        break;
      case 'old':
        queryString = `?sort=1`;
        break;
      case 'az':
        queryString = `?firstName=-1`;
        break;
      case 'za':
        queryString = `?firstName=1`;
        break;
      default:
        queryString;
    }
    refetch(queryString);
  };

  const handleChangeFileFormat = () => {
    const newRawData = getLoanDatas.map(item => {
      return {
        firstName: item.firstName,
        lastName: item.lastName,
        phone: item.phone,
        email: item.email
      };
    });
    convertArrayToExcel(newRawData);
    // convertArrayToCSVDownload(newRawData);
  };

  return (
    <Row className="flex-between-center">
      <Col xs={4} sm="auto" className="d-flex align-items-center pe-0">
        <h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">Мэдээлэл</h5>
      </Col>
      <Col xs={8} sm="auto" className="ms-auto text-end ps-0">
        {Object.keys(selectedRowIds).length > 0 ? (
          <div className="d-flex">
            <Form.Select
              size="sm"
              aria-label="Bulk actions"
              onChange={handleFieldChange}
            >
              <option value="editable">Засварлах</option>
              <option value="active">Нээх</option>
              <option value="closed">Хаах</option>
              <option value="delete">Устгах</option>
              {/* <option value="archive">Archive</option> */}
            </Form.Select>
            <Button
              type="button"
              variant="falcon-default"
              size="sm"
              className="ms-2"
              onClick={handleSubmit}
            >
              Хадгалах
            </Button>
          </div>
        ) : (
          <div className="d-flex">
            <div>
              {' '}
              <Form.Control
                variant="falcon-default"
                type="text"
                placeholder="Хайлт"
                name="searchText"
                value={text}
                size="sm"
                onChange={handleChange}
              />
            </div>
            <div className="mx-1">
              <Form.Select
                size="sm"
                aria-label="Bulk actions"
                onChange={handleFieldFilterChange}
              >
                <option value="filter">Шүүлтүүр</option>
                <option value="new">Шинэ эхэндээ</option>
                <option value="old">Хуучин эхэндээ</option>
                <option value="az">A - Z</option>
                <option value="za">Z - A</option>
              </Form.Select>
            </div>
            <div id="orders-actions">
              <IconButton
                variant="falcon-default"
                size="sm"
                icon="external-link-alt"
                transform="shrink-3"
                onClick={handleChangeFileFormat}
              >
                <span className="d-none d-sm-inline-block ms-1">Экспорт</span>
              </IconButton>
            </div>
          </div>
        )}
      </Col>
    </Row>
  );
};

LoanTableHeader.propTypes = {
  selectedRowIds: PropTypes.object,
  getLoanDatas: PropTypes.array,
  refetch: PropTypes.func,
  handleChange: PropTypes.func,
  text: PropTypes.any
};

export default LoanTableHeader;
