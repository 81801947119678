import PropTypes from 'prop-types';
import React, { useState, useContext } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { AuthWizardContext } from '../../context/Context';
import { login } from 'lib/apiService';
import Cookies from 'js-cookie';
import { toast } from 'react-toastify';

const LoginForm = ({ hasLabel, layout }) => {
  const { setUser } = useContext(AuthWizardContext);
  const navigate = useNavigate();

  // State
  const [formData, setFormData] = useState({
    email: '',
    password: '',
    remember: false
  });

  // Handler
  const handleSubmit = async e => {
    e.preventDefault();
    const loginData = {
      email: formData.email,
      password: formData.password
    };
    login(loginData)
      .then(result => {
        try {
          toast.success(`Амжиллтай нэвтэрлээ ${formData.email}`, {
            theme: 'colored'
          });

          Cookies.set('uiAccessToken', result?.data?.token, {
            expires: 7
          });
          localStorage.setItem('uiUser', JSON.stringify(result?.data?.user));

          setUser(result?.data?.user);
          setTimeout(() => {
            navigate('/');
          }, 500);
        } catch (error) {
          console.log(error);
        }
      })
      .catch(error => {
        toast.warning(`${error.response.data.error.message}`, {
          theme: 'colored'
        });
      });
  };

  const handleFieldChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Group className="mb-3">
        {hasLabel && <Form.Label>Имэйл хаяг</Form.Label>}
        <Form.Control
          placeholder={!hasLabel ? 'Email address' : ''}
          value={formData.email}
          name="email"
          onChange={handleFieldChange}
          type="email"
        />
      </Form.Group>

      <Form.Group className="mb-3">
        {hasLabel && <Form.Label>Нууц үг</Form.Label>}
        <Form.Control
          placeholder={!hasLabel ? 'Password' : ''}
          value={formData.password}
          name="password"
          onChange={handleFieldChange}
          type="password"
        />
      </Form.Group>

      <Row className="justify-content-between align-items-center">
        <Col xs="auto">
          <Form.Check type="checkbox" id="rememberMe" className="mb-0">
            <Form.Check.Input
              type="checkbox"
              name="remember"
              checked={formData.remember}
              onChange={e =>
                setFormData({
                  ...formData,
                  remember: e.target.checked
                })
              }
            />
            <Form.Check.Label className="mb-0 text-700">
              Сануулах
            </Form.Check.Label>
          </Form.Check>
        </Col>

        <Col xs="auto">
          <Link
            className="fs--1 mb-0"
            to={`/authentication/${layout}/forgot-password`}
          >
            Нууц үг солих
          </Link>
        </Col>
      </Row>

      <Form.Group>
        <Button
          type="submit"
          color="primary"
          className="mt-3 w-100"
          disabled={!formData.email || !formData.password}
        >
          Нэвтрэх
        </Button>
      </Form.Group>
    </Form>
  );
};

LoginForm.propTypes = {
  layout: PropTypes.string,
  hasLabel: PropTypes.bool
};

LoginForm.defaultProps = {
  layout: 'simple',
  hasLabel: false
};

export default LoginForm;
