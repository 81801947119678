import React from 'react';
import { Card, Col, Row, ButtonGroup, ToggleButton } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';

const LoanStatus = () => {
  const { setValue, watch } = useFormContext();
  const status = watch('status');

  const radios = [
    { name: 'Нээх', value: true },
    { name: 'Хаах', value: false }
  ];

  const handleRadioChange = value => {
    setValue('status', value);
  };

  return (
    <Card className="mb-3">
      <Card.Header as="h6" className="bg-light">
        Хүсэлтийн төлөв
      </Card.Header>
      <Card.Body>
        <Row className="gx-2 gy-3">
          <Col md="12">
            <ButtonGroup>
              {radios.map((radio, idx) => (
                <ToggleButton
                  key={radio.value}
                  id={`radio-${idx}`}
                  type="radio"
                  size="sm"
                  variant={idx % 2 ? 'outline-secondary' : 'outline-secondary'}
                  name="status"
                  value={radio.value}
                  checked={status === radio.value}
                  onChange={() => handleRadioChange(radio.value)}
                >
                  {radio.name}
                </ToggleButton>
              ))}
            </ButtonGroup>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default LoanStatus;
