import React from 'react';
// import { Link } from 'react-router-dom';
import LoginForm from 'components/authentication/LoginForm';
import AuthCardLayout from 'layouts/AuthCardLayout';

const Login = () => {
  return (
    <AuthCardLayout
      leftSideContent={
        <p className="text-white">
          Админтай холбогдох
          <br />
          <div className="d-flex flex-column">
            <span className="fs--1">утас: 99134731</span>
          </div>
        </p>
      }
    >
      <h3>Нэвтрэх хэсэг</h3>
      <LoginForm layout="card" hasLabel />
    </AuthCardLayout>
  );
};

export default Login;
