import React, { useState, useEffect } from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import FalconCardHeader from 'components/common/FalconCardHeader';
import { toast } from 'react-toastify';
import { createCurrency, getCurrency } from 'lib/apiService';
import IconButton from 'components/common/IconButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';

const CurrencyChange = () => {
  // ok yamar sonin bainaa ene
  const [inputs, setInputs] = useState([
    {
      code: '',
      name: '',
      buying_rate: 0,
      selling_rate: 0,
      nr_buying_rate: 0,
      nr_selling_rate: 0
    }
  ]);
  const [observer, setObserver] = useState(false);

  const handleInputChange = (index, field, value) => {
    setObserver(true);
    const newInputs = [...inputs];
    newInputs[index][field] = value;
    setInputs(newInputs);
  };

  const fetchData = async (queryString = {}) => {
    try {
      const response = await getCurrency(queryString);
      const formData = response?.data?.data;
      const newData = formData.map(data => {
        return {
          code: data.code,
          name: data.name,
          buying_rate: data.buying_rate,
          selling_rate: data.selling_rate,
          nr_buying_rate: data.nr_buying_rate,
          nr_selling_rate: data.nr_selling_rate
        };
      });

      setInputs(newData);
    } catch (error) {
      console.error('Error fetching user data:', error);
    }
  };

  useEffect(() => {
    fetchData('?currency=USD|RUB|CNY|JPY');
  }, []);

  const handleSubmit = async e => {
    e.preventDefault();

    setObserver(false);

    const { data } = await createCurrency(inputs);
    if (data.success) {
      fetchData('?currency=USD|RUB|CNY|JPY');
      toast.success(`Амжилттай шинэчилэгдлээ`, {
        theme: 'colored'
      });
    } else {
      toast.error(`can't set avatar`, {
        theme: 'colored'
      });
    }
  };

  const inputValues = {
    code: '',
    name: '',
    buying_rate: 0,
    selling_rate: 0
  };

  const addInput = () => {
    if (inputs.length < 5) {
      const newInput = { ...inputValues }; // Clone the inputValues
      setInputs([...inputs, newInput]);
    }
  };
  const removeInput = index => {
    const newInputs = [...inputs];
    newInputs.splice(index, 1);
    setInputs(newInputs);
  };
  return (
    <Card>
      <FalconCardHeader title="Валют ханш" />
      <Card.Body className="bg-light">
        <Form onSubmit={handleSubmit}>
          {inputs &&
            inputs.map((input, index) => (
              <div key={index}>
                {' '}
                <Row
                  className={`mb-5 g-3 ${
                    index !== 0 && 'border-top border-gray-300'
                  }`}
                >
                  <Form.Group as={Col} lg={1} controlId="firstName">
                    <Form.Label>Код</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="код"
                      value={inputs[index]?.code || ''}
                      name="code"
                      onChange={e =>
                        handleInputChange(
                          index,
                          'code',
                          e.target.value.toUpperCase()
                        )
                      }
                    />
                  </Form.Group>

                  <Form.Group as={Col} lg={2} controlId="lastName">
                    <Form.Label>Нэр</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="нэр"
                      value={inputs[index]?.name || ''}
                      name="name"
                      onChange={e =>
                        handleInputChange(index, 'name', e.target.value)
                      }
                    />
                  </Form.Group>
                  <Form.Group as={Col} lg={2} controlId="email">
                    <Form.Label>Авах ханш</Form.Label>
                    <Form.Control
                      type="number"
                      placeholder="авах"
                      value={inputs[index]?.buying_rate || ''}
                      name="buying_rate"
                      onChange={e =>
                        handleInputChange(index, 'buying_rate', e.target.value)
                      }
                    />
                  </Form.Group>

                  <Form.Group as={Col} lg={2} controlId="phone">
                    <Form.Label>Зарах ханш</Form.Label>
                    <Form.Control
                      type="number"
                      placeholder="зарах"
                      value={inputs[index]?.selling_rate || ''}
                      name="selling_rate"
                      onChange={e =>
                        handleInputChange(index, 'selling_rate', e.target.value)
                      }
                    />
                  </Form.Group>

                  <Form.Group as={Col} lg={2} controlId="email">
                    <Form.Label>Б/бус авах ханш</Form.Label>
                    <Form.Control
                      type="number"
                      placeholder="авах"
                      value={inputs[index]?.nr_buying_rate || ''}
                      name="nr_buying_rate"
                      onChange={e =>
                        handleInputChange(
                          index,
                          'nr_buying_rate',
                          e.target.value
                        )
                      }
                    />
                  </Form.Group>

                  <Form.Group as={Col} lg={2} controlId="phone">
                    <Form.Label>Б/бус зарах ханш</Form.Label>
                    <Form.Control
                      type="number"
                      placeholder="зарах"
                      value={inputs[index]?.nr_selling_rate || ''}
                      name="nr_selling_rate"
                      onChange={e =>
                        handleInputChange(
                          index,
                          'nr_selling_rate',
                          e.target.value
                        )
                      }
                    />
                  </Form.Group>
                  {index > 0 && (
                    <Form.Group
                      as={Col}
                      lg={1}
                      controlId="phone"
                      style={{ marginTop: '47px' }}
                    >
                      <Button
                        variant="warning"
                        onClick={() => removeInput(index)}
                      >
                        <FontAwesomeIcon icon={faTrashAlt} />
                      </Button>
                    </Form.Group>
                  )}
                </Row>
              </div>
            ))}
          {inputs.length < 5 && (
            <IconButton
              variant="primary"
              className="me-2 mb-1"
              icon="plus"
              transform="shrink-3"
              onClick={addInput}
            >
              Нөхцөл нэмэх
            </IconButton>
          )}
          <div className="text-end">
            <Button variant="primary" type="submit" disabled={!observer}>
              Шинэчлэх
            </Button>
          </div>
        </Form>
      </Card.Body>
    </Card>
  );
};

export default CurrencyChange;
