import React from 'react';
import { Card, Col, Form, Row } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';

const ServiceLocale = () => {
  const categories = [
    { value: 'mn', label: 'Mонгол хэл' },
    { value: 'en', label: 'Англи хэл' }
  ];
  const {
    register,
    formState: { errors }
  } = useFormContext();

  return (
    <Card className="mb-3">
      <Card.Header as="h6" className="bg-light">
        Хэлний сонголт
      </Card.Header>
      <Card.Body>
        <Row className="gx-2 gy-3">
          <Col md="12">
            <Form.Group>
              <Form.Label>Хэл сонгох</Form.Label>
              <Form.Select {...register(`locale`)} isInvalid={!!errors.locale}>
                <option value="">сонголт</option>
                {categories.map((category, i) => (
                  <option key={i} value={category.value}>
                    {category.label}
                  </option>
                ))}
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                {errors.locale?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default ServiceLocale;
