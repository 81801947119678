import React, { useState, useEffect } from 'react';
import { Card, Dropdown, Col } from 'react-bootstrap';
import { toast } from 'react-toastify';
import CardDropdown from 'components/common/CardDropdown';
import ReportTableHeader from './ReportTableHeader';
import ReportHeader from './Parts/ReportHeader';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
import { getReports, deleteReport } from '../../../lib/apiService';

const ReportList = () => {
  const [getSerivceDatas, setGetServiceDatas] = useState([]);
  const [pageIndex, setPageIndex] = useState({
    total: 0,
    currentIndex: 0,
    pageCount: 1,
    start: 0,
    end: 0,
    limit: 20,
    prevPage: 0
  });

  const fetchData = async (queryString = {}) => {
    try {
      const response = await getReports(queryString);

      if (response?.data?.pagination) {
        const { total, prevPage, pageCount, start, end, limit } =
          response.data.pagination;

        setPageIndex(prev => ({
          ...prev,
          total,
          pageCount,
          start,
          end,
          limit,
          prevPage
        }));

        setGetServiceDatas(response?.data?.data);
      }
    } catch (error) {
      console.error('Error fetching user data:', error);
    }
  };

  useEffect(() => {
    fetchData(`?limit=${pageIndex.limit}&page=${pageIndex.currentIndex + 1}`);
  }, [pageIndex.currentIndex]);

  const handleNextPage = () => {
    if (pageIndex.currentIndex < Math.ceil(pageIndex.total / pageIndex.limit)) {
      setPageIndex(prev => ({ ...prev, currentIndex: prev.currentIndex + 1 }));
    }
  };

  const handlePreviousPage = () => {
    if (pageIndex.currentIndex + 1 > 1) {
      setPageIndex(prev => ({ ...prev, currentIndex: prev.currentIndex - 1 }));
    }
  };

  const handleGoToPage = page => {
    setPageIndex(prev => ({ ...prev, currentIndex: page }));
  };

  const handleFieldChange = (value, shopId) => {
    if (value === 'delete') {
      deleteReport(shopId, value)
        .then(result => {
          if (result.data.success) {
            toast.success(`Амжиллтай устгагдлаа`, {
              theme: 'colored'
            });
            fetchData();
          }
        })
        .catch(error => {
          toast.warning(`${error.response.data.error.message}`, {
            theme: 'colored'
          });
        });
    }
  };

  const columns = [
    {
      accessor: 'name',
      Header: 'Дугаар',
      headerProps: { className: 'pe-5' },
      cellProps: {
        className: 'py-2'
      },
      Cell: rowData => {
        const { _id } = rowData.row.original;
        return (
          <>
            <strong>#{_id.slice(-6)}</strong>
          </>
        );
      }
    },
    {
      accessor: 'date',
      Header: 'Огноо',
      headerProps: { className: 'pe-7' },
      Cell: rowData => {
        const { createdAt } = rowData.row.original;
        return <>{createdAt.split('T')[0]}</>;
      }
    },
    {
      accessor: 'address',
      Header: 'Гарчиг',
      Cell: rowData => {
        const { name } = rowData.row.original;
        return <>{name.substring(0, 40)}</>;
      }
    },
    {
      accessor: 'files',
      Header: 'Тайлангууд',
      Cell: rowData => {
        const { file } = rowData.row.original;
        return (
          <div className="d-flex flex-column">
            {file.map((item, index) => (
              <div key={index}>{item.url}</div>
            ))}
          </div>
        );
      }
    },
    {
      accessor: 'none',
      Header: '',
      disableSortBy: true,
      cellProps: {
        className: 'text-end'
      },
      Cell: rowData => {
        const { _id: reportId } = rowData.row.original;
        return (
          <CardDropdown>
            <div className="py-2">
              <Dropdown.Item
                onClick={() => handleFieldChange('delete', reportId)}
                className="text-danger"
              >
                Устгах
              </Dropdown.Item>
            </div>
          </CardDropdown>
        );
      }
    }
  ];

  return (
    <>
      <Col xs={12} className="mb-3">
        <ReportHeader />
      </Col>

      <AdvanceTableWrapper
        columns={columns}
        data={getSerivceDatas}
        selection={false}
        sortable
        pagination
        perPage={pageIndex.limit}
      >
        <Card className="mb-3">
          <Card.Header>
            <ReportTableHeader
              table
              getSerivceDatas={getSerivceDatas}
              refetch={fetchData}
            />
          </Card.Header>
          <Card.Body className="p-0">
            <AdvanceTable
              table
              headerClassName="bg-200 text-900 text-nowrap align-middle"
              rowClassName="align-middle white-space-nowrap"
              tableProps={{
                size: 'sm',
                striped: true,
                className: 'fs--1 mb-0 overflow-hidden'
              }}
            />
          </Card.Body>
          <Card.Footer>
            {getSerivceDatas && getSerivceDatas.length > 0 && (
              <AdvanceTablePagination
                canPreviousPage={pageIndex.currentIndex !== 0}
                canNextPage={
                  !(pageIndex.currentIndex + 1 === pageIndex.pageCount)
                }
                previousPage={handlePreviousPage}
                nextPage={handleNextPage}
                pageCount={Math.ceil(pageIndex.total / pageIndex.limit)}
                pageIndex={pageIndex.currentIndex}
                gotoPage={handleGoToPage}
              />
            )}
          </Card.Footer>
        </Card>
      </AdvanceTableWrapper>
    </>
  );
};

export default ReportList;
