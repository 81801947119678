import React, { useState, useContext, useEffect } from 'react';
import Flex from 'components/common/Flex';
import { Card, Row, Col, Badge } from 'react-bootstrap';
import IconButton from 'components/common/IconButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MessageHeader from './MessageHeader';
import Avatar from 'components/common/Avatar';
import team1 from 'assets/img/avatar.png';
import { Link, useParams, useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import { getContact, getContacts } from 'lib/apiService';
import womensDay from 'assets/img/icons/spot-illustrations/international-women-s-day-2.png';
import { AuthWizardContext } from 'context/Context';

const MessageDetail = () => {
  const { setRetchContact } = useContext(AuthWizardContext);
  const navigate = useNavigate();
  const [marked, setMarked] = useState(true);
  const [getMessageDatas, setGetMessageDatas] = useState([]);
  const { messageId } = useParams();
  const [messageData, setMessageData] = useState([]);
  const [sequence, setSequence] = useState(null);
  const [copyText, setCopyText] = useState({
    isCopy: false,
    text: 'Хуулах'
  });

  const fetchDataAll = async (queryString = {}) => {
    try {
      const response = await getContacts(queryString);
      setGetMessageDatas(response?.data?.data);
      response?.data?.data.forEach((item, index) => {
        if (item._id === messageId) {
          setSequence(index + 1);
        }
      });
    } catch (error) {
      console.error('Error fetching user data:', error);
    }
  };

  const fetchData = async messageId => {
    try {
      const response = await getContact(messageId);
      setMessageData(response?.data?.data);
      setRetchContact(true);
    } catch (error) {
      console.error('Error fetching user data:', error);
    }
  };

  useEffect(() => {
    if (messageId) {
      fetchData(messageId);
      fetchDataAll();
    }
  }, [messageId]);

  const gotoBack = () => {
    if (getMessageDatas[sequence - 2]?._id !== undefined) {
      navigate(`/message/message-detail/${getMessageDatas[sequence - 2]?._id}`);
    }
  };

  const gotoForward = () => {
    if (getMessageDatas[sequence]?._id !== undefined) {
      navigate(`/message/message-detail/${getMessageDatas[sequence]?._id}`);
    }
  };

  const handleCopyToClipboard = () => {
    const phoneToCopy = messageData.phone;

    // Create a temporary input element to copy the text to the clipboard
    const tempInput = document.createElement('input');
    tempInput.value = phoneToCopy;
    document.body.appendChild(tempInput);

    // Select the text inside the input element
    tempInput.select();
    tempInput.setSelectionRange(0, 99999); // For mobile devices

    // Execute the copy command
    document.execCommand('copy');

    // Remove the temporary input element
    document.body.removeChild(tempInput);

    setCopyText({ isCopy: true, text: 'Хуулагдсан' });
  };

  return (
    <>
      {getMessageDatas && (
        <MessageHeader
          getMessageDatas={getMessageDatas}
          messageData={messageData}
          sequence={sequence}
          gotoBack={gotoBack}
          gotoForward={gotoForward}
          refetch={setMessageData}
        />
      )}
      <Card>
        <Card.Header>
          <Row>
            <Col md as={Flex}>
              <Avatar src={team1} size="2xl" />
              <div className="flex-1 ms-2">
                <h5 className="mb-0">{messageData.contactType}</h5>
                <a
                  className="text-800 fs--1"
                  href={`mailto:${messageData.email}`}
                >
                  <span className="fw-semi-bold">{messageData.firstName}</span>
                  <span className="ms-1 text-500">
                    &lt;{messageData.email}&gt;
                  </span>
                </a>
              </div>
            </Col>
            <Col
              md="auto"
              as={Flex}
              alignItems="center"
              className="ps-6 ps-md-3"
            >
              <small>{messageData.createdAt}</small>
              <FontAwesomeIcon
                onClick={() => setMarked(!marked)}
                icon={messageData.archived ? 'star' : ['far', 'star']}
                className={classNames(
                  'ms-2 fs--1',
                  { 'text-warning': marked, 'text-300': !marked },
                  'cursor-pointer'
                )}
              />
            </Col>
          </Row>
        </Card.Header>
        <Card.Body className="bg-light">
          <Row className="justify-content-center">
            <Col lg={8} xxl={6}>
              <Card className="shadow-none mb-3">
                <img src={womensDay} alt="" className="card-img-top" />
                <Card.Body>
                  <Flex>
                    <Link to="/user/profile">
                      <Avatar size="3xl" src={team1} />
                    </Link>
                    <div className="flex-1 ms-2">
                      <h6 className="mb-0">
                        <Link to="/user/profile">
                          {messageData.firstName + ` ` + messageData.lastName}
                        </Link>
                      </h6>

                      <p className="fs--1 mb-0">{messageData.email}</p>

                      <div className="d-flex align-items-baseline">
                        <IconButton
                          className="mt-1 py-0 border me-2"
                          variant="light"
                          size="sm"
                          icon="phone"
                          transform="shrink-5"
                          onClick={handleCopyToClipboard}
                        >
                          <span className="fs--1">{messageData.phone}</span>
                        </IconButton>
                        {copyText.isCopy ? (
                          <Badge bg="success" size="sm" className="me-2">
                            {copyText.text}
                          </Badge>
                        ) : (
                          <Badge bg="secondary" size="sm" className="me-2">
                            {copyText.text}
                          </Badge>
                        )}
                      </div>

                      <div className="border-dashed border-bottom my-3" />
                    </div>
                    <div className="d-none d-sm-block">
                      <Badge bg="success" className="me-2">
                        {messageData.contactType}
                      </Badge>
                    </div>
                  </Flex>
                  <div className="d-flex mx-0 mx-sm-5 px-0 px-sm-3">
                    <h3 className="fw-semi-bold">{messageData.firsName}</h3>
                    <p>{messageData.description}</p>
                  </div>
                  {/* <div className="text-center">
                    <Button variant="success" size="lg" className="my-3">
                      Browse Courses
                    </Button>
                    <small className="d-block">
                      For any technical issues faced, please contact{' '}
                      <a href="#!">Customer Support</a>.
                    </small>
                  </div> */}
                </Card.Body>
              </Card>
              {/* <div className="text-center">
                <IconGroup
                  icons={socialIcons}
                  className="justify-content-center"
                />
                <small>
                  If you wish to unsubscribe from all future emails, please{' '}
                  <a href="#!">click here</a>
                </small>
              </div> */}
            </Col>
          </Row>
        </Card.Body>
        <Card.Footer>
          <Row className="justify-content-between">
            <Col>
              {/* <IconButton
                as={Link}
                variant="falcon-default"
                size="sm"
                icon="reply"
                to="#!"
              >
                Reply
              </IconButton> */}
              <a
                href="https://mail.google.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <IconButton
                  variant="falcon-default"
                  size="sm"
                  className="ms-2"
                  icon="location-arrow"
                >
                  Хариу бичих
                </IconButton>
              </a>
            </Col>
            <Col xs="auto" as={Flex} alignItems="center">
              {messageId && (
                <small>
                  {sequence} of {getMessageDatas.length}
                </small>
              )}

              <IconButton
                variant="falcon-default"
                size="sm"
                icon="chevron-left"
                className="ms-2"
                disabled={getMessageDatas[sequence - 2]?._id === undefined}
                onClick={gotoBack}
              />
              <IconButton
                variant="falcon-default"
                size="sm"
                icon="chevron-right"
                className="ms-2"
                disabled={getMessageDatas[sequence]?._id === undefined}
                onClick={gotoForward}
              />
            </Col>
          </Row>
        </Card.Footer>
      </Card>
    </>
  );
};

export default MessageDetail;
