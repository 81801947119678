import React, { useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import NewHeader from './NewHeader';
import ReportBasicInformation from './ReportBasicInformation';
import ReportUpload from './ReportUpload';
import * as yup from 'yup';
import { createReport } from 'lib/apiService';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

const schema = yup
  .object({
    name: yup.string().required('Энэ талбарыг оруулна уу!')
  })
  .required();

const ReportAdd = () => {
  const navigage = useNavigate();
  const methods = useForm({
    resolver: yupResolver(schema)
  });
  const { handleSubmit, reset } = methods;
  const [files, setFiles] = useState([]);
  const [imageUrls, setImageUrls] = useState([]);
  const [loading, setLoading] = useState(false);

  const imageUploader = async selectedFile => {
    setLoading(true);
    if (selectedFile && selectedFile.type === 'application/pdf') {
      const formData = new FormData();
      formData.append('pdf', selectedFile);

      try {
        // Send a POST request to the server for file upload
        const response = await fetch(
          `${process.env.REACT_APP_SERVER_URL}/api/v1/files/upload`,
          {
            method: 'POST',
            body: formData
          }
        );

        if (response.ok) {
          console.log('File uploaded successfully.');
        } else {
          console.error('File upload failed.');
        }
        const { path } = await response.json();

        const newImageUrl = {
          url: String(path),
          public_id: selectedFile.name,
          bucketname: selectedFile.path
        };
        setImageUrls(prevUrls => [...prevUrls, newImageUrl]);
        setLoading(false);
      } catch (error) {
        console.error('Error uploading file:', error);
      }
    } else {
      console.error('Please select a PDF file.');
    }
  };

  const onSubmit = data => {
    if (Object.keys(data).length === 0) {
      toast.warning('Та мэдээллээ гүйцэт оруулна уу', {
        theme: 'colored'
      });
    } else {
      data.file = imageUrls;
      createReport(data)
        .then(result => {
          if (result.data.success) {
            toast.success(`Амжиллтай үүсгэгдлээ`, {
              theme: 'colored'
            });

            reset();
            navigage(`/report/report-list`);
          }
        })
        .catch(error => {
          toast.warning(`${error.response.data.error.message}`, {
            theme: 'colored'
          });
        });
    }
  };

  const handleRemove = path => {
    let removeItem = '';
    imageUrls.forEach(item => {
      if (item.public_id === path) {
        removeItem = item.url;
      }
    });

    setFiles(files.filter(file => file.path !== path));
    const updatedImageUrls = imageUrls.filter(
      image => image.public_id !== path
    );
    setImageUrls(updatedImageUrls);
    if (removeItem) {
      fetch(
        `${process.env.REACT_APP_SERVER_URL}/api/v1/files/upload?filename=${removeItem}`,
        {
          method: 'DELETE'
        }
      )
        .then(response => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.json();
        })
        .then(data => {
          console.log(data);
        })
        .catch(error => {
          console.error('CLOUDINARY UPLOAD FAILED', error);
        });
    }
  };

  return (
    <FormProvider {...methods}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Row className="g-3">
          <Col xs={8}>
            <NewHeader />
          </Col>
          <Col lg={8}>
            <ReportBasicInformation />

            <ReportUpload
              setFiles={setFiles}
              files={files}
              imageUploader={imageUploader}
              handleRemove={handleRemove}
              loading={loading}
            />
          </Col>
        </Row>
      </Form>
    </FormProvider>
  );
};

export default ReportAdd;
