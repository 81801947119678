import React from 'react';
import { Card, Col, Form, Row } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';

const LoanAddress = () => {
  const {
    register,
    formState: { errors }
  } = useFormContext();

  return (
    <Card className="mb-3">
      <Card.Header as="h6" className="bg-light">
        Хаяг
      </Card.Header>
      <Card.Body>
        <Row className="gx-2 gy-3">
          <Col md="12">
            <Form.Group>
              <Form.Label>Хот аймаг:</Form.Label>
              <Form.Control
                type="text"
                isInvalid={!!errors.city}
                {...register('address.city')}
              />
              <Form.Control.Feedback type="invalid">
                {errors.address?.city.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col md="12">
            <Form.Group>
              <Form.Label>Дүүрэг сум:</Form.Label>
              <Form.Control
                type="text"
                isInvalid={!!errors.district}
                {...register('address.district')}
              />
              <Form.Control.Feedback type="invalid">
                {errors.address?.district.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col md="12">
            <Form.Group>
              <Form.Label>Хороо баг:</Form.Label>
              <Form.Control
                type="text"
                isInvalid={!!errors.khoroo}
                {...register('address.khoroo')}
              />
              <Form.Control.Feedback type="invalid">
                {errors.address?.khoroo.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col md="12">
            <Form.Group>
              <Form.Label>Хаягийн дэлгэрэнгүй:</Form.Label>
              <Form.Control
                type="text"
                isInvalid={!!errors.description}
                {...register('address.description')}
              />
              <Form.Control.Feedback type="invalid">
                {errors.address?.description.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default LoanAddress;
