import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { Button, Form } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import classNames from 'classnames';

const PasswordResetFormInvited = ({ hasLabel }) => {
  const location = useLocation();
  // State
  const [formData, setFormData] = useState({
    password: '',
    confirmPassword: ''
  });

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const password = searchParams.get('password');
    const confirmPassword = searchParams.get('confirmPassword');

    if (password && confirmPassword) {
      setFormData({
        password,
        confirmPassword
      });
    }
  }, [location.search]);

  // Handler
  const handleSubmit = e => {
    e.preventDefault();
    if (formData.password === formData.confirmPassword) {
      console.log('dddsss');
    } else {
      toast.warning(`Батлах нууц үг таарахгүй байна`, { theme: 'colored' });
    }
  };

  const handleFieldChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  return (
    <Form
      className={classNames('mt-3', { 'text-left': hasLabel })}
      onSubmit={handleSubmit}
    >
      <Form.Group className="mb-3">
        {hasLabel && <Form.Label>Шинэ нууц үг</Form.Label>}
        <Form.Control
          placeholder={!hasLabel ? 'New Password' : ''}
          value={formData.password}
          name="password"
          onChange={handleFieldChange}
          type="password"
        />
      </Form.Group>

      <Form.Group className="mb-3">
        {hasLabel && <Form.Label>Хуучин нууц үг</Form.Label>}
        <Form.Control
          placeholder={!hasLabel ? 'Confirm Password' : ''}
          value={formData.confirmPassword}
          name="confirmPassword"
          onChange={handleFieldChange}
          type="password"
        />
      </Form.Group>

      <Button
        type="submit"
        className="w-100"
        disabled={!formData.password || !formData.confirmPassword}
      >
        Нууц үг солих
      </Button>
    </Form>
  );
};

PasswordResetFormInvited.propTypes = {
  hasLabel: PropTypes.bool
};

export default PasswordResetFormInvited;
