import React, { useState, useEffect, useContext } from 'react';
import { Card, Col, Dropdown } from 'react-bootstrap';
import CardDropdown from 'components/common/CardDropdown';
import SoftBadge from 'components/common/SoftBadge';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import MessageTableHeader from './MessageTableHeader';
import MessageHeader from './MessageHeader';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
import {
  deleteContact,
  toggleActivationContact,
  getContacts,
  toggleSeenContact
} from 'lib/apiService';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AuthWizardContext } from 'context/Context';

const MessageList = () => {
  const [getMessageDatas, setGetMessageDatas] = useState([]);
  const [pageIndex, setPageIndex] = useState({
    total: 0,
    currentIndex: 0,
    pageCount: 1,
    start: 0,
    end: 0,
    limit: 20,
    prevPage: 0
  });

  const { setRetchContact } = useContext(AuthWizardContext);

  const fetchData = async (queryString = {}) => {
    try {
      const response = await getContacts(queryString);

      if (response?.data?.pagination) {
        const { total, prevPage, pageCount, start, end, limit } =
          response.data.pagination;

        setPageIndex(prev => ({
          ...prev,
          total,
          pageCount,
          start,
          end,
          limit,
          prevPage
        }));

        setGetMessageDatas(response?.data?.data);
      }
    } catch (error) {
      console.error('Error fetching user data:', error);
    }
  };

  useEffect(() => {
    fetchData(`?limit=${pageIndex.limit}&page=${pageIndex.currentIndex + 1}`);
  }, [pageIndex.currentIndex]); // Re-fetch data when pageIndex.pageCount changes

  const handleNextPage = () => {
    if (pageIndex.currentIndex < Math.ceil(pageIndex.total / pageIndex.limit)) {
      setPageIndex(prev => ({ ...prev, currentIndex: prev.currentIndex + 1 }));
    }
  };

  const handlePreviousPage = () => {
    if (pageIndex.currentIndex + 1 > 1) {
      setPageIndex(prev => ({ ...prev, currentIndex: prev.currentIndex - 1 }));
    }
  };

  const removeMessage = contactId => {
    deleteContact(contactId)
      .then(result => {
        if (result.data.success) {
          toast.success(`Амжиллтай устгагдлаа`, {
            theme: 'colored'
          });
          fetchData();
        }
      })
      .catch(error => {
        toast.warning(`${error.response.data.error.message}`, {
          theme: 'colored'
        });
      });
  };

  const archiveMessage = (contactId, value) => {
    toggleActivationContact(contactId, !value)
      .then(result => {
        if (result.data.success) {
          toast.success(`Амжиллтай хадгаллаа`, {
            theme: 'colored'
          });
          fetchData();
        }
      })
      .catch(error => {
        toast.warning(`${error.response.data.error.message}`, {
          theme: 'colored'
        });
      });
  };

  const seenMessage = (contactId, value) => {
    toggleSeenContact(contactId, !value)
      .then(result => {
        if (result.data.success) {
          toast.success(`Амжиллтай хадгаллаа`, {
            theme: 'colored'
          });
          fetchData();
        }
      })
      .catch(error => {
        toast.warning(`${error.response.data.error.message}`, {
          theme: 'colored'
        });
      });
  };

  const handleGoToPage = page => {
    setPageIndex(prev => ({ ...prev, currentIndex: page }));
  };

  const columns = [
    {
      accessor: '_id',
      Header: 'Зурвас дугаар',
      headerProps: { className: 'pe-1' },
      cellProps: {
        className: 'py-2'
      },
      Cell: rowData => {
        const { _id } = rowData.row.original;
        return (
          <>
            {
              <Link to={`/message/message-detail/${_id}`}>
                <strong>#{_id.slice(-6)}</strong>
              </Link>
            }
          </>
        );
      }
    },
    {
      accessor: 'date',
      Header: 'Огноо',
      headerProps: { className: 'pe-1' },
      Cell: rowData => {
        const { createdAt } = rowData.row.original;
        return <>{createdAt.split('T')[0]}</>;
      }
    },
    {
      accessor: 'phone',
      Header: 'Утас',
      headerProps: { className: 'pe-1' },
      Cell: rowData => {
        const { phone } = rowData.row.original;
        return <>{phone}</>;
      }
    },
    {
      accessor: 'email',
      Header: 'Имэйл',
      headerProps: { className: 'pe-1' },
      Cell: rowData => {
        const { email } = rowData.row.original;
        return <>{email}</>;
      }
    },
    {
      accessor: 'content',
      Header: 'Контент',
      headerProps: { className: 'pe-1' },
      Cell: rowData => {
        const { description } = rowData.row.original;
        if (description === null) {
          return null;
        }
        return <>{description.substring(0, 25)}</>;
      }
    },
    {
      accessor: 'seen',
      Header: 'Төлөв',
      headerProps: {
        className: 'text-center pe-1'
      },
      cellProps: {
        className: 'fs-0'
      },
      Cell: rowData => {
        const { seen } = rowData.row.original;
        return (
          <SoftBadge
            size="sm"
            pill
            bg={classNames({
              success: seen === true,
              secondary: seen === false
            })}
            className="d-flex flex-center"
          >
            <p className="mb-0">
              {seen === true && 'шинэ'}
              {seen === false && 'хуучин'}
            </p>
          </SoftBadge>
        );
      }
    },
    {
      accessor: 'archived',
      Header: 'Хадгаласан',
      headerProps: { className: 'pe-1' },
      Cell: rowData => {
        const { archived, _id } = rowData.row.original;
        return (
          <>
            <FontAwesomeIcon
              onClick={() => archiveMessage(_id, archived)}
              icon={archived ? 'star' : ['far', 'star']}
              className={classNames(
                'ms-2 fs--1',
                { 'text-warning': archived, 'text-300': !archived },
                'cursor-pointer'
              )}
            />
          </>
        );
      }
    },
    {
      accessor: 'settings',
      Header: '',
      disableSortBy: true,
      cellProps: {
        className: 'text-end'
      },
      Cell: rowData => {
        const { _id: messageId, archived, seen } = rowData.row.original;
        return (
          <CardDropdown>
            <div className="py-2">
              {seen && (
                <Dropdown.Item
                  onClick={() => {
                    seenMessage(messageId, seen);
                    setRetchContact(true);
                  }}
                >
                  Үзсэн гэж тэмдэглэх
                </Dropdown.Item>
              )}
              <Dropdown.Item
                onClick={() => archiveMessage(messageId, archived)}
              >
                Архивлах
              </Dropdown.Item>
              <Dropdown.Divider as="div" />
              <Dropdown.Item
                onClick={() => removeMessage(messageId)}
                className="text-danger"
              >
                Устгах
              </Dropdown.Item>
            </div>
          </CardDropdown>
        );
      }
    }
  ];

  return (
    <>
      <Col xs={12} className="mb-3">
        <MessageHeader getMessageDatas={getMessageDatas} />
      </Col>
      <AdvanceTableWrapper
        columns={columns}
        data={getMessageDatas}
        selection
        sortable
        pagination
        perPage={pageIndex.limit}
      >
        <Card className="mb-3">
          <Card.Header>
            <MessageTableHeader
              table
              getBlogDatas={getMessageDatas}
              refetch={fetchData}
            />
          </Card.Header>
          <Card.Body className="p-0">
            <AdvanceTable
              table
              headerClassName="bg-200 text-900 text-nowrap align-middle"
              rowClassName="align-middle white-space-nowrap"
              tableProps={{
                size: 'sm',
                striped: true,
                className: 'fs--1 mb-0 overflow-hidden'
              }}
            />
          </Card.Body>
          <Card.Footer>
            {getMessageDatas && getMessageDatas.length > 0 && (
              <AdvanceTablePagination
                canPreviousPage={pageIndex.currentIndex !== 0}
                canNextPage={
                  !(pageIndex.currentIndex + 1 === pageIndex.pageCount)
                }
                previousPage={handlePreviousPage}
                nextPage={handleNextPage}
                pageCount={Math.ceil(pageIndex.total / pageIndex.limit)}
                pageIndex={pageIndex.currentIndex}
                gotoPage={handleGoToPage}
              />
            )}
          </Card.Footer>
        </Card>
      </AdvanceTableWrapper>
    </>
  );
};

export default MessageList;
