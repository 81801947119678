import React, { useState, useEffect } from 'react';
import {
  Button,
  Card,
  Form,
  Col,
  Row,
  Modal,
  Alert,
  ProgressBar
} from 'react-bootstrap';
import { toast } from 'react-toastify';
import Flex from 'components/common/Flex';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SettingsSideBar from './SettingsSideBar';
import SettingsHeader from './SettingsHeader';
import { Link } from 'react-router-dom';
import { register, getUsers } from 'lib/apiService';

const SettingsAccount = () => {
  const [observer, setObserver] = useState(false);
  const [formData, setFormData] = useState({
    email: '',
    name: '',
    role: ''
  });
  const [modalJob, setModalJob] = useState(false);
  const [customers, setCustomers] = useState([]);
  const [admins, setAdmins] = useState([]);

  const fetchData = async () => {
    try {
      const response = await getUsers({});
      const customers = response?.data?.data;
      const workers = customers.filter(customer => customer.role === 'worker');
      const newAdmins = customers.filter(customer => customer.role === 'admin');
      setCustomers(workers);
      setAdmins(newAdmins);
    } catch (error) {
      console.error('Error fetching user data:', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleSubmit = e => {
    e.preventDefault();
    if (formData?.email && formData?.name && formData?.role) {
      register(formData)
        .then(result => {
          if (result.data.success) {
            toast.success(`Амжиллтай илгээгдлээ`, {
              theme: 'colored'
            });
            setFormData({
              email: '',
              firstName: '',
              lastName: '',
              password: '123123'
            });
            setModalJob(false);
          }
        })
        .catch(error => {
          toast.warning(`${error.response.data.error.message}`, {
            theme: 'colored'
          });
        });
      setFormData({
        email: '',
        name: '',
        role: ''
      });
      setObserver(false);
    } else {
      toast.warning(`Мэдээллээ гүйцэт оруулах ёстой`, {
        theme: 'colored'
      });
    }
  };

  const handleChangeEmail = e => {
    setObserver(true);
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const calculateProcent = value => {
    switch (value) {
      case 'admin':
        return 100;
      case 'worker':
        return 50;
      default:
        return 0;
    }
  };

  return (
    <Row className="g-3">
      <Col xl={4} xxl={3}>
        <SettingsSideBar />
      </Col>
      <Col xl={8} xxl={9}>
        <SettingsHeader data={'Хэрэглэгчид ба зөвшөөрөл'} />

        <Card>
          <Card.Header className="bg-light d-flex justify-content-between align-items-md-center">
            <div>
              <h6 className="mb-0">Админ эрхтэй хэрэглэгч</h6>
            </div>
          </Card.Header>
          <Card.Body>
            <div className="my-x3 mt-2 m-lg-2 ">
              <h6 className="mb-0  fw-bold">Зөвшөөрөл</h6>
              <h6 className="mt-lg-2">
                Админ хэрэглэгч нь бүрэн гүйцэт эрхтэй вэбийг эзэмшигч эрх юм.
              </h6>
            </div>
            {admins &&
              admins.map(user => (
                <Flex alignItems="center" className="mb-2" key={user._id}>
                  <FontAwesomeIcon
                    icon="user-circle"
                    className="fs-3 me-2 text-700"
                  />
                  <Link to="#!">
                    <div className="flex-1">
                      <h6 className="mb-0 text-primary">{user?.name}</h6>
                    </div>
                  </Link>
                </Flex>
              ))}
          </Card.Body>
        </Card>
        <Card className="mt-4">
          <Card.Body>
            <h6 className="fw-bold">Ажилтан нэмэх </h6>
            <h6 className="">
              Та ажилтан нэмэхдээ ажилтаны эрх үүргийг өөрийн хүссэнээр
              тохируулах боломжтой юм.
            </h6>
            <Button
              className="btn btn-success mt-3"
              size="sm"
              onClick={() => setModalJob(!modalJob)}
            >
              {' '}
              Ажилтан нэмэх
            </Button>
          </Card.Body>
        </Card>
        <Card className="mt-4">
          <Card.Body>
            <div className="my-x3 mt-2 m-lg-2 ">
              <h6 className="mb-0  fw-bold">Ажилтан</h6>
              <h6 className="mt-lg-2">
                Ажилтан нь Worker гэсэн төлөвтэй бөгөөд Publisher нь мэдээлэлтэй
                холбоотой хэсгийг удирдах боломжтой ажилтан юм.
              </h6>
            </div>
            {customers.length > 0 ? (
              customers.map(user => (
                <Flex alignItems="center" className="mb-2" key={user._id}>
                  <FontAwesomeIcon
                    icon="user-circle"
                    className="fs-3 me-2 text-700"
                  />
                  <span className="text-primary">
                    <div className="flex-1">
                      <h6 className="mb-0 text-primary">{user?.name}</h6>
                    </div>
                  </span>
                </Flex>
              ))
            ) : (
              <div className="px-2">
                <Alert variant={'secondary'}>
                  <h6 className="fs--1 mb-0 text-600">
                    Одоогоор үүссэн ажилтан байхгүй байна
                  </h6>
                </Alert>
              </div>
            )}
          </Card.Body>
        </Card>
      </Col>

      <Modal
        show={modalJob}
        onHide={() => {
          setModalJob(false),
            setFormData({
              email: '',
              name: '',
              role: ''
            });
        }}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Form onSubmit={handleSubmit}>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter text-sm">
              Шинэ ажилтан
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="px-4 my-2">
            <label>Имэйл</label>
            <input
              className="form-control"
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChangeEmail}
            />
            <Row className="mt-4">
              <Col sm={12} md={6}>
                <label>Ажилтны нэр</label>
                <input
                  className="form-control"
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleChangeEmail}
                />
              </Col>
              <Col sm={12} md={6}>
                <label>Үүрэг</label>
                <Form.Select name="role" onChange={handleChangeEmail}>
                  <option value="">сонголт</option>
                  {[
                    { name: 'Админ', value: 'admin' },
                    { name: 'Нийтлэгч', value: 'worker' }
                  ].map((category, i) => (
                    <option key={i} value={category.value}>
                      {category.name}
                    </option>
                  ))}
                </Form.Select>
              </Col>
              <Col className="my-4" sm={12} md={12}>
                <Form.Label>{'Үүрэг'}</Form.Label>
                <ProgressBar
                  variant="success"
                  now={calculateProcent(formData.role)}
                  label={parseInt(calculateProcent(formData.role)) + '%'}
                />
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button
              onClick={() => {
                setModalJob(false),
                  setFormData({
                    email: '',
                    name: '',
                    role: ''
                  });
              }}
            >
              Хаах
            </Button>
            <Button
              type="submit"
              className="btn btn-success"
              disabled={!observer}
            >
              Хадгалах
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </Row>
  );
};

export default SettingsAccount;
