import React from 'react';
import PropTypes from 'prop-types';
import Flex from 'components/common/Flex';
import { Card, OverlayTrigger, Tooltip, Dropdown } from 'react-bootstrap';
import IconButton from 'components/common/IconButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router-dom';
import { deleteContact, toggleActivationContact } from 'lib/apiService';
import { toast } from 'react-toastify';

const ItemButton = ({ tootltip, icon, className, onClick }) => {
  return (
    <OverlayTrigger
      overlay={
        <Tooltip style={{ position: 'fixed' }} id="overlay-trigger-example">
          {tootltip}
        </Tooltip>
      }
    >
      <div className="d-inline-block">
        <IconButton
          variant="falcon-default"
          size="sm"
          icon={icon}
          className={className}
          onClick={onClick}
        />
      </div>
    </OverlayTrigger>
  );
};

const MessageHeader = ({
  messageData,
  getMessageDatas,
  sequence,
  gotoBack,
  gotoForward,
  refetch
}) => {
  const navigate = useNavigate();
  const removeMessage = contactId => {
    deleteContact(contactId)
      .then(result => {
        if (result.data.success) {
          toast.success(`Амжиллтай устгагдлаа`, {
            theme: 'colored'
          });
          navigate(`/message/message-list`);
        }
      })
      .catch(error => {
        toast.warning(`${error.response.data.error.message}`, {
          theme: 'colored'
        });
      });
  };

  const archiveMessage = contactId => {
    toggleActivationContact(contactId, !messageData.archived)
      .then(result => {
        if (result.data.success) {
          toast.success(`Амжиллтай хадгаллаа`, {
            theme: 'colored'
          });
          refetch(result.data.data);
        }
      })
      .catch(error => {
        toast.warning(`${error.response.data.error.message}`, {
          theme: 'colored'
        });
      });
  };
  const handlePrint = () => {
    window.print();
  };
  return (
    <Card className="mb-3">
      <Card.Body as={Flex} justifyContent="between">
        <div>
          <ItemButton
            tootltip="Буцах"
            icon="arrow-left"
            onClick={() => {
              navigate('/message/message-list');
            }}
          />
          <span className="mx-1 mx-sm-2 text-300">|</span>
          <ItemButton
            tootltip="Архивлах"
            icon="archive"
            onClick={() => archiveMessage(messageData._id)}
          />
          <ItemButton
            tootltip="Хэвлэх"
            icon="print"
            className="ms-1 ms-sm-2 d-none d-sm-inline-block"
            onClick={() => handlePrint()}
          />
          <ItemButton
            tootltip="Устгах"
            icon="trash-alt"
            className="ms-1 ms-sm-2"
            onClick={() => removeMessage(messageData._id)}
          />
        </div>
        <Flex>
          <div className="d-none d-md-block">
            <small>
              {' '}
              {sequence} of {getMessageDatas.length}
            </small>

            <IconButton
              variant="falcon-default"
              size="sm"
              icon="chevron-left"
              className="ms-2"
              disabled={getMessageDatas[sequence - 2]?._id === undefined}
              onClick={gotoBack}
            />

            <IconButton
              variant="falcon-default"
              size="sm"
              icon="chevron-right"
              className="ms-2"
              disabled={getMessageDatas[sequence]?._id === undefined}
              onClick={gotoForward}
            />
          </div>
          <Dropdown className="font-sans-serif" align="end">
            <Dropdown.Toggle
              variant="falcon-default"
              size="sm"
              className="text-600 dropdown-caret-none ms-2"
            >
              <FontAwesomeIcon icon="cog" />
            </Dropdown.Toggle>
            <Dropdown.Menu className="py-2">
              <Dropdown.Item onClick={() => archiveMessage(messageData._id)}>
                {messageData.archived ? 'Архивлахгүй' : 'Архивлах'}
              </Dropdown.Item>
              <Dropdown.Item onClick={() => handlePrint()}>
                Хэвлэх
              </Dropdown.Item>
              <Dropdown.Divider />
              <Dropdown.Item onClick={() => removeMessage(messageData._id)}>
                Устгах
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </Flex>
      </Card.Body>
    </Card>
  );
};

ItemButton.propTypes = {
  tootltip: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  className: PropTypes.string,
  onClick: PropTypes.func
};

MessageHeader.propTypes = {
  messageData: PropTypes.any,
  getMessageDatas: PropTypes.array,
  sequence: PropTypes.any,
  gotoBack: PropTypes.func,
  gotoForward: PropTypes.func,
  refetch: PropTypes.func
};

export default MessageHeader;
