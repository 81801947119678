import React, { useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import ServiceBasicInformation from './NewServiceForms/ServiceBasicInformation';
import ServiceHeader from './NewServiceForms/ServiceHeader';
import ServiceUpload from './NewServiceForms/ServiceUpload';
import ServiceDetails from './NewServiceForms/ServiceDetails';
import SerivceConditions from './NewServiceForms/SerivceConditions';
import ServiceRequirements from './NewServiceForms/ServiceRequirements';
import ServiceAdvantages from './NewServiceForms/ServiceAdvantages';
import ServiceLocale from './NewServiceForms/ServiceLocale';
import ServiceType from './NewServiceForms/ServiceType';
import axios from 'axios';
import Resizer from 'react-image-file-resizer';
import { createService } from 'lib/apiService';

const schema = yup
  .object({
    title: yup.string().required('Энэ талбарыг оруулна уу!'),
    description: yup.string().required('Энэ талбарыг оруулна уу!'),
    serviceType: yup.string().required('Энэ талбарыг оруулна уу!'),
    locale: yup.string().required('Энэ талбарыг оруулна уу!'),
    advantages: yup
      .array()
      .of(yup.string().required('Энэ талбарыг оруулна уу!')),
    requirements: yup
      .array()
      .of(yup.string().required('Энэ талбарыг оруулна уу!')),
    conditions: yup.array().of(
      yup.object().shape({
        key: yup.string().required('Хэмжээ талбарыг оруулна уу!'),
        value: yup.string().required('Ширхэг талбарыг оруулна уу!')
      })
    )
  })
  .required();

const ServiceAdd = () => {
  const navigage = useNavigate();
  const submittedValues = {};
  const methods = useForm({
    resolver: yupResolver(schema)
  });
  const [loading, setLoading] = useState(false);
  const { handleSubmit, reset } = methods;
  const [files, setFiles] = useState([]);
  const [imageUrls, setImageUrls] = useState([]);

  const imageUploader = image => {
    setLoading(true);
    Resizer.imageFileResizer(
      image,
      800,
      600,
      'WEBP',
      100,
      0,
      uri => {
        axios
          .post(`${process.env.REACT_APP_FILE_URL}/uploadimage`, {
            image: uri
          })
          .then(response => {
            if (response.data?.url && response.data?.public_id) {
              const newImageUrl = {
                url: String(response?.data?.url),
                public_id: String(response?.data?.public_id),
                bucketname: image.name
              };

              setImageUrls(prevUrls => [...prevUrls, newImageUrl]);
              setLoading(false);
            }
          })
          .catch(error => {
            console.log('CLOUDINARY UPLOAD FAILED', error);
          });
      },
      'base64',
      400,
      300
    );
  };

  const onSubmit = data => {
    if (Object.keys(data).length === 0) {
      toast.warning('Та мэдээллээ гүйцэт оруулна уу', {
        theme: 'colored'
      });
    } else {
      data.image = imageUrls;
      createService(data)
        .then(result => {
          if (result.data.success) {
            toast.success(`Амжиллтай үүсгэгдлээ`, {
              theme: 'colored'
            });

            const keys = Object.keys(data);
            for (const key of keys) {
              if (
                key !== 'conditions' ||
                key !== 'requirements' ||
                key !== 'advantages'
              ) {
                submittedValues[key] = '';
              } else {
                submittedValues[key] = [];
              }
            }
            reset();
            navigage(`/service/service-update/${result?.data?.data?._id}`);
          }
        })
        .catch(error => {
          toast.warning(`${error.response.data.error.message}`, {
            theme: 'colored'
          });
        });
    }
  };

  const handleRemove = path => {
    let removeItem = '';
    imageUrls.forEach(item => {
      if (item.bucketname === path) {
        removeItem = item.public_id;
      }
    });
    setFiles(files.filter(file => file.path !== path));
    const updatedImageUrls = imageUrls.filter(
      image => image.bucketname !== path
    );
    setImageUrls(updatedImageUrls);
    if (removeItem) {
      axios
        .post(`https://admin.gegeensoft.live/api/v1/images/removeimage`, {
          public_id: removeItem
        })
        .then(response => {
          console.log(response);
        })
        .catch(error => {
          console.log('CLOUDINARY UPLOAD FAILED', error);
        });
    }
  };

  return (
    <FormProvider {...methods}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Row className="g-3">
          <Col xs={12}>
            <ServiceHeader />
          </Col>
          <Col lg={8}>
            <ServiceBasicInformation />

            <ServiceUpload
              setFiles={setFiles}
              files={files}
              imageUploader={imageUploader}
              handleRemove={handleRemove}
              loading={loading}
            />
            <SerivceConditions />
            <ServiceRequirements />
            <ServiceAdvantages />
            <ServiceDetails />
          </Col>
          <Col lg={4}>
            <div className="sticky-sidebar">
              <ServiceType />
              <ServiceLocale />
            </div>
          </Col>
        </Row>
      </Form>
    </FormProvider>
  );
};

export default ServiceAdd;
