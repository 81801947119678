import React from 'react';
import { Card, Col, Form, Row } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';

const LoanVerified = () => {
  const {
    register,
    formState: { errors }
  } = useFormContext();

  return (
    <Card className="mb-3">
      <Card.Header as="h6" className="bg-light">
        Зээлдэгчийн баталгаа
      </Card.Header>
      <Card.Body>
        <Row className="gx-2 gy-3">
          <Col md="12">
            <Form.Group>
              <Form.Label>Орлогын төрөл</Form.Label>
              <Form.Control
                type="text"
                isInvalid={!!errors.incomeSource}
                {...register('incomeSource')}
              />
              <Form.Control.Feedback type="invalid">
                {errors.incomeSource?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col md="12">
            <Form.Group>
              <Form.Label>Зээлдэгчийн орлого: (₮)</Form.Label>
              <Form.Control
                type="text"
                isInvalid={!!errors.income}
                {...register('income')}
              />
              <Form.Control.Feedback type="invalid">
                {errors.income?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col md="12">
            <Form.Group>
              <Form.Label>Ажил эрхлэлт:</Form.Label>
              <Form.Control
                type="text"
                isInvalid={!!errors.company}
                {...register('company')}
              />
              <Form.Control.Feedback type="invalid">
                {errors.company?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>

          <Col md="12">
            <Form.Group>
              <Form.Label>Мэргэжил:</Form.Label>
              <Form.Control
                type="text"
                isInvalid={!!errors.employment}
                {...register('employment')}
              />
              <Form.Control.Feedback type="invalid">
                {errors.employment?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>

          <Col md="12">
            <Form.Group>
              <Form.Label>Туршлага:</Form.Label>
              <Form.Control
                type="text"
                isInvalid={!!errors.experience}
                {...register('experience')}
              />
              <Form.Control.Feedback type="invalid">
                {errors.experience?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col md="12">
            <Form.Group>
              <Form.Label>Гэр бүлийн орлого: (₮)</Form.Label>
              <Form.Control
                type="text"
                isInvalid={!!errors.familyIncome}
                {...register('familyIncome')}
              />
              <Form.Control.Feedback type="invalid">
                {errors.familyIncome?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default LoanVerified;
