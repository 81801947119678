import React, { useState, useContext, useEffect } from 'react';
import { Button, Modal, Card, Col, Form, Row } from 'react-bootstrap';
import FalconCardHeader from 'components/common/FalconCardHeader';
import Avatar from 'components/common/Avatar';
import { AuthWizardContext } from '../../../../context/Context';
import axios from 'axios';
import { Buffer } from 'buffer';
import loader from '../../../../assets/img/loader.gif';
import { toast } from 'react-toastify';
import { setAvatarUser, updateUser } from 'lib/apiService';

const avatarStyle = {
  border: '0.4rem solid transparent',
  padding: '0.4rem',
  borderRadius: '5rem',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  transition: '0.5s ease-in-out'
};

const imgStyle = {
  height: '6rem',
  transition: '0.5s ease-in-out'
};

const selectedStyle = {
  border: '0.4rem solid #4e0eff'
};

const avatarsStyle = {
  display: 'flex',
  justifyContent: 'center',
  gap: '2rem'
};

const ProfileSettings = () => {
  const api = `https://api.multiavatar.com/4645646`;
  const { user, setUser } = useContext(AuthWizardContext);
  const [formData, setFormData] = useState();
  const [modalShow, setModalShow] = useState(false);
  const [observer, setObserver] = useState(false);
  const [avatars, setAvatars] = useState([]);
  const [selectedAvatar, setSelectedAvatar] = useState(undefined);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    user && setFormData(user);
  }, [user]);

  const handleChange = e => {
    setObserver(true);
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = async e => {
    e.preventDefault();

    setObserver(false);
    const isData = {
      name: formData.name,
      phone: formData.phone
    };
    const { data } = await updateUser(user?._id, isData);
    if (data.success) {
      user.phone = data.data.phone;
      user.email = data.data.email;
      user.name = data.data.name;
      user.role = data.data.role;
      localStorage.setItem('uiUser', JSON.stringify(user));
      setUser(user);
      toast.success(`Амжилттай шинэчилэгдлээ`, {
        theme: 'colored'
      });
    } else {
      toast.error(`can't set avatar`, {
        theme: 'colored'
      });
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = [];
        for (let i = 0; i < 4; i++) {
          const image = await axios.get(
            `${api}/${Math.round(Math.random() * 1000)}`
          );
          const buffer = new Buffer(image.data);
          data.push(buffer.toString('base64'));
        }
        setAvatars(data);
        setIsLoading(false);
      } catch (error) {
        toast.error(`алдаа гарлаа`, {
          theme: 'colored'
        });
        setModalShow(false);
      }
    };
    if (modalShow) {
      fetchData();
    }
  }, [modalShow]);

  const setProfilePicture = async () => {
    if (selectedAvatar === undefined) {
      toast.error(`Аватар сонгогдоогүй байна`, {
        theme: 'colored'
      });
    } else {
      const { data } = await setAvatarUser(user?._id, {
        image: avatars[selectedAvatar]
      });

      if (data.isSet) {
        user.isAvatarImageSet = true;
        user.avatarImage = data.image;
        localStorage.setItem('uiUser', JSON.stringify(user));
        setUser(user);
        setModalShow(false);
        setAvatars([]);
        setIsLoading(true);
        setSelectedAvatar(undefined);
        // navigate('/customers/customer-settings');
        toast.success(`Амжилттай шинэчилэгдлээ`, {
          theme: 'colored'
        });
      } else {
        toast.error(`can't set avatar`, {
          theme: 'colored'
        });
      }
    }
  };

  return (
    <Card>
      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <h5>Аватар солих</h5>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div style={avatarsStyle}>
            {isLoading ? (
              <img src={loader} alt="loader" style={imgStyle} />
            ) : (
              avatars.map((avatar, index) => {
                return (
                  <div
                    key={index}
                    style={
                      selectedAvatar === index
                        ? { ...avatarStyle, ...selectedStyle }
                        : { ...avatarStyle }
                    }
                  >
                    <img
                      src={`data:image/svg+xml;base64,${avatar}`}
                      alt="avatar"
                      style={imgStyle}
                      onClick={() => setSelectedAvatar(index)}
                    />
                  </div>
                );
              })
            )}
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button onClick={setProfilePicture}>Сонгох</Button>
        </Modal.Footer>
      </Modal>

      <FalconCardHeader title="Хувийн мэдээлэл" />
      <Card.Body className="bg-light">
        <Form onSubmit={handleSubmit}>
          <Row className="mb-3 g-3 align-items-center">
            <Form.Group as={Col} lg={6} controlId="firstName">
              {formData && formData.isAvatarImageSet ? (
                <img
                  src={`data:image/svg+xml;base64,${formData.avatarImage}`}
                  alt="avatar image"
                  style={imgStyle}
                />
              ) : (
                <Avatar
                  src={formData?.avatarImage}
                  rounded="circle"
                  size="4xl"
                />
              )}
            </Form.Group>

            <Form.Group as={Col} lg={6} controlId="firstName">
              <Button onClick={() => setModalShow(true)}>Өөрчлөх</Button>
            </Form.Group>
          </Row>
          <Row className="mb-3 g-3">
            <Form.Group as={Col} lg={6} controlId="firstName">
              <Form.Label>Нэр</Form.Label>
              <Form.Control
                type="text"
                placeholder="нэр"
                value={formData?.name || ''}
                name="name"
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group as={Col} lg={6} controlId="lastName">
              <Form.Label>Утас</Form.Label>
              <Form.Control
                type="text"
                placeholder="Утас"
                value={formData?.phone || ''}
                name="phone"
                onChange={handleChange}
              />
            </Form.Group>
          </Row>
          <Row className="mb-3 g-3">
            <Form.Group as={Col} lg={6} controlId="email">
              <Form.Label>Имэйл</Form.Label>
              <Form.Control
                disabled
                type="email"
                placeholder="Имэйл"
                value={formData?.email || ''}
                name="email"
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group as={Col} lg={6} controlId="phone">
              <Form.Label>Системийн эрх</Form.Label>
              <Form.Control
                disabled
                type="text"
                placeholder="Утас"
                value={
                  formData?.role && formData?.role === 'admin'
                    ? 'Админ'
                    : 'Нийтлэгч' || ''
                }
                name="role"
                onChange={handleChange}
              />
            </Form.Group>
          </Row>

          <div className="text-end">
            <Button variant="primary" type="submit" disabled={!observer}>
              Шинэчлэх
            </Button>
          </div>
        </Form>
      </Card.Body>
    </Card>
  );
};

export default ProfileSettings;
