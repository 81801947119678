import React, { useState, useEffect } from 'react';
import { Card, Col } from 'react-bootstrap';
import LogTableHeader from './LogTableHeader';
import LogHeader from './LogHeader';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
import { getLogs } from 'lib/apiService';

const LogList = () => {
  const [getMessageDatas, setGetMessageDatas] = useState([]);
  const [getCounter, setGetCounter] = useState([]);

  const fetchData = async (queryString = {}) => {
    try {
      const response = await getLogs(queryString);
      setGetMessageDatas(response?.data?.data);
      setGetCounter(response?.data?.counter);
    } catch (error) {
      console.error('Error fetching user data:', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const columns = [
    {
      accessor: 'action',
      Header: 'Үйлдэл',
      headerProps: { className: 'pe-1' },
      Cell: rowData => {
        const { action } = rowData.row.original;
        return <>{action}</>;
      }
    },
    {
      accessor: 'path',
      Header: 'Зам',
      headerProps: { className: 'pe-1' },
      Cell: rowData => {
        const { path } = rowData.row.original;
        return <>{path}</>;
      }
    },
    {
      accessor: 'timestamp',
      Header: 'Огноо',
      headerProps: { className: 'pe-1' },
      Cell: rowData => {
        const { timestamp } = rowData.row.original;
        return <>{timestamp}</>;
      }
    }
  ];

  return (
    <>
      <Col xs={12} className="mb-3">
        <LogHeader getMessageDatas={getMessageDatas} getCounter={getCounter} />
      </Col>
      <AdvanceTableWrapper
        columns={columns}
        data={getMessageDatas}
        selection={false}
        sortable
        pagination
        perPage={50}
      >
        <Card className="mb-3">
          <Card.Header>
            <LogTableHeader
              table
              getBlogDatas={getMessageDatas}
              refetch={fetchData}
            />
          </Card.Header>
          <Card.Body className="p-0">
            <AdvanceTable
              table
              headerClassName="bg-200 text-900 text-nowrap align-middle"
              rowClassName="align-middle white-space-nowrap"
              tableProps={{
                size: 'sm',
                striped: true,
                className: 'fs--1 mb-0 overflow-hidden'
              }}
            />
          </Card.Body>
          <Card.Footer>
            <AdvanceTablePagination table />
          </Card.Footer>
        </Card>
      </AdvanceTableWrapper>
    </>
  );
};

export default LogList;
