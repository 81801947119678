import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Flex from 'components/common/Flex';
import SoftBadge from 'components/common/SoftBadge';
import { AuthWizardContext } from 'context/Context';

const NavbarVerticalMenuItem = ({ route }) => {
  const { hasNewNotification, loanNewNotification } =
    useContext(AuthWizardContext);

  return (
    <Flex alignItems="center">
      {route.icon && (
        <span className="nav-link-icon">
          <FontAwesomeIcon icon={route.icon} />
        </span>
      )}
      <span className="nav-link-text ps-1">{route.name}</span>
      {route.badge && route.badge.for === 'contact' && (
        <SoftBadge pill bg={route.badge.type} className="ms-2">
          {hasNewNotification > 0 && hasNewNotification}
        </SoftBadge>
      )}
      {route.badge && route.badge.for === 'loan' && (
        <SoftBadge pill bg={route.badge.type} className="ms-2">
          {loanNewNotification > 0 && loanNewNotification}
        </SoftBadge>
      )}
    </Flex>
  );
};

// prop-types
const routeShape = {
  active: PropTypes.bool,
  name: PropTypes.string.isRequired,
  to: PropTypes.string,
  icon: PropTypes.oneOfType([PropTypes.array, PropTypes.string])
};
routeShape.children = PropTypes.arrayOf(PropTypes.shape(routeShape));
NavbarVerticalMenuItem.propTypes = {
  route: PropTypes.shape(routeShape).isRequired
};

export default React.memo(NavbarVerticalMenuItem);
