import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Form } from 'react-bootstrap';
import { forgetPassword } from 'lib/apiService';

const ForgetPasswordForm = () => {
  const navigage = useNavigate();
  // State
  const [email, setEmail] = useState('');

  // Handler
  const handleSubmit = e => {
    e.preventDefault();
    forgetPassword(email)
      .then(result => {
        if (result.data.success) {
          toast.success(
            `Амжиллтай илгээлээ та өөрийн бүртгэлтэй ${email} имэйл хаягаа шалгана уу`,
            {
              theme: 'colored'
            }
          );
          setTimeout(() => {
            navigage('/authentication/card/login');
          }, 3000);
        }
      })
      .catch(error => {
        toast.warning(`${error.response.data.error.message}`, {
          theme: 'colored'
        });
      });
  };

  return (
    <Form className="mt-4" onSubmit={handleSubmit}>
      <Form.Group className="mb-3">
        <Form.Control
          placeholder={'Имэйл хаяг'}
          value={email}
          name="email"
          onChange={({ target }) => setEmail(target.value)}
          type="email"
        />
      </Form.Group>

      <Form.Group className="mb-3">
        <Button className="w-100" type="submit" disabled={!email}>
          Илгээх
        </Button>
      </Form.Group>

      <Link className="fs--1 text-600" to="/authentication/card/login">
        Хэрэв нууц үгээ санасан бол
        <span className="d-inline-block ms-1"> &rarr;</span>
      </Link>
    </Form>
  );
};

ForgetPasswordForm.propTypes = {
  layout: PropTypes.string
};

ForgetPasswordForm.defaultProps = { layout: 'simple' };

export default ForgetPasswordForm;
