import React from 'react';
import { Card, Col, Form, Row } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import TinymceEditor from 'components/common/TinymceEditor';

const ServiceDetails = () => {
  const {
    setValue,
    formState: { errors },
    watch
  } = useFormContext();
  const description = watch('description');

  return (
    <Card className="mb-3">
      <Card.Header as="h6" className="bg-light">
        Контент
      </Card.Header>
      <Card.Body>
        <Row className="gx-2 gy-3">
          <Col xs="12">
            <Form.Group>
              <Form.Label>Дэлгэрэнгүй:</Form.Label>
              <div className="create-product-description-textarea">
                <TinymceEditor
                  height="13.438rem"
                  isInvalid={!!errors.description}
                  handleChange={newValue => setValue('description', newValue)}
                  value={description}
                />
              </div>
              <Form.Control.Feedback type="invalid">
                {errors.description?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default ServiceDetails;
