import React from 'react';
import { Card, Col, Form, Row } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';

const LoanInfo = () => {
  const {
    register,
    formState: { errors }
  } = useFormContext();

  return (
    <Card className="mb-3">
      <Card.Header as="h6" className="bg-light">
        Зээлийн мэдээлэл
      </Card.Header>
      <Card.Body>
        <Row className="gx-2 gy-3">
          <Col md="12">
            <Form.Group>
              <Form.Label>Мөнгөн дүн: (₮)</Form.Label>
              <Form.Control
                type="text"
                isInvalid={!!errors.money}
                {...register('money')}
              />
              <Form.Control.Feedback type="invalid">
                {errors.money?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col md="12">
            <Form.Group>
              <Form.Label>Зээлийн хугацаа:</Form.Label>
              <Form.Control
                type="text"
                isInvalid={!!errors.period}
                {...register('period')}
              />
              <Form.Control.Feedback type="invalid">
                {errors.period?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>

          <Col md="12">
            <Form.Group>
              <Form.Label>Зээлийн хүү:</Form.Label>
              <Form.Control
                type="text"
                isInvalid={!!errors.loanRate}
                {...register('loanRate')}
              />
              <Form.Control.Feedback type="invalid">
                {errors.loanRate?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>

          <Col md="12">
            <Form.Group>
              <Form.Label>Зээлийн зориулалт</Form.Label>
              <Form.Control
                type="text"
                isInvalid={!!errors.dedication}
                {...register('dedication')}
              />
              <Form.Control.Feedback type="invalid">
                {errors.dedication?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>

          <Col md="12">
            <Form.Group>
              <Form.Label>Зээлийн оноо</Form.Label>
              <Form.Control
                type="text"
                isInvalid={!!errors.creditScore}
                {...register('creditScore')}
              />
              <Form.Control.Feedback type="invalid">
                {errors.creditScore?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default LoanInfo;
