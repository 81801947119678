import React, { useEffect, useState } from 'react';
import { Card, Col, Form, Row, Button } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import IconButton from 'components/common/IconButton';
import PropTypes from 'prop-types';

// variants
const ServiceConditions = () => {
  const {
    register,
    watch,
    formState: { errors }
  } = useFormContext();
  // nemsen
  const conditions = watch('conditions');
  const [inputs, setInputs] = useState([{ key: '', value: '' }]);

  const inputValues = {
    key: inputs[0].key,
    value: inputs[0].value
  };

  useEffect(() => {
    if (conditions) {
      const buffer = conditions.map(() => ({
        key: '',
        value: ''
      }));
      setInputs(buffer);
    }
  }, [conditions]);

  const addInput = () => {
    const newInput = { ...inputValues }; // Clone the inputValues
    setInputs([...inputs, newInput]);
  };

  const removeInput = index => {
    const newInputs = [...inputs];
    newInputs.splice(index, 1);
    setInputs(newInputs);
  };

  const handleInputChange = (index, field, value) => {
    const newInputs = [...inputs];
    if (value === '') {
      newInputs[index][field] = 0;
    } else {
      newInputs[index][field] = parseFloat(value);
    }
    setInputs(newInputs);
  };

  return (
    <>
      <Card className="mb-3">
        <Card.Header as="h6" className="bg-light">
          Нөхцөл
        </Card.Header>
        <Card.Body>
          <Row className="gx-2 gy-3">
            <Col xs="12">
              <Form.Group>
                <Form.Label>Утга</Form.Label>
                {inputs &&
                  inputs.map((input, index) => (
                    <div className="d-flex mb-3" key={index}>
                      <div className="me-2">
                        <Form.Control
                          type="text"
                          isInvalid={!!errors.conditions?.[index]?.key}
                          {...register(`conditions[${index}].key`)}
                          style={{ marginRight: '10px' }}
                          placeholder="Түлхүүр"
                          onChange={e =>
                            handleInputChange(index, 'key', e.target.value)
                          }
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.conditions?.[index]?.key?.message}
                        </Form.Control.Feedback>
                      </div>
                      <div className="me-2">
                        <Form.Control
                          type="text"
                          isInvalid={!!errors.conditions?.[index]?.value}
                          {...register(`conditions[${index}].value`)}
                          style={{ marginRight: '10px' }}
                          placeholder="Утга"
                          onChange={e =>
                            handleInputChange(index, 'value', e.target.value)
                          }
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.conditions?.[index]?.value?.message}
                        </Form.Control.Feedback>
                      </div>

                      {index > 0 && (
                        <Button
                          variant="warning"
                          onClick={() => removeInput(index)}
                        >
                          <FontAwesomeIcon icon={faTrashAlt} />
                        </Button>
                      )}
                    </div>
                  ))}
                <IconButton
                  variant="primary"
                  className="me-2 mb-1"
                  icon="plus"
                  transform="shrink-3"
                  onClick={addInput}
                >
                  Нөхцөл нэмэх
                </IconButton>
              </Form.Group>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </>
  );
};
ServiceConditions.propTypes = {
  updateProducts: PropTypes.array
};

export default ServiceConditions;
