import React, { useEffect, useState } from 'react';
import { Card, Col, Form, Row, Button } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import IconButton from 'components/common/IconButton';
import PropTypes from 'prop-types';

// variants
const ServiceAdvantages = () => {
  const {
    register,
    watch,
    formState: { errors }
  } = useFormContext();
  const advantages = watch('advantages');
  const [inputs, setInputs] = useState(['']);

  const inputValues = '';

  useEffect(() => {
    if (advantages) {
      const buffer = advantages.map(() => '');
      setInputs(buffer);
    }
  }, [advantages]);

  const addInput = () => {
    const newInput = { ...inputValues }; // Clone the inputValues
    setInputs([...inputs, newInput]);
  };

  const removeInput = index => {
    const newInputs = [...inputs];
    newInputs.splice(index, 1);
    setInputs(newInputs);
  };

  const handleInputChange = (index, value) => {
    const newInputs = [...inputs];
    if (value === '') {
      newInputs[index] = 0;
    } else {
      newInputs[index] = parseFloat(value);
    }
    setInputs(newInputs);
  };

  return (
    <>
      <Card className="mb-3">
        <Card.Header as="h6" className="bg-light">
          Давуу тал
        </Card.Header>
        <Card.Body>
          <Row className="gx-2 gy-3">
            <Col xs="12">
              <Form.Group>
                <Form.Label>Утга</Form.Label>
                {inputs &&
                  inputs.map((input, index) => (
                    <div className="d-flex mb-3" key={index}>
                      <div className="me-2 w-75">
                        <Form.Control
                          type="text"
                          isInvalid={!!errors.advantages?.[index]}
                          {...register(`advantages[${index}]`)}
                          style={{ marginRight: '10px' }}
                          placeholder="Оруулах утгаа бичнэ үү"
                          onChange={e =>
                            handleInputChange(index, e.target.value)
                          }
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.advantages?.[index]?.message}
                        </Form.Control.Feedback>
                      </div>

                      {index > 0 && (
                        <Button
                          variant="warning"
                          onClick={() => removeInput(index)}
                        >
                          <FontAwesomeIcon icon={faTrashAlt} />
                        </Button>
                      )}
                    </div>
                  ))}
                <IconButton
                  variant="primary"
                  className="me-2 mb-1"
                  icon="plus"
                  transform="shrink-3"
                  onClick={addInput}
                >
                  Давуу тал нэмэх
                </IconButton>
              </Form.Group>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </>
  );
};
ServiceAdvantages.propTypes = {
  updateProducts: PropTypes.array
};

export default ServiceAdvantages;
