import React from 'react';
import { Card, Col, Button, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

const LoanHeader = () => {
  const navigate = useNavigate();
  const handleBack = () => {
    navigate(-1);
  };
  return (
    <Card>
      <Card.Body>
        <Row className="flex-between-center">
          <Col md>
            <h5 className="mb-2 mb-md-0">Зээлийн хүсэлтүүд</h5>
          </Col>
          <Col xs="auto">
            <Button
              variant="link"
              className="text-secondary fw-medium p-0 me-3"
              type="button"
              onClick={handleBack}
            >
              Буцах
            </Button>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default LoanHeader;
