import FalconCardHeader from 'components/common/FalconCardHeader';
import React, { useState, useContext } from 'react';
import { Button, Card, Form, Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { changePassword } from 'lib/apiService';
import { AuthWizardContext } from '../../../../context/Context';
import Cookies from 'js-cookie';
import PropTypes from 'prop-types';

const ConfirmModal = ({
  showModal,
  handleCloseModal,
  handleConfirmChangePassword
}) => {
  return (
    <Modal show={showModal} onHide={handleCloseModal}>
      <Modal.Header closeButton>
        <Modal.Title>
          <h5>Нууц шинэчилэх</h5>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p className="">Та нууц үгээ солихдоо итгэлтэй байна уу!</p>
      </Modal.Body>
      <Modal.Footer className="border-0">
        <Button variant="secondary" onClick={handleCloseModal}>
          Үгүй
        </Button>
        <Button variant="primary" onClick={handleConfirmChangePassword}>
          Тийм
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

ConfirmModal.propTypes = {
  showModal: PropTypes.bool,
  handleCloseModal: PropTypes.func,
  handleConfirmChangePassword: PropTypes.func
};

const ChangePassword = () => {
  const [showModal, setShowModal] = useState(false);
  const { user, setUser } = useContext(AuthWizardContext);
  const [formData, setFormData] = useState({
    oldPassword: '',
    newPassword: '',
    confirmPassword: ''
  });

  const handleChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleShowModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setFormData({
      oldPassword: '',
      newPassword: '',
      confirmPassword: ''
    });
  };

  const handleSubmit = async e => {
    try {
      e.preventDefault();
      if (formData.newPassword === formData.confirmPassword) {
        handleShowModal();
      } else {
        toast.warning(`Батлах нууц үг болон шинэ нууц үг таарахгүй байна`, {
          theme: 'colored'
        });
      }
    } catch (err) {
      console.log('🚀 ~ file: ChangePassword.js:60 ~ handleSubmit ~ err:', err);
      toast.warning(err.response.data.error.message, {
        theme: 'colored'
      });
    }
  };

  const handleConfirmChangePassword = async () => {
    try {
      // Perform the actual password change API call here
      const { data } = await changePassword({
        newPassword: formData?.newPassword,
        password: formData?.oldPassword,
        userId: user?._id
      });

      if (data.success) {
        // Update user data and close modal
        Cookies.set('uiAccessToken', data?.token, {
          expires: 7
        });
        localStorage.setItem('uiUser', JSON.stringify(data?.user));
        setUser(data?.user);
        setFormData({
          oldPassword: '',
          newPassword: '',
          confirmPassword: ''
        });
        handleCloseModal();

        toast.success('Амжилттай шинэчиллээ', {
          theme: 'colored'
        });
      } else {
        toast.warning(data.error.message, {
          theme: 'colored'
        });
      }
    } catch (err) {
      console.log('Error:', err);
      toast.warning(err.response.data.error.message, {
        theme: 'colored'
      });
      handleCloseModal();
    }
  };

  return (
    <Card className="mb-3" style={{ zIndex: '0' }}>
      <FalconCardHeader title="Нууц үг шинэчлэх" />
      <Card.Body className="bg-light">
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3" controlId="oldPassword">
            <Form.Label>Хуучин нууц үг</Form.Label>
            <Form.Control
              type="password"
              value={formData.oldPassword}
              name="oldPassword"
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="newPassword">
            <Form.Label>Шинэ нууц үг</Form.Label>
            <Form.Control
              type="password"
              value={formData.newPassword}
              name="newPassword"
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="confirmPassword">
            <Form.Label>Батлах нууц үг</Form.Label>
            <Form.Control
              type="password"
              value={formData.confirmPassword}
              name="confirmPassword"
              onChange={handleChange}
            />
          </Form.Group>
          <Button
            className="w-100"
            type="submit"
            disabled={
              !formData.oldPassword ||
              !formData.newPassword ||
              !formData.confirmPassword
            }
          >
            Нууц үг шинэчлэх
          </Button>
        </Form>
      </Card.Body>
      <ConfirmModal
        handleCloseModal={handleCloseModal}
        showModal={showModal}
        handleConfirmChangePassword={handleConfirmChangePassword}
      />
    </Card>
  );
};

export default ChangePassword;
