import React from 'react';
import { Card, Col, Form, Row } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';

const ServiceType = () => {
  const categories = [
    'Зээл',
    'Итгэлцэл',
    'Мөнгөн гуйвуулага',
    'Вальют арилжаа'
  ];
  const {
    register,
    formState: { errors }
  } = useFormContext();

  return (
    <Card className="mb-3">
      <Card.Header as="h6" className="bg-light">
        Үйлчилгээний төрөл
      </Card.Header>
      <Card.Body>
        <Row className="gx-2 gy-3">
          <Col md="12">
            <Form.Group>
              <Form.Label>Төрөл сонгох</Form.Label>
              <Form.Select
                {...register(`serviceType`)}
                isInvalid={!!errors.serviceType}
              >
                <option value="">сонголт</option>
                {categories.map((category, i) => (
                  <option key={i} value={category}>
                    {category}
                  </option>
                ))}
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                {errors.serviceType?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default ServiceType;
