import IconButton from 'components/common/IconButton';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import React, { useState } from 'react';
import { Col, Form, Row, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import * as XLSX from 'xlsx';
import {
  toggleActivationServices,
  deleteServices
} from '../../../lib/apiService';

function convertArrayToExcel(array) {
  const worksheet = XLSX.utils.json_to_sheet(array);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet 1');
  const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

  // Create a Blob object with the Excel data
  const blob = new Blob([excelBuffer], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
  });

  // Create a temporary anchor element
  const anchor = document.createElement('a');
  anchor.href = URL.createObjectURL(blob);
  anchor.download = 'data.xlsx'; // Change the filename as desired
  anchor.style.display = 'none';

  // Append the anchor to the document body and click it programmatically
  document.body.appendChild(anchor);
  anchor.click();

  // Clean up
  document.body.removeChild(anchor);
  URL.revokeObjectURL(anchor.href);
}

// function workbookToExcelBlob(workbook) {
//   const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
//   return new Blob([excelBuffer], {
//     type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
//   });
// }

function removeHtmlTags(text) {
  const tempElement = document.createElement('div');
  tempElement.innerHTML = text;
  return tempElement.textContent || tempElement.innerText || '';
}

// function convertArrayToCSVDownload(array) {
//   const separator = ','; // Change this if you want a different separator
//   const csvRows = [];

//   // Create headers row
//   const headers = Object.keys(array[0]);
//   csvRows.push(headers.join(separator));

//   // Create data rows
//   for (const row of array) {
//     const values = headers.map(header => row[header]);
//     csvRows.push(values.join(separator));
//   }

//   // Join all rows into a single CSV string
//   const csvString = csvRows.join('\n');

//   // Create a Blob object with the CSV data
//   const blob = new Blob([csvString], { type: 'text/csv' });

//   // Create a temporary anchor element
//   const anchor = document.createElement('a');
//   anchor.href = URL.createObjectURL(blob);
//   anchor.download = 'newsnuud.csv'; // Change the filename as desired
//   anchor.style.display = 'none';

//   // Append the anchor to the document body and click it programmatically
//   document.body.appendChild(anchor);
//   anchor.click();

//   // Clean up
//   document.body.removeChild(anchor);
//   URL.revokeObjectURL(anchor.href);
// }

const ServiceTableHeader = ({ selectedRowIds, getSerivceDatas, refetch }) => {
  const [actionType, setActionType] = useState('editable');
  const keyArray = Object.keys(selectedRowIds).map(Number);

  const newUpdateIds =
    keyArray.length > 0 &&
    keyArray.map(item => {
      return getSerivceDatas[item]?._id;
    });

  let navigage = useNavigate();

  const handleSubmit = e => {
    e.preventDefault();
    if (newUpdateIds.length > 0 && actionType !== 'editable') {
      if (actionType !== 'delete') {
        toggleActivationServices(
          newUpdateIds,
          actionType === 'active' ? true : false
        )
          .then(result => {
            if (result.data.success) {
              toast.success(`Амжиллтай шинэчилэгдлээ`, {
                theme: 'colored'
              });
              refetch();
            }
          })
          .catch(error => {
            toast.warning(`${error.response.data.error.message}`, {
              theme: 'colored'
            });
          });
      } else {
        deleteServices(newUpdateIds)
          .then(result => {
            if (result.data.success) {
              toast.success(`Амжиллтай устгагдлаа`, {
                theme: 'colored'
              });
              refetch();
            }
          })
          .catch(error => {
            toast.warning(`${error.response.data.error.message}`, {
              theme: 'colored'
            });
          });
      }
    }
  };

  const handleFieldFilterChange = e => {
    const value = e.target.value;
    let queryString = '';
    switch (value) {
      case 'new':
        queryString = `?sort=-1`;
        break;
      case 'old':
        queryString = `?sort=1`;
        break;
      case 'true':
        queryString = `?status=true`;
        break;
      case 'false':
        queryString = `?status=false`;
        break;
      case 'mn':
        queryString = `?locale=mn`;
        break;
      case 'en':
        queryString = `?locale=en`;
        break;
      default:
        queryString;
    }
    refetch(queryString);
  };

  const handleFieldChange = e => {
    setActionType(e.target.value);
  };
  const handleChangeFileFormat = () => {
    const newRawData =
      getSerivceDatas.length > 0 &&
      getSerivceDatas.map(item => {
        return {
          title: item.title,
          desciption: removeHtmlTags(item.description),
          locale: item.locale,
          serviceType: item.serviceType
        };
      });
    convertArrayToExcel(newRawData);
    // convertArrayToCSVDownload(newRawData);
  };

  const handleGoTo = () => {
    navigage('/service/new-service');
  };

  return (
    <Row className="flex-between-center">
      <Col xs={4} sm="auto" className="d-flex align-items-center pe-0">
        <h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">Үйлчилгээ</h5>
      </Col>
      <Col xs={8} sm="auto" className="ms-auto text-end ps-0">
        {Object.keys(selectedRowIds).length > 0 ? (
          <div className="d-flex">
            <Form.Select
              size="sm"
              aria-label="Bulk actions"
              onChange={handleFieldChange}
            >
              <option value="editable">Засварлах</option>
              <option value="active">Нээх</option>
              <option value="closed">Хаах</option>
              <option value="delete">Устгах</option>
              {/* <option value="archive">Archive</option> */}
            </Form.Select>
            <Button
              type="button"
              variant="falcon-default"
              size="sm"
              className="ms-2"
              onClick={handleSubmit}
            >
              Хадгалах
            </Button>
          </div>
        ) : (
          <div className="d-flex">
            <IconButton
              variant="falcon-default"
              size="sm"
              icon="plus"
              transform="shrink-3"
              onClick={handleGoTo}
            >
              <span className="d-none d-sm-inline-block ms-1">
                Шинэ Үйлчилгээ
              </span>
            </IconButton>
            <div className="mx-1">
              <Form.Select
                size="sm"
                aria-label="Bulk actions"
                onChange={handleFieldFilterChange}
              >
                <option value="filter">Шүүлтүүр</option>
                <option value="new">Шинэ эхэндээ</option>
                <option value="old">Хуучин эхэндээ</option>
                <option value="true">Нээлттэй</option>
                <option value="false">Хаалттай</option>
                <option value="mn">Монгол</option>
                <option value="en">Англи</option>
              </Form.Select>
            </div>
            <div id="orders-actions">
              <IconButton
                variant="falcon-default"
                size="sm"
                icon="external-link-alt"
                transform="shrink-3"
                onClick={handleChangeFileFormat}
              >
                <span className="d-none d-sm-inline-block ms-1">Экспорт</span>
              </IconButton>
            </div>
          </div>
        )}
      </Col>
    </Row>
  );
};

ServiceTableHeader.propTypes = {
  selectedRowIds: PropTypes.object,
  getBlogDatas: PropTypes.array,
  refetch: PropTypes.func,
  getSerivceDatas: PropTypes.any
};

export default ServiceTableHeader;
