// import FalconCardFooterLink from 'components/common/FalconCardFooterLink';
import FalconCardHeader from 'components/common/FalconCardHeader';
import CampaignChart from './CampaignChart';
import PropTypes from 'prop-types';
import PerfomanceTable from './PerfomanceTable';
import React from 'react';
import { getColor } from 'helpers/utils';
import { Card, Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';

function allCountActionCollectByDate(obj, property) {
  const resultArray = Object.entries(obj).map(([date, actions]) => ({
    date,
    ...actions
  }));
  const getPropertyValues = resultArray.map(item => item[property]);
  return getPropertyValues;
}

function runningProjectState(data) {
  let actionCounts = {};

  const currentDate = new Date();
  const fiveDaysAgo = new Date();
  fiveDaysAgo.setDate(currentDate.getDate() - 4); // Subtract 4 to account for the current day

  data &&
    data.forEach(item => {
      const createdAt = new Date(item.timestamp).toDateString();

      if (
        new Date(item.timestamp) >= fiveDaysAgo &&
        new Date(item.timestamp) <= currentDate
      ) {
        if (!Object.prototype.hasOwnProperty.call(actionCounts, createdAt)) {
          actionCounts[createdAt] = { created: 0, updated: 0, deleted: 0 };
        }

        if (item.action === 'Үйлдэл: POST') {
          actionCounts[createdAt].created++;
        }
        if (item.action === 'Үйлдэл: PUT') {
          actionCounts[createdAt].updated++;
        }
        if (item.action === 'Үйлдэл: DELETE') {
          actionCounts[createdAt].deleted++;
        }
      }
    });
  return actionCounts;
}

const RunningProjects = ({ userLogs, analytics }) => {
  return (
    <Card>
      <Card className="h-100">
        <FalconCardHeader
          title="Хэрэглэгчийн үйлдэл"
          light
          titleTag="h6"
          endEl={
            <Link to="/log/log-list">
              <span className="h6">Цааш үзэх</span>
            </Link>
          }
        />
        <Card.Body className="pb-0">
          <Row>
            <Col xs={4}>
              <h6 className="text-700">Үүсгэсэн</h6>
              <h3 className="fw-normal text-700">{analytics.POST}</h3>
              <CampaignChart
                color={getColor('success')}
                data={allCountActionCollectByDate(
                  runningProjectState(userLogs),
                  'created'
                )}
              />
            </Col>
            <Col xs={4}>
              <h6 className="text-700">Засварласан</h6>
              <h3 className="fw-normal text-700"> {analytics.PUT}</h3>
              <CampaignChart
                color={getColor('primary')}
                data={allCountActionCollectByDate(
                  runningProjectState(userLogs),
                  'updated'
                )}
              />
            </Col>
            <Col xs={4}>
              <h6 className="text-700">Устгасан</h6>
              <h3 className="fw-normal text-700">{analytics.DELETE}</h3>
              <CampaignChart
                color={getColor('danger')}
                data={allCountActionCollectByDate(
                  runningProjectState(userLogs),
                  'deleted'
                )}
              />
            </Col>
          </Row>
          <div className="mx-ncard">
            <PerfomanceTable data={userLogs} />
          </div>
        </Card.Body>
      </Card>
      {/* 
      <FalconCardFooterLink title="Цааш үзэх" size="sm" /> */}
    </Card>
  );
};

RunningProjects.propTypes = {
  userLogs: PropTypes.array,
  analytics: PropTypes.any
};

export default RunningProjects;
