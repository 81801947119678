export const dashboardRoutes = {
  label: 'Dashboard',
  labelDisable: true,
  children: [
    {
      name: 'Эхлэл',
      active: true,
      to: '/',
      icon: 'chart-pie',
      roles: ['admin', 'worker']
    }
  ]
};
export const appRoutes = {
  label: 'Үндсэн цэс',
  children: [
    {
      name: 'Зээлийн хүсэлт',
      icon: 'file-alt',
      to: '/loan/loan-list',
      active: true,
      roles: ['admin', 'worker'],
      badge: {
        type: 'success',
        text: 'Шинэ',
        for: 'loan'
      }
    },
    {
      name: 'Тайлан',
      icon: 'file-alt',
      to: '/report/report-list',
      active: true,
      roles: ['admin', 'worker']
    },

    {
      name: 'Үйлчилгээ',
      icon: 'file-alt',
      to: '/service/service-list',
      active: true,
      roles: ['admin', 'worker']
    },
    {
      name: 'Зурвас',
      icon: 'envelope-open',
      to: '/message/message-list',
      active: false,
      roles: ['admin', 'worker'],
      badge: {
        type: 'success',
        text: 'Шинэ',
        for: 'contact'
      }
    },
    {
      name: 'Ажилтан',
      icon: 'user',
      to: '/customers/customer-list',
      active: false,
      roles: ['admin']
    },
    {
      name: 'Тохиргоо',
      icon: 'wrench',
      to: '/settings/general',
      active: false,
      roles: ['admin']
    }
  ]
};

export default [dashboardRoutes, appRoutes];
