import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { AuthWizardContext } from 'context/Context';
import Cookies from 'js-cookie';
import {
  getCompany,
  getContactNotification,
  getLoanNotification
} from 'lib/apiService';

const AuthWizardProvider = ({ children }) => {
  const [user, setUser] = useState({});
  const [company, setCompany] = useState(null);
  const [step, setStep] = useState(1);
  const [refetchContact, setRetchContact] = useState(false);
  const [hasNewNotification, setHasNewNotification] = useState(false);
  const [refetchLoan, setRefetchLoan] = useState(false);
  const [loanNewNotification, setLoanNewNotification] = useState(false);
  const value = {
    user,
    setUser,
    step,
    setStep,
    company,
    setCompany,
    refetchContact,
    setRetchContact,
    hasNewNotification,
    setHasNewNotification,
    refetchLoan,
    setRefetchLoan,
    loanNewNotification,
    setLoanNewNotification
  };

  const fetchUser = async () => {
    const result = await JSON.parse(localStorage.getItem('uiUser'));
    setUser(result);
  };

  const fetchDataCompany = async companyHandle => {
    try {
      const response = await getCompany(companyHandle);

      if (response.data && response.data.data) {
        // Check if the response has valid data
        const companyData = response.data.data;
        setCompany(companyData);
        localStorage.setItem('company', JSON.stringify(companyData));
      } else {
        console.error('Invalid company data received:', response.data);
      }
    } catch (error) {
      console.error('Error fetching company data:', error);
    }
  };

  const fetchCompany = async () => {
    const result = await JSON.parse(localStorage.getItem('company'));

    if (!result) {
      fetchDataCompany('uizen-iveelt');
    } else {
      setCompany(result);
    }
  };

  useEffect(() => {
    fetchCompany();
  }, []);

  useEffect(() => {
    const fetchNotification = async () => {
      try {
        const result = await getContactNotification();
        setHasNewNotification(result.data.isHasNew);
        setRetchContact(false);
      } catch (error) {
        console.log(error);
      }
    };
    fetchNotification();
  }, [refetchContact, setRetchContact]);

  useEffect(() => {
    const fetchLoanNotification = async () => {
      try {
        const result = await getLoanNotification();
        setLoanNewNotification(result.data.isHasNew);
        setRefetchLoan(false);
      } catch (error) {
        console.log(error);
      }
    };
    fetchLoanNotification();
  }, [refetchLoan, setRefetchLoan]);

  useEffect(() => {
    const accessToken = Cookies.get('uiAccessToken');
    if (accessToken) {
      fetchUser();
    }
  }, []);

  return (
    <AuthWizardContext.Provider value={value}>
      {children}
    </AuthWizardContext.Provider>
  );
};

AuthWizardProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default AuthWizardProvider;
