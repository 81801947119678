import React from 'react';
import {
  Card,
  Col,
  Button,
  Row,
  OverlayTrigger,
  Tooltip
} from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import Flex from 'components/common/Flex';
import cloudDownload from 'assets/img/icons/cloud-download.svg';
// import editAlt from 'assets/img/icons/edit-alt.svg';
import classNames from 'classnames';
import iconDocs from 'assets/img/icons/docs.png';
import { Link } from 'react-router-dom';

const LoanFiles = () => {
  const mainUrl = process.env.REACT_APP_SERVER_URL + '/';
  const { watch } = useFormContext();
  const {
    loanInfoForm,
    bankStatementForm,
    idCardForm,
    marriedForm,
    socialInsuranceForm,
    DefinitionAmount,
    CustomsDocuments,
    NDSHReference,
    PurchaseSale,
    StudyBook,
    LearningDefinition,
    carCard
  } = watch();

  return (
    <Card className="mb-3">
      <Card.Header as="h6" className="bg-light">
        Зээлдэгчийн материал
      </Card.Header>
      <Card.Body>
        <Row className="gx-2 gy-3">
          <Col md="12">
            {bankStatementForm && (
              <Flex alignItems="center" className="mb-3 hover-actions-trigger">
                <div className="file-thumbnail">
                  <img
                    className={classNames('h-100 w-100 fit-cover rounded-2', {
                      border: false
                    })}
                    src={iconDocs}
                    alt=""
                  />
                </div>
                <div className="ms-3 flex-shrink-1 flex-grow-1">
                  <h6 className="mb-1">
                    <Link
                      className="stretched-link text-900 fw-semi-bold"
                      onClick={() =>
                        window.open(`${mainUrl}${bankStatementForm}`, '_blank')
                      }
                    >
                      {bankStatementForm && bankStatementForm.split('/')[2]}
                    </Link>
                  </h6>
                  <div className="fs--1">
                    <span className="fw-semi-bold">Банкны лавлагаа</span>
                  </div>
                  <div className="hover-actions end-0 top-50 translate-middle-y">
                    <OverlayTrigger
                      overlay={
                        <Tooltip style={{ position: 'fixed' }} id="nextTooltip">
                          Татах
                        </Tooltip>
                      }
                    >
                      <Button
                        variant="light"
                        size="sm"
                        className="border-300 me-1 text-600"
                        as={'a'}
                        href={`${mainUrl}${bankStatementForm}`}
                        download
                      >
                        <img src={cloudDownload} alt="Татах" width={15} />
                      </Button>
                    </OverlayTrigger>
                  </div>
                </div>
              </Flex>
            )}
          </Col>
          <Col md="12">
            {loanInfoForm && (
              <Flex alignItems="center" className="mb-3 hover-actions-trigger">
                <div className="file-thumbnail">
                  <img
                    className={classNames('h-100 w-100 fit-cover rounded-2', {
                      border: false
                    })}
                    src={iconDocs}
                    alt=""
                  />
                </div>
                <div className="ms-3 flex-shrink-1 flex-grow-1">
                  <h6 className="mb-1">
                    <Link
                      className="stretched-link text-900 fw-semi-bold"
                      onClick={() =>
                        window.open(`${mainUrl}${loanInfoForm}`, '_blank')
                      }
                    >
                      {loanInfoForm && loanInfoForm.split('/')[2]}
                    </Link>
                  </h6>
                  <div className="fs--1">
                    <span className="fw-semi-bold">Зээлийн лавлагаа</span>
                  </div>
                  <div className="hover-actions end-0 top-50 translate-middle-y">
                    <OverlayTrigger
                      overlay={
                        <Tooltip style={{ position: 'fixed' }} id="nextTooltip">
                          Татах
                        </Tooltip>
                      }
                    >
                      <Button
                        variant="light"
                        size="sm"
                        className="border-300 me-1 text-600"
                        as={'a'}
                        href={`${mainUrl}${loanInfoForm}`}
                        download
                      >
                        <img src={cloudDownload} alt="Татах" width={15} />
                      </Button>
                    </OverlayTrigger>
                  </div>
                </div>
              </Flex>
            )}
          </Col>
          {/* <Col md="12">
            <Flex alignItems="center" className="mb-3 hover-actions-trigger">
              <div className="file-thumbnail">
                <img
                  className={classNames('h-100 w-100 fit-cover rounded-2', {
                    border: false
                  })}
                  src={iconDocs}
                  alt=""
                />
              </div>
              <div className="ms-3 flex-shrink-1 flex-grow-1">
                <h6 className="mb-1">
                  <Link
                    className="stretched-link text-900 fw-semi-bold"
                    onClick={() =>
                      window.open(`${mainUrl}${addressForm}`, '_blank')
                    }
                  >
                    {addressForm && addressForm.split('/')[2]}
                  </Link>
                </h6>
                <div className="fs--1">
                  <span className="fw-semi-bold">Хаягийн лавлагаа</span>
                </div>
                <div className="hover-actions end-0 top-50 translate-middle-y">
                  <OverlayTrigger
                    overlay={
                      <Tooltip style={{ position: 'fixed' }} id="nextTooltip">
                        Татах
                      </Tooltip>
                    }
                  >
                    <Button
                      variant="light"
                      size="sm"
                      className="border-300 me-1 text-600"
                      as={'a'}
                      href={`${mainUrl}${addressForm}`}
                      download
                    >
                      <img src={cloudDownload} alt="Татах" width={15} />
                    </Button>
                  </OverlayTrigger>
                </div>
              </div>
            </Flex>
          </Col> */}
          <Col md="12">
            {idCardForm && (
              <Flex alignItems="center" className="mb-3 hover-actions-trigger">
                <div className="file-thumbnail">
                  <img
                    className={classNames('h-100 w-100 fit-cover rounded-2', {
                      border: false
                    })}
                    src={iconDocs}
                    alt=""
                  />
                </div>
                <div className="ms-3 flex-shrink-1 flex-grow-1">
                  <h6 className="mb-1">
                    <Link
                      className="stretched-link text-900 fw-semi-bold"
                      onClick={() =>
                        window.open(`${mainUrl}${idCardForm}`, '_blank')
                      }
                    >
                      {idCardForm && idCardForm.split('/')[2]}
                    </Link>
                  </h6>
                  <div className="fs--1">
                    <span className="fw-semi-bold">
                      Иргэний үнэмлэх лавлагаа
                    </span>
                  </div>
                  <div className="hover-actions end-0 top-50 translate-middle-y">
                    <OverlayTrigger
                      overlay={
                        <Tooltip style={{ position: 'fixed' }} id="nextTooltip">
                          Татах
                        </Tooltip>
                      }
                    >
                      <Button
                        variant="light"
                        size="sm"
                        className="border-300 me-1 text-600"
                        as={'a'}
                        href={`${mainUrl}${idCardForm}`}
                        download
                      >
                        <img src={cloudDownload} alt="Татах" width={15} />
                      </Button>
                    </OverlayTrigger>
                  </div>
                </div>
              </Flex>
            )}
          </Col>
          <Col md="12">
            {socialInsuranceForm && (
              <Flex alignItems="center" className="mb-3 hover-actions-trigger">
                <div className="file-thumbnail">
                  <img
                    className={classNames('h-100 w-100 fit-cover rounded-2', {
                      border: false
                    })}
                    src={iconDocs}
                    alt=""
                  />
                </div>
                <div className="ms-3 flex-shrink-1 flex-grow-1">
                  <h6 className="mb-1">
                    <Link
                      className="stretched-link text-900 fw-semi-bold"
                      onClick={() =>
                        window.open(
                          `${mainUrl}${socialInsuranceForm}`,
                          '_blank'
                        )
                      }
                    >
                      {socialInsuranceForm && socialInsuranceForm.split('/')[2]}
                    </Link>
                  </h6>
                  <div className="fs--1">
                    <span className="fw-semi-bold">
                      Нийгмийн даатгал лавлагаа
                    </span>
                  </div>
                  <div className="hover-actions end-0 top-50 translate-middle-y">
                    <OverlayTrigger
                      overlay={
                        <Tooltip style={{ position: 'fixed' }} id="nextTooltip">
                          Татах
                        </Tooltip>
                      }
                    >
                      <Button
                        variant="light"
                        size="sm"
                        className="border-300 me-1 text-600"
                        as={'a'}
                        href={`${mainUrl}${socialInsuranceForm}`}
                        download
                      >
                        <img src={cloudDownload} alt="Татах" width={15} />
                      </Button>
                    </OverlayTrigger>
                  </div>
                </div>
              </Flex>
            )}
          </Col>
          <Col md="12">
            {marriedForm && (
              <Flex alignItems="center" className="mb-3 hover-actions-trigger">
                <div className="file-thumbnail">
                  <img
                    className={classNames('h-100 w-100 fit-cover rounded-2', {
                      border: false
                    })}
                    src={iconDocs}
                    alt=""
                  />
                </div>
                <div className="ms-3 flex-shrink-1 flex-grow-1">
                  <h6 className="mb-1">
                    <Link
                      className="stretched-link text-900 fw-semi-bold"
                      onClick={() =>
                        window.open(`${mainUrl}${marriedForm}`, '_blank')
                      }
                    >
                      {marriedForm && marriedForm.split('/')[2]}
                    </Link>
                  </h6>
                  <div className="fs--1">
                    <span className="fw-semi-bold">Гэрлэлтийн лавлагаа</span>
                  </div>
                  <div className="hover-actions end-0 top-50 translate-middle-y">
                    <OverlayTrigger
                      overlay={
                        <Tooltip style={{ position: 'fixed' }} id="nextTooltip">
                          Татах
                        </Tooltip>
                      }
                    >
                      <Button
                        variant="light"
                        size="sm"
                        className="border-300 me-1 text-600"
                        as={'a'}
                        href={`${mainUrl}${marriedForm}`}
                        download
                      >
                        <img src={cloudDownload} alt="Татах" width={15} />
                      </Button>
                    </OverlayTrigger>
                  </div>
                </div>
              </Flex>
            )}
          </Col>

          <Col md="12">
            {DefinitionAmount && (
              <Flex alignItems="center" className="mb-3 hover-actions-trigger">
                <div className="file-thumbnail">
                  <img
                    className={classNames('h-100 w-100 fit-cover rounded-2', {
                      border: false
                    })}
                    src={iconDocs}
                    alt=""
                  />
                </div>
                <div className="ms-3 flex-shrink-1 flex-grow-1">
                  <h6 className="mb-1">
                    <Link
                      className="stretched-link text-900 fw-semi-bold"
                      onClick={() =>
                        window.open(`${mainUrl}${DefinitionAmount}`, '_blank')
                      }
                    >
                      {DefinitionAmount && DefinitionAmount.split('/')[2]}
                    </Link>
                  </h6>
                  <div className="fs--1">
                    <span className="fw-semi-bold">Дүнгийн тодорхойлолт</span>
                  </div>
                  <div className="hover-actions end-0 top-50 translate-middle-y">
                    <OverlayTrigger
                      overlay={
                        <Tooltip style={{ position: 'fixed' }} id="nextTooltip">
                          Татах
                        </Tooltip>
                      }
                    >
                      <Button
                        variant="light"
                        size="sm"
                        className="border-300 me-1 text-600"
                        as={'a'}
                        href={`${mainUrl}${DefinitionAmount}`}
                        download
                      >
                        <img src={cloudDownload} alt="Татах" width={15} />
                      </Button>
                    </OverlayTrigger>
                  </div>
                </div>
              </Flex>
            )}
          </Col>

          <Col md="12">
            {CustomsDocuments && (
              <Flex alignItems="center" className="mb-3 hover-actions-trigger">
                <div className="file-thumbnail">
                  <img
                    className={classNames('h-100 w-100 fit-cover rounded-2', {
                      border: false
                    })}
                    src={iconDocs}
                    alt=""
                  />
                </div>
                <div className="ms-3 flex-shrink-1 flex-grow-1">
                  <h6 className="mb-1">
                    <Link
                      className="stretched-link text-900 fw-semi-bold"
                      onClick={() =>
                        window.open(`${mainUrl}${CustomsDocuments}`, '_blank')
                      }
                    >
                      {CustomsDocuments && CustomsDocuments.split('/')[2]}
                    </Link>
                  </h6>
                  <div className="fs--1">
                    <span className="fw-semi-bold">Гаалийн бичиг баримт</span>
                  </div>
                  <div className="hover-actions end-0 top-50 translate-middle-y">
                    <OverlayTrigger
                      overlay={
                        <Tooltip style={{ position: 'fixed' }} id="nextTooltip">
                          Татах
                        </Tooltip>
                      }
                    >
                      <Button
                        variant="light"
                        size="sm"
                        className="border-300 me-1 text-600"
                        as={'a'}
                        href={`${mainUrl}${CustomsDocuments}`}
                        download
                      >
                        <img src={cloudDownload} alt="Татах" width={15} />
                      </Button>
                    </OverlayTrigger>
                  </div>
                </div>
              </Flex>
            )}
          </Col>

          <Col md="12">
            {NDSHReference && (
              <Flex alignItems="center" className="mb-3 hover-actions-trigger">
                <div className="file-thumbnail">
                  <img
                    className={classNames('h-100 w-100 fit-cover rounded-2', {
                      border: false
                    })}
                    src={iconDocs}
                    alt=""
                  />
                </div>
                <div className="ms-3 flex-shrink-1 flex-grow-1">
                  <h6 className="mb-1">
                    <Link
                      className="stretched-link text-900 fw-semi-bold"
                      onClick={() =>
                        window.open(`${mainUrl}${NDSHReference}`, '_blank')
                      }
                    >
                      {NDSHReference && NDSHReference.split('/')[2]}
                    </Link>
                  </h6>
                  <div className="fs--1">
                    <span className="fw-semi-bold">Гаалийн бичиг баримт</span>
                  </div>
                  <div className="hover-actions end-0 top-50 translate-middle-y">
                    <OverlayTrigger
                      overlay={
                        <Tooltip style={{ position: 'fixed' }} id="nextTooltip">
                          Татах
                        </Tooltip>
                      }
                    >
                      <Button
                        variant="light"
                        size="sm"
                        className="border-300 me-1 text-600"
                        as={'a'}
                        href={`${mainUrl}${NDSHReference}`}
                        download
                      >
                        <img src={cloudDownload} alt="Татах" width={15} />
                      </Button>
                    </OverlayTrigger>
                  </div>
                </div>
              </Flex>
            )}
          </Col>

          <Col md="12">
            {PurchaseSale && (
              <Flex alignItems="center" className="mb-3 hover-actions-trigger">
                <div className="file-thumbnail">
                  <img
                    className={classNames('h-100 w-100 fit-cover rounded-2', {
                      border: false
                    })}
                    src={iconDocs}
                    alt=""
                  />
                </div>
                <div className="ms-3 flex-shrink-1 flex-grow-1">
                  <h6 className="mb-1">
                    <Link
                      className="stretched-link text-900 fw-semi-bold"
                      onClick={() =>
                        window.open(`${mainUrl}${PurchaseSale}`, '_blank')
                      }
                    >
                      {PurchaseSale && PurchaseSale.split('/')[2]}
                    </Link>
                  </h6>
                  <div className="fs--1">
                    <span className="fw-semi-bold">
                      Нийгмийн даатгалын лавлагаа
                    </span>
                  </div>
                  <div className="hover-actions end-0 top-50 translate-middle-y">
                    <OverlayTrigger
                      overlay={
                        <Tooltip style={{ position: 'fixed' }} id="nextTooltip">
                          Татах
                        </Tooltip>
                      }
                    >
                      <Button
                        variant="light"
                        size="sm"
                        className="border-300 me-1 text-600"
                        as={'a'}
                        href={`${mainUrl}${PurchaseSale}`}
                        download
                      >
                        <img src={cloudDownload} alt="Татах" width={15} />
                      </Button>
                    </OverlayTrigger>
                  </div>
                </div>
              </Flex>
            )}
          </Col>

          <Col md="12">
            {StudyBook && (
              <Flex alignItems="center" className="mb-3 hover-actions-trigger">
                <div className="file-thumbnail">
                  <img
                    className={classNames('h-100 w-100 fit-cover rounded-2', {
                      border: false
                    })}
                    src={iconDocs}
                    alt=""
                  />
                </div>
                <div className="ms-3 flex-shrink-1 flex-grow-1">
                  <h6 className="mb-1">
                    <Link
                      className="stretched-link text-900 fw-semi-bold"
                      onClick={() =>
                        window.open(`${mainUrl}${StudyBook}`, '_blank')
                      }
                    >
                      {StudyBook && StudyBook.split('/')[2]}
                    </Link>
                  </h6>
                  <div className="fs--1">
                    <span className="fw-semi-bold">
                      Сурагчийн сургалтын дэвтэр
                    </span>
                  </div>
                  <div className="hover-actions end-0 top-50 translate-middle-y">
                    <OverlayTrigger
                      overlay={
                        <Tooltip style={{ position: 'fixed' }} id="nextTooltip">
                          Татах
                        </Tooltip>
                      }
                    >
                      <Button
                        variant="light"
                        size="sm"
                        className="border-300 me-1 text-600"
                        as={'a'}
                        href={`${mainUrl}${StudyBook}`}
                        download
                      >
                        <img src={cloudDownload} alt="Татах" width={15} />
                      </Button>
                    </OverlayTrigger>
                  </div>
                </div>
              </Flex>
            )}
          </Col>
          <Col md="12">
            {LearningDefinition && (
              <Flex alignItems="center" className="mb-3 hover-actions-trigger">
                <div className="file-thumbnail">
                  <img
                    className={classNames('h-100 w-100 fit-cover rounded-2', {
                      border: false
                    })}
                    src={iconDocs}
                    alt=""
                  />
                </div>
                <div className="ms-3 flex-shrink-1 flex-grow-1">
                  <h6 className="mb-1">
                    <Link
                      className="stretched-link text-900 fw-semi-bold"
                      onClick={() =>
                        window.open(`${mainUrl}${LearningDefinition}`, '_blank')
                      }
                    >
                      {LearningDefinition && LearningDefinition.split('/')[2]}
                    </Link>
                  </h6>
                  <div className="fs--1">
                    <span className="fw-semi-bold">
                      Сурч байгаа тодорхойлолт
                    </span>
                  </div>
                  <div className="hover-actions end-0 top-50 translate-middle-y">
                    <OverlayTrigger
                      overlay={
                        <Tooltip style={{ position: 'fixed' }} id="nextTooltip">
                          Татах
                        </Tooltip>
                      }
                    >
                      <Button
                        variant="light"
                        size="sm"
                        className="border-300 me-1 text-600"
                        as={'a'}
                        href={`${mainUrl}${LearningDefinition}`}
                        download
                      >
                        <img src={cloudDownload} alt="Татах" width={15} />
                      </Button>
                    </OverlayTrigger>
                  </div>
                </div>
              </Flex>
            )}
          </Col>
          <Col md="12">
            {carCard && (
              <Flex alignItems="center" className="mb-3 hover-actions-trigger">
                <div className="file-thumbnail">
                  <img
                    className={classNames('h-100 w-100 fit-cover rounded-2', {
                      border: false
                    })}
                    src={iconDocs}
                    alt=""
                  />
                </div>
                <div className="ms-3 flex-shrink-1 flex-grow-1">
                  <h6 className="mb-1">
                    <Link
                      className="stretched-link text-900 fw-semi-bold"
                      onClick={() =>
                        window.open(`${mainUrl}${carCard}`, '_blank')
                      }
                    >
                      {carCard && carCard.split('/')[2]}
                    </Link>
                  </h6>
                  <div className="fs--1">
                    <span className="fw-semi-bold">
                      Тээврийн хэрэгслийн гэрчилгээ
                    </span>
                  </div>
                  <div className="hover-actions end-0 top-50 translate-middle-y">
                    <OverlayTrigger
                      overlay={
                        <Tooltip style={{ position: 'fixed' }} id="nextTooltip">
                          Татах
                        </Tooltip>
                      }
                    >
                      <Button
                        variant="light"
                        size="sm"
                        className="border-300 me-1 text-600"
                        as={'a'}
                        href={`${mainUrl}${carCard}`}
                        download
                      >
                        <img src={cloudDownload} alt="Татах" width={15} />
                      </Button>
                    </OverlayTrigger>
                  </div>
                </div>
              </Flex>
            )}
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default LoanFiles;
