import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { Card, Dropdown, ListGroup } from 'react-bootstrap';
import FalconCardHeader from 'components/common/FalconCardHeader';
import Notification from './Notification';

const NotificationDropdown = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isAllRead, setIsAllRead] = useState(false);
  const [newNumber, setNewNumber] = useState(0);
  console.log('🚀 ~ NotificationDropdown ~ setNewNumber:', setNewNumber);

  // Handler
  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    window.addEventListener('scroll', () => {
      window.innerWidth < 1200 && setIsOpen(false);
    });
  }, []);

  const markAsRead = e => {
    e.preventDefault();
    if ([].length > 0) {
      console.log('jjj');
    }
    [].length === 0 && setIsAllRead(false);
  };

  const handleGoTo = url => {
    window.location.assign(url);
  };

  return (
    <Dropdown navbar={true} as="li" show={isOpen} onToggle={handleToggle}>
      <Dropdown.Toggle
        bsPrefix="toggle"
        as={Link}
        to="#!"
        className={classNames('px-0 nav-link', {
          'notification-indicator notification-indicator-danger': isAllRead
        })}
      >
        {isAllRead && (
          <span className="notification-indicator-number">
            {newNumber > 9 ? 9 : newNumber}
          </span>
        )}
        <FontAwesomeIcon icon="bell" transform="shrink-6" className="fs-4" />
      </Dropdown.Toggle>

      <Dropdown.Menu className="dropdown-menu-card dropdown-menu-end dropdown-caret dropdown-caret-bg">
        <Card
          className="dropdown-menu-notification dropdown-menu-end shadow-none"
          style={{ maxWidth: '20rem' }}
        >
          <FalconCardHeader
            className="card-header"
            title="Мэдэгдэл"
            titleTag="h6"
            light={false}
            endEl={
              <Link
                className="card-link fw-normal"
                to="#!"
                onClick={markAsRead}
              >
                {[].length > 0 && 'Уншисан гэж тэмдэглэх'}
              </Link>
            }
          />
          <ListGroup
            variant="flush"
            className="fw-normal fs--1 scrollbar"
            style={{ maxHeight: '19rem' }}
          >
            {[].length > 0 && <div className="list-group-title">Шинэ</div>}
            {[].length > 0 &&
              [].map(notification => (
                <ListGroup.Item key={notification._id}>
                  <Notification
                    {...notification}
                    unread={true}
                    flush
                    className={
                      'rounded-0 border-x-0 border-300 border-bottom-0'
                    }
                  />
                </ListGroup.Item>
              ))}
            {[].length > 0 && <div className="list-group-title">Хуучин</div>}
            {[].length > 0 &&
              [].map(notification => (
                <ListGroup.Item
                  key={notification._id}
                  onClick={() => handleGoTo(notification?.modelUrl)}
                >
                  <Notification
                    {...notification}
                    unread={true}
                    flush
                    className={
                      'rounded-0 border-x-0 border-300 border-bottom-0'
                    }
                  />
                </ListGroup.Item>
              ))}
          </ListGroup>
          <div
            className="card-footer text-center border-top"
            onClick={handleToggle}
          >
            <Link className="card-link d-block" to="/message/message-list">
              Бүгдийг харах
            </Link>
          </div>
        </Card>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default NotificationDropdown;
