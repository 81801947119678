// apiService.js

import axios from 'axios';
import Cookies from 'js-cookie';

const API_BASE_URL = process.env.REACT_APP_UIZEN_BACKEND;
//const API_BASE_URL = 'http://localhost:8000/api/v1';

const apiService = axios.create({
  baseURL: API_BASE_URL,
  timeout: 40000 // Specify a timeout
});

apiService.interceptors.request.use(
  config => {
    const accessToken = Cookies.get('uiAccessToken'); // Get the access token from the cookie
    if (accessToken) {
      config.headers['Authorization'] = `Bearer ${accessToken}`;
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);
// auth
export const login = async postData => {
  return await apiService.post(`/users/login`, postData);
};

export const register = async postData => {
  return await apiService.post(`/users/register`, postData);
};

export const forgetPassword = async email => {
  const formData = {};
  formData.email = email;
  return await apiService.post(`/users/forgot-password`, formData);
};

export const resetPassword = async formData => {
  return await apiService.post(`/users/reset-password`, formData);
};

export const setAvatarUser = async (userId, postData) => {
  return await apiService.post(`/users/setavatar/${userId}`, postData);
};

export const updateUser = async (userId, postData) => {
  return await apiService.put(`/users/${userId}`, postData);
};

export const changePassword = async postData => {
  return await apiService.post(`/users/change-password`, postData);
};

export const getLoans = queryString => {
  if (Object.keys(queryString).length === 0) {
    return apiService.get(`/loan`);
  } else {
    return apiService.get(`/loan${queryString}`);
  }
};

export const getLoan = loanId => {
  return apiService.get(`/loan/${loanId}`);
};

export const updateLoan = (loanId, putData) => {
  return apiService.put(`/loan/${loanId}`, putData);
};

export const fetchSearchByTextLoan = params => {
  const { searchText } = params;
  const queryParams = new URLSearchParams();

  if (searchText) {
    queryParams.append('searchText', searchText);
  }

  const queryString = queryParams.toString();

  return apiService.get(`/loan/searchtext?${queryString}`);
};

export const deleteLoans = async loanIds => {
  const formData = {
    loanIds: loanIds
  };
  return await apiService.put(`/loan/alldelete`, formData);
};

export const toggleActivationLoans = async (loanIds, value) => {
  const formData = {
    activate: value,
    loanIds: loanIds
  };
  return await apiService.put(`/loan/activation`, formData);
};

export const toggleActivationLoan = async (loanId, value) => {
  const formData = {
    activate: value === 'active' ? true : false
  };
  return await apiService.put(`/loan/${loanId}/activation`, formData);
};

export const deleteLoan = async loanId => {
  return await apiService.delete(`/loan/${loanId}`);
};

export const createService = postData => {
  return apiService.post('/service', postData);
};

export const updateService = (serviceId, postData) => {
  return apiService.put(`/service/${serviceId}`, postData);
};

export const getServices = queryString => {
  if (Object.keys(queryString).length === 0) {
    return apiService.get(`/service`);
  } else {
    return apiService.get(`/service${queryString}`);
  }
};

export const getService = serviceId => {
  return apiService.get(`/service/${serviceId}`);
};

export const toggleActivationServices = async (serviceIds, value) => {
  const formData = {
    activate: value,
    serviceIds: serviceIds
  };
  return await apiService.put(`/service/activation`, formData);
};

export const toggleActivationService = async (serviceId, value) => {
  const formData = {
    activate: value === 'active' ? true : false
  };
  return await apiService.put(`/service/${serviceId}/activation`, formData);
};

export const deleteService = async serviceId => {
  return await apiService.delete(`/service/${serviceId}`);
};

export const deleteServices = async serviceIds => {
  const formData = {
    serviceIds: serviceIds
  };
  return await apiService.put(`/service/alldelete`, formData);
};

export const getContacts = queryString => {
  if (Object.keys(queryString).length === 0) {
    return apiService.get(`/contact`);
  } else {
    return apiService.get(`/contact${queryString}`);
  }
};

export const getContact = messageId => {
  return apiService.get(`/contact/${messageId}`);
};

export const deleteContact = async contactId => {
  return await apiService.delete(`/contact/${contactId}`);
};

export const toggleActivationContact = async (contactId, value) => {
  const formData = {
    activate: value
  };
  return await apiService.put(`/contact/${contactId}/activation`, formData);
};

export const deleteContacts = async contactIds => {
  const formData = {
    contactIds: contactIds
  };
  return await apiService.put(`/contact/alldelete`, formData);
};

export const toggleActivationContacts = async (contactIds, value) => {
  const formData = {
    activate: value,
    contactIds: contactIds
  };
  return await apiService.put(`/contact/activation`, formData);
};

export const toggleSeenContact = async (contactId, value) => {
  const formData = {
    activate: Boolean(value)
  };
  return await apiService.put(`/contact/${contactId}/seen`, formData);
};

export const getContactNotification = () => {
  return apiService.get(`/contact/notification`);
};

export const getUsers = queryString => {
  if (Object.keys(queryString).length === 0) {
    return apiService.get(`/users`);
  } else {
    return apiService.get(`/users${queryString}`);
  }
};

export const deleteUser = async deleteId => {
  return await apiService.delete(`/users/${deleteId}`);
};

export const toggleActivationUsers = async (usersIds, value) => {
  const formData = {
    activate: value,
    usersIds: usersIds
  };
  return await apiService.put(`/users/activation`, formData);
};

export const toggleActivationUser = async (userId, value) => {
  const formData = {
    activate: value
  };
  return await apiService.put(`/users/${userId}/activation`, formData);
};

export const deleteUsers = async deleteIds => {
  const formData = {
    deleteIds: deleteIds
  };
  return await apiService.put(`/users/alldelete`, formData);
};

export const updateCompany = (companyId, postData) => {
  return apiService.put(`/company/${companyId}`, postData);
};

export const getCompany = handle => {
  return apiService.get(`/company/${handle}`);
};

export const getLogs = () => {
  return apiService.get(`/logs`);
};

export const createCurrency = postData => {
  return apiService.post('/currency', postData);
};

export const getCurrency = query => {
  return apiService.get(`/currency${query}`);
};

export const createSendmoney = postData => {
  return apiService.post('/sendmoney', postData);
};

export const getSendmoney = queryString => {
  if (Object.keys(queryString).length === 0) {
    return apiService.get(`/sendmoney`);
  } else {
    return apiService.get(`/sendmoney${queryString}`);
  }
};

export const getLoanNotification = () => {
  return apiService.get(`/loan/notification`);
};

export const createReport = postData => {
  return apiService.post('/report', postData);
};

export const getReports = queryString => {
  if (Object.keys(queryString).length === 0) {
    return apiService.get(`/report`);
  } else {
    return apiService.get(`/report${queryString}`);
  }
};

export const deleteReport = async reportId => {
  return await apiService.delete(`/report/${reportId}`);
};
// Add other API-related functions as needed
